import React, { lazy, Suspense, useEffect } from 'react';


import Navbar from 'components/Navbars/siteIntro'
import Banner from 'components/siteIntro/datrimony/banner';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
const WhatIsDatrimony = lazy(() => import('components/siteIntro/datrimony/whatIsDatrimony'))
const WhyChooseDatrimony = lazy(() => import('components/siteIntro/datrimony/whyChooseDatrimony'))
const CpFeatures = lazy(() => import('components/siteIntro/datrimony/cpFeatures'))
const InviteFriends = lazy(() => import('components/siteIntro/datrimony/inviteFriends'))
const ConnectionFlow = lazy(() => import('components/siteIntro/datrimony/connectionFlow'))
const WalletPayment = lazy(() => import('components/siteIntro/datrimony/walletPayment'))
const PlanCards = lazy(() => import('components/siteIntro/datrimony/planCards'))
const Testimonial = lazy(() => import('components/siteIntro/datrimony/testimonial'))
const Event = lazy(() => import('components/siteIntro/datrimony/event'))
const Parterns = lazy(() => import('components/siteIntro/datrimony/parterns'))

const Footer = lazy(() => import("components/Footer"))
const LandingPageResponsiveFooter = lazy(() => import("components/Footer/responsive"))

const Index = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='bg-white'>

            <Helmet>
                <meta name='title' content="India's First and Only Datrimony Platform| Couple Squad" />
                <meta name="description" content="Couple Squad's Datrimony is India’s first and only platform that blends dating with matrimony, a unique way to connect and build lasting relationships." />
                <link rel="canonical" href="https://www.couplesquad.com/date-to-marry-dtm" />
            </Helmet>

            <div className="fixed top-0 z-50 w-full">
                <Navbar />
            </div>

            <Banner />
            <Suspense children={<WhatIsDatrimony />} fallback={<div>loading</div>} />
            <Suspense children={<WhyChooseDatrimony />} fallback={<div>loading</div>} />
            <Suspense children={<CpFeatures />} fallback={<div>loading</div>} />
            <Suspense children={<InviteFriends />} fallback={<div>loading</div>} />
            <Suspense children={<Event />} fallback={<div>loading</div>} />
            <div className="mt-24">
                <Suspense children={<ConnectionFlow />} fallback={<div>loading</div>} />
            </div>
            <Suspense children={<WalletPayment />} fallback={<div>loading</div>} />
            <Suspense children={<PlanCards />} fallback={<div>loading</div>} />
            <Suspense children={<Testimonial />} fallback={<div>loading</div>} />
            <Suspense children={<Parterns />} fallback={<div>loading</div>} />



            <Suspense fallback={<div>Loading...</div>}>
                <div className="hidden w-full bg-black lg:block">
                    <Footer />
                </div>
                <div className="block bg-black lg:hidden">
                    <LandingPageResponsiveFooter />
                </div>
            </Suspense>

        </div>
    );
};

export default Index;
