import React, { useState, useEffect, lazy, Suspense } from 'react'
import 'react-toastify/dist/ReactToastify.css';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getMutualConnectionProfilePreview, getQrConnectionProfilePreview, sendConnectionRequest, skipTheUser, typeBasedConnectionRequest } from 'apis/matches';
import { decodeFromBase64 } from 'utils/encodeDecode';
import NavBar from 'components/Navbars/mainHeader'
import NavBarResponsive from 'components/Navbars/mainHeader/responsive'
import ResponsiveBottomNav from 'components/Navbars/responsiveBottomNav'

import ImgPreview from "components/discover/imgPreview"
import BasicsInfo from "components/discover/basics"
import InterestAndLocation from "components/discover/interestAndLocation"
import { Actions } from 'components/discover/action';
import { toast } from 'react-toastify';
import { MAIN_NAV, WALLET } from 'shared/router';


const LocationError = lazy(() => import('components/Cards/emptyCards/LocationTurnoff'))
const LimitReached = lazy(() => import('components/Cards/emptyCards/LimitReached'))
const InsufficientCode = lazy(() => import('components/Cards/emptyCards/InsufficientCode'))
const NoAccess = lazy(() => import('components/Cards/emptyCards/NodataAccess'))
const SomethingWentWrong = lazy(() => import('components/Cards/ErrorCards/somethingWentWrong'))
// Assets

function Index() {
    const { id } = useParams();
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    const [userData, setUserData] = useState()
    const [errorType, setErrorType] = useState(null)
    const [phoneNo, setPhoneNo] = useState(false)

    const fetchFriendsProfilePreview = async (phone = false) => {
        let res
        console.log('fetchFriendsProfilePreview', phone)
        if (phone) {
            res = await getMutualConnectionProfilePreview(decodeFromBase64(id), phone)
        } else {
            res = await getQrConnectionProfilePreview(decodeFromBase64(id))
        }
        if (res?.data) {
            setUserData(res?.data)
        }
    }

    useEffect(() => {
        const phone = queryParams.get('phone');
        if (phone) setPhoneNo(phone)
        console.log('setPhoneNo', phone)
        fetchFriendsProfilePreview(phone ? phone : false)
    }, [])

    // Send Connection Request

    const handleConnect = async () => {
        const res = await typeBasedConnectionRequest(userData?.matchId, phoneNo ? 'mutual_contacts' : 'qr')
        if (res?.success) {
            toast.success('Request sended')
            phoneNo ? navigate(MAIN_NAV.DISCOVER) : navigate(WALLET.WALLET)
        }
    }

    const handleSkip = async () => {
        const res = await skipTheUser(userData?.matchId)
        if (res?.success) {
            toast.success('Skipped the user')
            phoneNo ? navigate(MAIN_NAV.DISCOVER) : navigate(WALLET.WALLET)
        }
    }



    const ErrorComponent = errorType === 'Please update your location to continue' ? LocationError
        : (errorType === 'DAILY_CONNECTION_LIMIT' ? LimitReached
            : (errorType === 'INSUFFICIENT_COINS' ? InsufficientCode
                : (errorType === 'NO_ACCESS' ? NoAccess
                    : SomethingWentWrong)))



    return (
        <div className="flex flex-col h-full min-h-screen bg-white">
            {/* Navbar */}
            <div className="text-white bg-white h-18">
                <div className="hidden lg:block">
                    <NavBar />
                </div>
                <div className="block lg:hidden">
                    <NavBarResponsive />
                </div>
            </div>
            <ResponsiveBottomNav />

            {errorType && (
                <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center w-full bg-black bg-opacity-75">
                    <Suspense fallback={<div>Loading...</div>}>
                        <ErrorComponent closeBtn={() => setErrorType(null)} />
                    </Suspense>
                </div>
            )}

            <div className='flex items-center justify-center flex-grow w-full bg-white'>
                <div className='flex flex-wrap items-center justify-center gap-5'>
                    <div className='flex flex-col'>
                        <div className="h-[510px] w-full md:w-[380px] lg:me-2">
                            <ImgPreview data={userData} profilePreview={true} />
                        </div>
                        <div className="flex items-center justify-center gap-3 my-3">
                            <Actions handleConnect={handleConnect} handleSkip={handleSkip} />
                        </div>
                    </div>

                    <div className="w-full md:w-[400px] lg:me-2">
                        <BasicsInfo data={userData} />
                    </div>
                    <div className="w-full md:w-[400px] lg:me-2">
                        <InterestAndLocation data={userData} isPreview={true} />
                    </div>

                </div>
            </div>
        </div>

    )
}

export default Index