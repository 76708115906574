// import React from 'react';

// const TermsAndConditions = () => {
//     return (
//         <div className="p-10 text-black bg-white">
//             <h1 className="text-3xl font-bold text-center">Terms & Conditions</h1>
//             <p>
//                 Welcome to www.couplesquad.com. This website is owned and
//                 operated by Couple Squad Private Limited & Centerspace, No
//                 B 28, Anjanasree Arcade,Annankunnu
//                 Road,Kottayam,Kerala,686601. <br />By visiting our website and
//                 accessing the information, resources, services, products, and
//                 tools we provide, you understand and agree to accept and
//                 adhere to the following terms and conditions as stated in this
//                 policy (hereinafter referred to as the ‘Agreement’), along with the
//                 terms and conditions as stated in our Privacy Policy (please refer
//                 to the Privacy Policy section below for more information).<br />
//                 We reserve the right to change this Agreement from time to time
//                 with/without notice. You acknowledge and agree that it is your
//                 responsibility to review this Agreement periodically to familiarize
//                 yourself with any modifications. Your continued use of this site
//                 after such modifications will constitute acknowledgment and
//                 agreement of the modified terms and conditions.<br />
//                 PLEASE READ THIS TERMS OF SERVICE AGREEMENT
//                 CAREFULLY. BY USING THIS WEBSITE OR ORDERING
//                 PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE
//                 BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS
//                 AGREEMENT.
//                 <br />
//                 This Agreement governs your use of this website
//                 www.couplesquad.com (hereinafter referred to as the
//                 ‘Website’), Couple Squad Private Limitedc’s offer of Products
//                 and Services for purchase on this Website, or your purchase of
//                 Products and Services available on this Website. This
//                 Agreement includes and incorporates by this reference, the
//                 policies and guidelines referred below. Couple Squad Private
//                 Limited reserves the right to change or revise the terms and
//                 conditions of this Agreement at any time by posting any changes
//                 or a revised Agreement on this Website. Couple Squad Private
//                 Limited will/will not alert you that changes or revisions have
//                 been made by indicating on the top of this Agreement the date it
//                 was last revised. The changed or revised Agreement will be
//                 effective immediately after it is posted on this Website. Your use
//                 of the Website following the posting of any such changes or of a
//                 revised Agreement will constitute your acceptance of any such
//                 changes or revisions. Couple Squad Private Limited
//                 encourages you to review this Agreement whenever you visit the
//                 Website to make sure that you understand the terms and
//                 conditions governing use of the Website. This Agreement does
//                 not alter in any way the terms or conditions of any other written
//                 agreement you may have with Couple Squad Private Limited
//                 for other products or services. If you do not agree to this
//                 Agreement (including any referenced policies or guidelines),
//                 please immediately terminate your use of the Website.
//                 Responsible Use And Conduct
//                 In order to access our Resources/ Products and Services, you
//                 may be required to provide certain information about yourself
//                 (such as identification, email, phone number, contact details,
//                 etc.) as part of the registration process, or as part of your ability
//                 to use the Resources. You agree that any information you
//                 provide will always be accurate, correct, and up to date.
//                 You are responsible for maintaining the confidentiality of any
//                 login information associated with any account you use to access
//                 our Resources/ Products and Services. Accordingly, you are
//                 responsible for all activities that occur under your accounts.
//                 Accessing (or attempting to access) any of our Resources/
//                 Products and Services by any means other than through the
//                 means we provide, is strictly prohibited. You specifically agree
//                 not to access (or attempt to access) any of our Resources/
//                 Products and Services through any automated, unethical or
//                 unconventional means.
//                 Engaging in any activity that disrupts or interferes with our
//                 Resources/ Products and Services, including the servers
//                 and/or networks to which our Resources / Products and
//                 Services are located or connected, is strictly prohibited.
//                 Attempting to copy, duplicate, reproduce, sell, trade, or resell our
//                 Resources / Products and Services is strictly prohibited.
//                 You are solely responsible for any consequences, losses, or
//                 damages that we may directly or indirectly incur or suffer due to
//                 any unauthorized activities conducted by you, as explained
//                 above, and may incur criminal or civil liability.
//                 <br />
//                 Privacy:
//                 Couple Squad Private Limited believes strongly in protecting
//                 user privacy, which is why a separate Privacy Policy have been
//                 created in order to explain in detail how we collect, manage,
//                 process, secure, and store your private information. Please refer
//                 to Couple Squad Private Limited privacy policy, incorporated
//                 by reference herein, that is posted on the Website.
//                 Products and Services - Terms of Offer:
//                 This Website offers for sale certain Products and Services
//                 (hereinafter referred to as ‘ Products and Services’). By placing
//                 an order for Products and Services through this Website, you
//                 agree to the terms set forth in this Agreement.
//                 <br />
//                 Customer Solicitation:
//                 Unless you notify our third party call center representatives or
//                 direct Couple Squad Private Limited sales representatives,
//                 while they are calling you, of your desire to opt out from further
//                 direct company communications and solicitations, you are
//                 agreeing to continue to receive further emails and call
//                 solicitations from Couple Squad Private Limited and its
//                 designated in house or third party call team(s).
//                 Opt Out Procedure:
//                 We provide 3 easy ways to opt out of from future solicitations -
//                 You may use the opt out link found in any email solicitation
//                 that you may receive.
//                 You may also choose to opt out, via sending your email
//                 address to: [opt-out email].
//                 You may send a written remove request to Couple Squad
//                 Private Limited sales representatives, while they are calling
//                 you, of your desire to opt out from further direct company
//                 communications and solicitations, you are agreeing to
//                 continue to receive further emails and call solicitations from
//                 Couple Squad Private Limited & ADDRESS Centerspace,
//                 No B 28, Anjanasree Arcade,Annankunnu
//                 Road,Kottayam,Kerala,686601.
//                 Proprietary Rights:
//                 Couple Squad Private Limited has proprietary rights and trade
//                 secrets in the Products and Services. You may not copy,
//                 reproduce, resell or redistribute any Product manufactured
//                 and/or distributed by Couple Squad Private Limited. Couple
//                 Squad Private Limited also has rights to all trademarks and
//                 trade dress and specific layouts of this webpage, including calls
//                 to action, text placement, images and other information.
//                 <br />
//                 Taxes: If you purchase any Products and Services, you will be
//                 responsible for paying the applicable taxes. (GST)
//                 Content, Intellectual Property, Third Party Links:
//                 In addition to making Products and Services available, this
//                 Website also offers information and marketing materials. This
//                 Website also offers information, both directly and through indirect
//                 links to third-party websites, about (kind of information). Couple
//                 Squad Private Limited does not always create the information
//                 offered on this Website; instead the information is often gathered
//                 from other sources. To the extent that Couple Squad Private
//                 Limited does create the content on this Website, such content is
//                 protected by intellectual property laws of the India, foreign
//                 nations, and international bodies. Unauthorized use of the
//                 material may violate copyright, trademark, and/or other laws. You
//                 acknowledge that your use of the content on this Website is for
//                 personal, non-commercial use. Any links to third-party websites
//                 are provided solely as a convenience to you. Couple Squad
//                 Private Limited does not endorse the contents on any such
//                 third-party websites. Couple Squad Private Limited is not
//                 responsible for the content of or any damage that may result
//                 from your access to or reliance on these third-party websites. If
//                 you link to third-party websites, you do so at your own risk.
//                 <br />
//                 Use of Website:
//                 Couple Squad Private Limited is not responsible for any
//                 damages resulting from use of this website by anyone. You will
//                 not use the Website for illegal purposes. You will -
//                 abide by all applicable local, state, national, and international
//                 laws and regulations in your use of the Website (including laws
//                 regarding intellectual property),
//                 not interfere with or disrupt the use and enjoyment of the Website
//                 by other users,
//                 not resell material on the Website,
//                 not engage, directly or indirectly, in transmission of "spam", chain
//                 letters, junk mail or any other type of unsolicited communication,
//                 and
//                 not defame, harass, abuse, or disrupt other users of the Website.
//                 License:
//                 By using this Website, you are granted a limited, non-exclusive,
//                 non-transferable right to use the content and materials on the
//                 Website in connection with your normal, non-commercial use of
//                 the Website. You may not copy, reproduce, transmit, distribute, or
//                 create derivative works of such content or information without
//                 express written authorization from Couple Squad Private
//                 Limited or the applicable third party (if third party content is at
//                 issue).
//                 <br />
//                 Blogs:
//                 We may provide various open communication tools on our
//                 website, such as blog comments, blog posts, public chat, forums,
//                 message boards, newsgroups, product ratings and reviews,
//                 various social media services, etc. You understand that generally
//                 we do not pre-screen or monitor the content posted by users of
//                 these various communication tools, which means that if you
//                 choose to use these tools to submit any type of content to our
//                 website, then it is your personal responsibility to use these tools
//                 in a responsible and ethical manner. By posting information or
//                 otherwise using any open communication tools as mentioned,
//                 you agree that you will not upload, post, share, or otherwise
//                 distribute any content that:
//                 is illegal, threatening, defamatory, abusive, harassing, degrading,
//                 intimidating, fraudulent, deceptive, invasive, racist, or contains
//                 any type of suggestive, inappropriate, or explicit language;
//                 <br />
//                 infringes on any trademark, patent, trade secret, copyright, or
//                 other proprietary right of any party;
//                 contains any type of unauthorized or unsolicited advertising;
//                 impersonates any person or entity, including any
//                 www.couplesquad.com/Couple Squad Private Limited
//                 employees or representatives.
//                 We have the right at our sole discretion to remove any content
//                 that, we feel in our judgment does not comply with this User
//                 Agreement, along with any content that we feel is otherwise
//                 offensive, harmful, objectionable, inaccurate, or violates any 3rd
//                 party copyrights or trademarks. We are not responsible for any
//                 delay or failure in removing such content. If you post content that
//                 we choose to remove, you hereby consent to such removal, and
//                 consent to waive any claim against us.
//                 We do not assume any liability for any content posted by you or
//                 any other 3rd party users of our website. However, any content
//                 posted by you using any open communication tools on our
//                 website, provided that it doesn't violate or infringe on any 3rd
//                 party copyrights or trademarks, becomes the property of Couple
//                 Squad Private Limited, and as such, gives us a perpetual,
//                 irrevocable, worldwide, royalty-free, exclusive license to
//                 reproduce, modify, adapt, translate, publish, publicly display
//                 and/or distribute as we see fit. This only refers and applies to
//                 content posted via open communication tools as described, and
//                 does not refer to information that is provided as part of the
//                 registration process, necessary in order to use our Resources.
//                 All information provided as part of our registration process is
//                 covered by our Privacy Policy.
//                 You agree to indemnify and hold harmless
//                 www.couplesquad.com a digital property of Couple Squad
//                 Private Limited and its parent company and affiliates, and their
//                 directors, officers, managers, employees, donors, agents, and
//                 licensors, from and against all losses, expenses, damages and
//                 costs, including reasonable attorneys' fees, resulting from any
//                 violation of this User Agreement or the failure to fulfill any
//                 obligations relating to your account incurred by you or any other
//                 person using your account. We reserve the right to take over the
//                 exclusive defense of any claim for which we are entitled to
//                 indemnification under this User Agreement. In such event, you
//                 shall provide us with such cooperation as is reasonably
//                 requested by us.
//                 Posting:
//                 By posting, storing, or transmitting any content on the Website,
//                 you hereby grant Couple Squad Private Limited a perpetual,
//                 worldwide, non-exclusive, royalty-free, assignable, right and
//                 license to use, copy, display, perform, create derivative works
//                 from, distribute, have distributed, transmit and assign such
//                 content in any form, in all media now known or hereinafter
//                 created, anywhere in the world. Couple Squad Private Limited
//                 does not have the ability to control the nature of the usergenerated content offered through the Website. You are solely
//                 responsible for your interactions with other users of the Website
//                 and any content you post. Couple Squad Private Limited is not
//                 liable for any damage or harm resulting from any posts by or
//                 interactions between users. Couple Squad Private Limited
//                 reserves the right, but has no obligation, to monitor interactions
//                 between and among users of the Website and to remove any
//                 content Couple Squad Private Limited deems objectionable, in
//                 Couple Squad Private Limited's sole discretion.
//                 <br />
//                 Disclaimer of Warranties:
//                 Your use of this website and/or Products and Services are at
//                 your sole risk. The website and Products and Services are
//                 offered on an "as is" and "as available" basis. Couple Squad
//                 Private Limited expressly disclaims all warranties of any kind,
//                 whether express or implied, including, but not limited to, implied
//                 warranties of merchantability, fitness for a particular purpose and
//                 non-infringement with respect to the Products and Services or
//                 website content, or any reliance upon or use of the website
//                 content or Products and Services (" Products and Services"
//                 include trial Products and Services.)
//                 Without limiting the generality of the foregoing, Couple Squad
//                 Private Limited makes no warranty:
//                 that the information provided on this website is accurate, reliable,
//                 complete, or timely.
//                 that the links to third-party websites are to information that is
//                 accurate, reliable, complete, or timely.
//                 no advice or information, whether oral or written, obtained by you
//                 from this website will create any warranty not expressly stated
//                 herein
//                 as to the results that may be obtained from the use of the
//                 products or that defects in products will be corrected.
//                 regarding any Products and Services purchased or obtained
//                 through the website.
//                 Some jurisdictions do not allow the exclusion of certain
//                 warranties, so some of the above exclusions may not apply to
//                 you.
//                 <br />
//                 Limitation Of Liability
//                 Couple Squad Private Limited’s entire liability, and your
//                 exclusive remedy, in law, in equity, or otherwise, with respect to
//                 the website content / Products and Services and/or for any
//                 breach of this agreement is solely limited to the amount you paid,
//                 less shipping and handling charges, for Products and Services
//                 purchased via this website.
//                 Couple Squad Private Limited will not be liable for any direct,
//                 indirect, incidental, special or consequential damages in
//                 connection with this agreement &/or Products and Services in
//                 any manner, including liabilities resulting from:
//                 the use or the inability to use the website content / Products and
//                 Services;
//                 the cost of procuring substitute content / Products and
//                 Services;
//                 any information obtained / Products and Services purchased or
//                 transactions entered into through the website; or
//                 any lost profits you allege.
//                 Some jurisdictions do not allow the limitation or exclusion of
//                 liability for incidental or consequential damages so some of the
//                 above limitations may not apply to you.
//                 conjunction with the Limitation of Liability as explained above,
//                 you expressly understand and agree that any claim against us
//                 shall be limited to the amount you paid, if any, for use of
//                 Products and Services. www.couplesquad.com/Couple
//                 Squad Private Limited will not be liable for any direct, indirect,
//                 incidental, consequential or exemplary loss or damages which
//                 may be incurred by you as a result of using our Resources /
//                 Products and Services, or as a result of any changes, data loss
//                 or corruption, cancellation, loss of access, or downtime to the full
//                 extent that applicable limitation of liability laws apply.
//                 <br />
//                 Indemnification:
//                 You will release, indemnify, defend and hold harmless Couple
//                 Squad Private Limited, and any of its contractors, agents,
//                 employees, officers, directors, shareholders, affiliates and
//                 assigns from all liabilities, claims, damages, costs and expenses,
//                 including reasonable attorneys' fees and expenses, of third
//                 parties relating to or arising out of
//                 this Agreement or the breach of your warranties, representations
//                 and obligations under this Agreement;
//                 the Website content or your use of the Website content;
//                 the Products and Services or your use of the Products and
//                 Services (including Trial Products and Services);
//                 any intellectual property or other proprietary right of any person
//                 or entity;
//                 your violation of any provision of this Agreement; or
//                 any information or data you supplied to Couple Squad Private
//                 Limited.
//                 <br />
//                 When Couple Squad Private Limited is threatened with suit or
//                 sued by a third party, Couple Squad Private Limited may seek
//                 written assurances from you concerning your promise to
//                 indemnify Couple Squad Private Limited; your failure to provide
//                 such assurances may be considered by Couple Squad Private
//                 Limited to be a material breach of this Agreement. Couple
//                 Squad Private Limited will have the right to participate in any
//                 defense by you of a third-party claim related to your use of any of
//                 the Website content / Products and Services, with the counsel
//                 of Couple Squad Private Limited’s choice at its expense.
//                 Couple Squad Private Limited will reasonably cooperate in any
//                 defense by you of a third-party claim at your request and
//                 expense. You will have sole responsibility to defend Couple
//                 Squad Private Limited against any claim, but you must receive
//                 Couple Squad Private Limited’s prior written consent regarding
//                 any related settlement. The terms of this provision will survive
//                 any termination or cancellation of this Agreement or your use of
//                 the Website / Products and Services.
//                 <br />
//                 Copyrights / Trademarks
//                 All content and materials available on www.couplesquad.com,
//                 including but not limited to text, graphics, website name, code,
//                 images and logos are the intellectual property of Couple Squad
//                 Private Limited and are protected by applicable copyright and
//                 trademark law. Any inappropriate use, including but not limited to
//                 the reproduction, distribution, display or transmission of any
//                 content on this site is strictly prohibited, unless specifically
//                 authorized by Couple Squad Private Limited.
//                 Agreement To Be Bound
//                 BY USING THIS WEBSITE OR ORDERING Products and
//                 Services, YOU ACKNOWLEDGE THAT YOU HAVE READ AND
//                 AGREE TO BE BOUND BY THIS AGREEMENT AND ALL
//                 TERMS AND CONDITIONS ON THIS WEBSITE.
//                 <br />
//                 General Clause:
//                 Force Majeure: Couple Squad Private Limited will not be
//                 deemed in default hereunder or held responsible for any
//                 cessation, interruption or delay in the performance of its
//                 obligations hereunder due to earthquake, flood, fire, storm,
//                 natural disaster, act of God, war, terrorism, armed conflict, labor
//                 strike, lockout, or boycott.
//                 Cessation of Operation: Couple Squad Private Limited may
//                 at any time, in its sole discretion and without advance notice to
//                 you, cease operation of the Website and distribution of the
//                 Products and Services.
//                 Entire Agreement: This Agreement comprises the entire
//                 agreement between you and Couple Squad Private Limited
//                 and supersedes any prior agreements pertaining to the subject
//                 matter contained herein.
//                 <br />
//                 Governing Law:
//                 This website is controlled by Couple Squad Private Limited
//                 from our offices located in the state of Kerala, India. It can be
//                 accessed by most countries around the world. As each country
//                 has laws that may differ from those of Kerala, India, by
//                 accessing our website, you agree that the statutes and laws of
//                 Kerala, India, without regard to its conflict of law principles to the
//                 contrary and the United Nations Convention on the International
//                 Sales of Goods, will apply to all matters relating to the use of this
//                 website and the purchase of any Products and Services
//                 through this site.
//                 <br />
//                 Effect of Waiver:
//                 The failure of Couple Squad Private Limited to exercise or
//                 enforce any right or provision of this Agreement will not constitute
//                 a waiver of such right or provision. If any provision of this
//                 Agreement is found by a court of competent jurisdiction to be
//                 invalid, the parties nevertheless agree that the court should
//                 endeavor to give effect to the parties' intentions as reflected in
//                 the provision and the other provisions of this Agreement remain
//                 in full force and effect.
//                 Governing Law/Jurisdiction:
//                 This Website originates from the Kottayam, Kerala, India. This
//                 Agreement will be governed by the laws of the State of Kerala,
//                 India. It can be accessed by most countries around the world. As
//                 each country has laws that may differ from those of Kerala, India
//                 without regard to its conflict of law principles to the contrary.
//                 Neither you nor Couple Squad Private Limited will
//                 commenceKerala), India. This Agreement will be governed by
//                 the laws of the State of Kerala, India. It can be accessed by
//                 most countries around the world. As each country has laws that
//                 may differ from those of Kerala, India without regard to its
//                 conflict of law principles to the contrary. Neither you nor or
//                 prosecute any suit, proceeding or claim to enforce the provisions
//                 of this Agreement, to recover damages for breach of or default of
//                 this Agreement, or otherwise arising under or by reason of this
//                 Agreement, other than in courts located in State of Kerala, India.
//                 It can be accessed by most countries around the world. As each
//                 country has laws that may differ from those of Kerala, India. By
//                 using this Website or ordering Products and Services, you
//                 consent to the jurisdiction and venue of such courts in
//                 connection with any action, suit, proceeding or claim arising
//                 under or by reason of this Agreement. You hereby waive any
//                 right to trial by jury arising out of this Agreement and any related
//                 documents.
//                 <br />
//                 This website is controlled by Couple Squad Private Limited
//                 from our offices located in the state of Kerala, India. It can be
//                 accessed by most countries around the world. As each country
//                 has laws that may differ from those of Kerala, India, by
//                 accessing our website, you agree that the statutes and laws of
//                 Kerala, India without regard to the conflict of laws and the United
//                 Nations Convention on the International Sales of Goods, will
//                 apply to all matters relating to the use of this website and the
//                 purchase of any Products and Services through this site.
//                 Furthermore, any action to enforce this User Agreement shall be
//                 brought in the courts having jurisdiction over such issue, located
//                 in Kerala, India. You hereby agree to judgement passed by such
//                 courts and waive any right to jurisdictional, venue, or
//                 inconvenient forum objections to such courts.
//                 Statute of Limitation:
//                 You agree that regardless of any statute or law to the contrary,
//                 any claim or cause of action arising out of or related to use of the
//                 Website or Products and Services or this Agreement must be
//                 filed within one (1) year after such claim or cause of action arose
//                 else be forever barred.
//                 Waiver of Class Action Rights:
//                 BY ENTERING INTO THIS AGREEMENT, YOU HEREBY
//                 IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO JOIN
//                 CLAIM WITH THOSE OF OTHERS IN THE FORM OF A CLASS
//                 ACTION OR SIMILAR PROCEDURAL DEVICE. ANY CLAIMS
//                 ARISING OUT OF OR RELATING TO OR IN CONNECTION
//                 WITH THIS AGREEMENT MUST BE ASSERTED
//                 INDIVIDUALLY
//                 <br />
//                 Termination:
//                 Couple Squad Private Limited reserves the right to terminate
//                 your access to the Website if it reasonably believes, in its sole
//                 discretion, that you have breached any of the terms and
//                 conditions of this Agreement. Following termination, you will not
//                 be permitted to use the Website and Couple Squad Private
//                 Limited may, in its sole discretion and without advance notice to
//                 you, cancel any outstanding orders for Products and Services.
//                 If your access to the Website is terminated, Couple Squad
//                 Private Limited reserves the right to exercise whatever means it
//                 deems necessary to prevent unauthorized access of the
//                 Website. This Agreement will survive indefinitely unless and until
//                 Couple Squad Private Limited chooses, in its sole discretion
//                 and without advance notice to you, to terminate it.
//                 Domestic Use:
//                 Couple Squad Private Limited makes no representation that
//                 the Website or Products and Services are appropriate or
//                 available for use in locations outside India. Users who access the
//                 Website from outside India do so at their own risk and initiative
//                 and must bear all responsibility for compliance with any
//                 applicable local laws.
//                 Cancellation And Refund:
//                 Cancellation of order is not possible once the payment has been
//                 made. No refunds will be given except in the event of
//                 cancellation or non-performance of service by Couple Squad
//                 Private Limited.
//                 <br />
//                 Guarantee:
//                 Unless otherwise expressed, Couple Squad Private Limited &
//                 www.couplesquad.com expressly disclaims all warranties and
//                 conditions of any kind, whether express or implied, including, but
//                 not limited to the implied warranties and conditions of
//                 merchantability, fitness of content / Products and Services for a
//                 particular purpose and non-infringement.
//                 Assignment:
//                 You may not assign your rights and obligations under this
//                 Agreement to anyone. Couple Squad Private Limited may
//                 assign its rights and obligations under this Agreement in its sole
//                 discretion and without advance notice to you.
//                 BY USING THIS WEBSITE OR ORDERING Products and
//                 Services FROM THIS WEBSITE YOU AGREE TO BE BOUND
//                 BY ALL OF THE TERMS AND CONDITIONS OF THIS
//                 AGREEMENT.
//                 <br />
//                 Contact Information:
//                 If you have any questions or comments about these our Terms of
//                 Service as outlined above, you can contact us at:<br />
//                 Couple Squad Private Limited<br />
//                 Centerspace, No B 28, Anjanasree Arcade,Annankunnu<br />
//                 Road,Kottayam,Kerala,686601<br />
//                 info@couplesquad.com
//             </p>
//         </div>
//     )
// };



import React from 'react';

const Index = () => {
    return (
        <div className="p-10 text-black bg-white">
            <h1 className="text-3xl font-bold text-center">Terms & Conditions</h1>

            <p className='mt-4'>
                Welcome to Couple Squad These Terms of Service (“Terms”) are a binding legal agreement between you (“you” or “User”) and Couple Squad Private Limited (“Couple Squad,” “we,” “us,” or “our”). By accessing or using our website, mobile application (“App”), or any other services provided by Couple Squad (collectively, the “Service” or “Platform”), you agree to be bound by these Terms, our Privacy Policy, and any additional terms, guidelines, or policies we may post.If you do not agree with these Terms, please do not use the Service.
            </p>

            <h1 className="mt-4 text-lg font-semibold">1. Scope of Service</h1>
            <p>
                Couple Squad is an online platform that facilitates personal connections through our unique Datrimony® service. The Platform includes features such as profile creation, browsing other profiles, messaging, organising live meet-ups, attending events, and additional functionalities based on your subscription plan.
            </p>
            <p className='mt-4'>
                <strong>Datrimony®</strong> is a registered trademark of CoupleSquad Private Limited. It represents our unique process that blends dating and matrimonial services to offer a seamless transition from dating to marriage. This trademarked service is exclusive to CoupleSquad and cannot be used or replicated by any other entity without the express written consent of CoupleSquad Private Limited.
            </p>

            <h1 className="mt-4 text-lg font-semibold">2. Eligibility</h1>
            <p>
                To use the Service, you must be at least 18 years old or the age of majority in your jurisdiction if older than 18. By using the Service, you represent and warrant that you have the legal capacity to enter into this Agreement and to comply with all applicable laws and regulations.
            </p>

            <h1 className="mt-4 text-lg font-semibold">3. Account Registration</h1>
            <p>
                To access and use certain features of the Service, you must register for an account. You agree to:
            </p>
            <ul>
                <li>• Provide accurate, current, and complete information during the registration process.</li>
                <li>• Maintain the security of your login credentials.</li>
                <li>• Not share your account with others or use another person’s account without permission.</li>
                <li>• Notify us immediately of any unauthorised use of your account.</li>
            </ul>
            <p>
                We reserve the right to suspend or terminate your account if any information provided during the registration process or thereafter is inaccurate, not current, or incomplete.
            </p>

            <h1 className="mt-4 text-lg font-semibold">4. User Conduct and Responsibilities</h1>
            <h1 className="text-base font-semibold">4.1 Acceptable Use</h1>
            <p>You agree to:</p>
            <ul>
                <li>• Use the Service in a manner that is lawful, respectful, and consistent with the community guidelines that we may establish from time to time.</li>
                <li>• Use your real name, real age, and accurate information when creating your profile.</li>
                <li>• Engage with other users respectfully and avoid conduct that is abusive, harassing, discriminatory, or otherwise harmful.</li>
            </ul>

            <h1 className="mt-2 text-base font-semibold">4.2 Prohibited Activities</h1>
            <p>You agree not to:</p>
            <ul>
                <li>• Impersonate any person or entity, or falsely state or otherwise misrepresent yourself, your age, or your affiliation with any person or entity.</li>
                <li>• Post or transmit any content that is illegal, harmful, defamatory, obscene, or otherwise objectionable.</li>
                <li>• Engage in any activities that are fraudulent, abusive, or harmful to others.</li>
                <li>• Attempt to disrupt, damage, or interfere with the Service, servers, or networks connected to the Service.</li>
                <li>• Use the Service to spam, phish, or otherwise send unauthorised or unwanted communications.</li>
            </ul>

            <h1 className="mt-4 text-lg font-semibold">5. Content and Intellectual Property</h1>
            <h1 className="text-base font-semibold">5.1 Your Content</h1>
            <p>
                You retain ownership of any content you post or transmit on the Platform (“Your Content”). However, by posting or transmitting Your Content, you grant Couple Squad a non-exclusive, royalty-free, perpetual, irrevocable, and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display Your Content worldwide in any media.You represent and warrant that you have all necessary rights to grant the above licence and that Your Content does not infringe any third-party rights.
            </p>

            <h1 className="mt-2 text-base font-semibold">5.2 Member Content</h1>
            <p>
                Content provided by other users (“Member Content”) is owned by the user who posted it. You may only use Member Content in connection with the Service’s intended purposes of facilitating connections and communication between users. You may not use Member Content for commercial purposes or for any illegal or harmful activities.
            </p>
            <h1 className="mt-2 text-base font-semibold">5.3 Our Content</h1>
            <p>
                All text, images, video, graphics, user interfaces, trademarks, logos, sounds, artwork, and other content provided by Couple Squad (“Our Content”) are protected by copyright, trademark, and other intellectual property laws. We grant you a limited, non-exclusive, non-transferable licence to access and use Our Content solely for personal, non-commercial use in connection with the Service.
            </p>

            <h1 className="mt-4 text-lg font-semibold">6. Virtual Items</h1>
            <p>
                Couple Squad may offer virtual items (e.g., virtual gifts, tokens) that can be purchased through the Service. These virtual items are non-transferable, non-refundable, and have no real-world value. You acknowledge that you do not own these virtual items; rather, you are granted a limited licence to use them within the Service. Couple Squad reserves the right to manage, modify, or eliminate virtual items at its discretion without notice or liability.
            </p>

            <h1 className="mt-4 text-lg font-semibold">7. Subscription Plans and Payment Terms</h1>
            <h1 className="text-base font-semibold">7.1 Subscription Plans</h1>
            <p>
                Couple Squad offers various subscription plans that provide access to different features and functionalities. Details of available plans and pricing can be found on our website or App. By subscribing, you agree to pay the subscription fees and any applicable taxes as specified during the sign-up process.
            </p>

            <h1 className="mt-2 text-base font-semibold">7.2 Automatic Renewal </h1>
            <p>
                Unless you cancel your subscription before the end of the current period, your subscription will automatically renew at the end of the subscription term. You authorise Couple Squad to charge the payment method on file for the renewal fees. You can manage your subscription and payment settings through your account.
            </p>

            <h1 className="mt-2 text-base font-semibold">7.3 Changes to </h1> Subscription Fees
            <p>
                Couple Squad reserves the right to change subscription fees at any time. If we change the fees for a subscription plan to which you are currently subscribed, we will notify you in advance. If you do not agree to the new fees, you may cancel your subscription before the changes take effect.
            </p>

            <h1 className="mt-2 text-base font-semibold">7.4 Payment Methods </h1>
            <p>
                We accept various payment methods, including credit cards, debit cards, and other payment options displayed during the purchase process. By providing payment information, you authorise Couple Squad to charge your chosen payment method for all applicable fees.
            </p>

            <h1 className="mt-2 text-base font-semibold">7.5 Refund Policy </h1>
            <p>
                All subscription fees are non-refundable unless otherwise required by law. If you cancel your subscription, you will continue to have access to the Service until the end of your current billing period, but you will not receive a refund for any unused portion of the subscription term.
            </p>

            <h1 className="mt-4 text-lg font-semibold">8. Live Meet-Ups and Events</h1>
            <h1 className="text-base font-semibold">8.1 Event Participation</h1>
            <p>
                Couple Squad organises live meet-ups and events to help users connect in person. By participating in these events, you agree to:
            </p>
            <ul>
                <li>• Comply with all instructions, rules, and guidelines provided by Couple Squad or event organisers.</li>
                <li>• Conduct yourself in a respectful and appropriate manner.</li>
                <li>• Be responsible for your own conduct and safety during the event.</li>
            </ul>

            <h1 className="mt-2 text-base font-semibold">8.2 Event Registration and Fees</h1>
            <p>
                Some events may require prior registration and payment of a fee. Event details, including fees, will be provided on the registration page. Event fees are non-refundable unless the event is cancelled by Couple Squad.
            </p>

            <h1 className="mt-2 text-base font-semibold">8.3 User Conduct at Events</h1>
            <p>
                Harassment, discrimination, or any form of abusive behavior will not be tolerated. Couple Squad reserves the right to remove any participant from an event for inappropriate conduct. You must also comply with all applicable local laws and regulations during the event.
            </p>

            <h1 className="mt-2 text-base font-semibold">8.4 Liability</h1>
            <p>
                Couple Squad is not responsible for any injuries, losses, or damages resulting from your participation in live meet-ups and events. You agree to indemnify and hold Couple Squad harmless from any claims, damages, or liabilities arising out of your participation in these events.
            </p>

            <h1 className="mt-4 text-lg font-semibold">9. Privacy and Data Security</h1>
            <p>
                Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use, and protect your information. By using the Service, you consent to the collection, use, and sharing of your information as described in the Privacy Policy.
            </p>

            <h1 className="mt-4 text-lg font-semibold">10. Disclaimers and Limitation of Liability</h1>
            <h1 className="text-base font-semibold">10.1 Disclaimer of Warranties</h1>
            <p>
                The Service is provided “as is” and “as available” without any warranties of any kind, either express or implied. We disclaim all warranties, including, but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not guarantee that the Service will be uninterrupted, secure, or error-free.
            </p>

            <h1 className="mt-2 text-base font-semibold">10.2 Limitation of Liability</h1>
            <p>
                To the maximum extent permitted by law, Couple Squad shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including, but not limited to, loss of profits, data, use, goodwill, or other intangible losses arising out of or in connection with your use of the Service.
            </p>

            <h1 className="mt-4 text-lg font-semibold">11. Termination</h1>
            <p>
                We may suspend or terminate your account and access to the Service at any time, for any reason, with or without notice. You may terminate your account at any time through your account settings. Upon termination, your right to use the Service will immediately cease. Provisions of these Terms that by their nature should survive termination shall survive, including, but not limited to, ownership provisions, disclaimers, indemnity, and limitations of liability.
            </p>

            <h1 className="mt-4 text-lg font-semibold">12. Governing Law and Dispute Resolution</h1>
            <h1 className="text-base font-semibold">12.1 Governing Law</h1>
            <p>
                These Terms are governed by and construed in accordance with the laws of India, without regard to its conflict of law principles.
            </p>

            <h1 className="mt-2 text-base font-semibold">12.2 Dispute Resolution</h1>
            <p>
                Any dispute arising out of or relating to these Terms or the Service shall be resolved through binding arbitration in accordance with the Indian Arbitration and Conciliation Act, 1996. The arbitration shall be conducted in Kottayam, Kerala, India, in English. The arbitrator’s decision shall be final and binding on the parties.
            </p>

            <h1 className="mt-4 text-lg font-semibold">13. Modifications to the Terms</h1>
            <p>
                We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms on the Service. Your continued use of the Service following the posting of changes constitutes your acceptance of such changes. It is your responsibility to review the Terms periodically for updates.
            </p>

            <h1 className="mt-4 text-lg font-semibold">14. Indemnification</h1>
            <p>
                You agree to indemnify, defend, and hold harmless Couple Squad, its affiliates, officers, directors, employees, and agents from any claims, liabilities, damages, losses, and expenses, including reasonable attorneys’ fees, arising out of or in any way connected with your access to or use of the Service, your violation of these Terms, or your violation of any rights of another.
            </p>

            <h1 className="mt-4 text-lg font-semibold">15. Contact Us</h1>
            <p>
                If you have any questions or concerns about these Terms or the Service, please contact us at info@couplesquad.com
            </p>

            <p className='mt-4'><strong>Effective Date:</strong> 20th August 2024</p>

        </div>
    )
}
export default Index