import { auth } from '..';

export const fetchConnections = async ({ connectionType }) => {
    const res = await auth({
        method: 'GET',
        url: `/connections/connection/${connectionType}`
    });

    return res;
};

export const acceptConnection = async requested_user => {
    const res = await auth({
        method: 'POST',
        url: `/connections/accept/${requested_user}`
    });

    return res?.data;
};

export const rejectConnection = async requested_user => {
    const res = await auth({
        method: 'POST',
        url: `/connections/reject/${requested_user}`,
    });

    return res?.data;
}


// New Version Api's

export const getAllSendRequest = async () => {
    const res = await auth({
        method: 'GET',
        url: "/connections/sent",
    });
    return res;
}

export const removeSendConnection = async (id) => {
    const res = await auth({
        method: 'DELETE',
        url: `/connections/sent/${id}`
    });
    return res;
}

export const getAllReceivedRequest = async () => {
    const res = await auth({
        method: 'GET',
        url: "/connections/received",
    });
    return res;
}

export const acceptConnectionRequest = async (id) => {
    const res = await auth({
        method: 'POST',
        url: `/connections/accept/${id}`,
    });
    return res;
}

export const rejectConnectionRequest = async (id) => {
    const res = await auth({
        method: 'POST',
        url: `/connections/reject/${id}`,
    });
    return res;
}

export const getAllFriendsList = async (page, limit) => {
    const res = await auth({
        method: 'GET',
        url: `/shortlist?page=${page}&limit=${limit}`,
    });
    return res;
}

// Event Connection 

export const getEventsForConnection = async () => {
    const res = await auth({
        method: 'GET',
        url: `/events/auth/connections`,
    });
    return res;
}