import React, { Suspense, lazy, memo, useState } from 'react'
import { capitalize } from 'shared/textFormats'

// Assets
import location from 'assets/discover/icons/location.png'
import block from 'assets/discover/icons/block.png'
import unblock from 'assets/discover/icons/unblock.png'
import report from 'assets/discover/icons/report.png'
import { useNavigate } from 'react-router-dom'
import { CONNECTIONS } from 'shared/router'

const BlockAndReportPopUp = lazy(() => import('components/popUps/blockAndReportUser'))

function Index({ data }) {

    const navigate = useNavigate()

    const [showPopUp, setShowPopUp] = useState(false)
    const [type, setType] = useState()

    const handleOnSuccess = () => {
        navigate(CONNECTIONS.FRIENDS)
    }

    return (
        <>
            {showPopUp &&
                <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                    <Suspense children={<BlockAndReportPopUp closeBtn={() => {
                        setShowPopUp(false)
                        setType('')
                    }}
                        onSuccess={handleOnSuccess}
                        data={data} type={type} />} />
                </div>
            }
            <div className='w-full h-fit md:h-[510px] md:border-2 bg-white md:border-div_border flex flex-col justify-start items-start shadow-sm  '>

                <div className='w-[339px] flex flex-col justify-start items-start  gap-5 py-3 ml-5'>
                    <div className='w-[339px]'>

                        <div className='flex flex-wrap gap-3'>
                            <div className='flex flex-col w-full gap-3 h-fit'>
                                <p className='font-medium text-[20px] text-text_gray'>Location</p>
                                <div className='flex gap-2'>
                                    {(data?.address?.state || data?.address?.country) &&
                                        <img src={location} alt="CoupleSquad" />
                                    }
                                    {data?.address?.state &&
                                        <>
                                            {capitalize(data?.address?.state)}
                                            ,
                                        </>
                                    }
                                    {capitalize(data?.address?.country)}
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-wrap gap-3 mt-4'>
                            <div className='flex flex-col w-full gap-3 h-fit'>
                                <p className='font-medium text-[20px] text-text_gray'>Block and Report</p>
                                <>
                                    <div className="flex items-center cursor-pointer" onClick={() => {
                                        setType(data?.blocked ? 'unBlock' : 'block')
                                        setShowPopUp(true)
                                    }}>
                                        <img src={data?.blocked ? unblock : block} alt="CoupleSquad" className='me-2' />
                                        <h1>{data?.blocked ? 'Unblock' : 'Block'}</h1>
                                    </div>

                                    <div className="flex items-center cursor-pointer" onClick={() => {
                                        setType('report')
                                        setShowPopUp(true)
                                    }}>
                                        <img src={report} alt="CoupleSquad" className='me-2' />
                                        <h1>Report</h1>
                                    </div>
                                </>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default memo(Index)