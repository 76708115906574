import React from 'react'
import plan from '../../../../assets/cards/plan.svg'
import { WALLET } from '../../../../shared/router'
import { Link } from 'react-router-dom'

function Index({ closeBtn }) {
    return (
        <div className='w-[336px] h-[398px] bg-white flex flex-col justify-center items-center m-4 border relative'>
            {closeBtn &&
                <button
                    onClick={closeBtn}
                    style={{ top: '10px', right: '10px' }}
                    className='absolute text-red-500 hover:text-red-700 focus:outline-none font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center'
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            }

            <div>
                <img src={plan} alt='limits' className='w-[114px] h-[150px] mb-2 cursor-pointer' />
            </div>
            <div className='flex flex-col justify-center items-center gap-2'>
                <p className='text-black font-medium text-[18px]'>Plan Upgrade</p>
                <p className='text-[14px] font-normal text-text_gray text-center w-[243px] h-[96px]'>To access additional features and benefits, please upgrade your current plan for a better experience.</p>
            </div>
            <div className='flex justify-center items-center'>
                <button className='w-[280px] h-[48px] bg-brandRed text-white cursor-pointer'>
                    <Link to={WALLET.PLANES} className='font-normal text-[16px]'>Continue</Link>
                </button>
            </div>
        </div>
    )
}

export default React.memo(Index)
