
import { Link } from "react-router-dom";
import successIcon from "assets/signup/success Icon.svg";

export const Success = () => {

    return (
        <div className="w-full lg:w-1/2 bg-white h-full md:h-screen flex flex-col items-center justify-center text-black">
            <img src={successIcon} alt="Success" className="mb-8" />
            <h1 className="text-3xl font-semibold mb-4 text-center">Account Created <br /> Successfully</h1>
            <p className="text-center text-sm mb-8 text-gray-700">We need you to help us with some basic <br /> information to create your account.</p>
            <Link to={'/on-boarding-1'} className="p-3 border bg-brandRed text-center text-white text-sm w-6/12 cursor-pointer">
                Let's Go
            </Link>
        </div>
    );
};
