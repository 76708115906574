import React from 'react'
import SideBar from "../../../components/sidebars/settings"
import NavBar from "../../../components/Navbars/mainHeader"
import NavBarResponsive from "../../../components/Navbars/mainHeader/responsive"
import img from "../../../assets/settings/img/logout.png"
import { Link, useNavigate } from 'react-router-dom'
import { AUTH, SETTINGS } from '../../../shared/router'
import { userLogout } from '../../../apis/settings'
import { markAsOffline } from 'socket/SocketManager'
import LazyImage from 'components/image/lazyLoading'
import { useDispatch } from 'react-redux'
import { resetUserProfile } from '../../../redux/slice/userProfile'

function Index() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const goOffline = () => {
        markAsOffline();
        sessionStorage.removeItem('onlineSet');
    };

    const handleUserLogOut = async () => {
        const res = await userLogout()
        if (res?.success) {
            dispatch(resetUserProfile());
            localStorage?.removeItem('session')
            navigate(AUTH.LOGIN)
            goOffline()
        }
    }

    return (

        <div>
            <div className="flex flex-col h-screen bg-white">
                {/* Navbar */}
                <div className="text-white h-18">
                    <div className="hidden lg:block">
                        <NavBar />
                    </div>
                    <div className="block lg:hidden">
                        <NavBarResponsive />
                    </div>
                </div>
                <div className="flex flex-1 overflow-hidden bg-[#f9f9f9]">
                    <div className="hidden w-2/12 bg-white border-e-2 border-e-gray-300 lg:block">
                        <SideBar />
                    </div>

                    {/* Main content area for Logout centered */}
                    <div className="flex items-center justify-center flex-1 md:my-20">
                        <div className="w-full md:w-[550px] h-full flex flex-col items-center justify-center bg-white text-black rounded-lg">
                            <h1 className='my-5 text-xl font-semibold md:text-4xl'>Logout</h1>
                            <LazyImage src={img} alt="" className="w-40 h-40" />
                            <h1 className='px-5 my-5 text-base font-semibold text-center md:text-2xl'>Are you sure you want to logout?</h1>
                            <div className="flex justify-around w-full px-10 text-center">
                                <Link to={SETTINGS.SETTINGS} className='py-2 px-5 w-[40%] md:w-[170px] border rounded hover:bg-gray-200 transition-colors'>Cancel</Link>
                                <button className='py-2 px-5 w-[40%] md:w-[170px] text-white bg-brandRed rounded hover:bg-red-700 transition-colors' onClick={handleUserLogOut}>Logout</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Index