
import { useEffect } from 'react';
import { socket } from '../socket/SocketManager';

export const useSocketListenerWithConnectedSocket = (eventName, handler) => {
    useEffect(() => {

        if (socket) {
            socket.on(eventName, handler);
            return () => {
                socket.off(eventName, handler);
            };
        }
    }, [eventName, handler]);
};
