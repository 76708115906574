import { Suspense, lazy, useEffect, useState } from 'react'

import NavBar from 'components/Navbars/mainHeader'
import NavBarResponsive from 'components/Navbars/mainHeader/responsive'
import ResponsiveBottomNav from 'components/Navbars/responsiveBottomNav'
import EventsCard from '../../../components/Cards/Connection/events'
import Tab from '../../../components/Navbars/ConnectionTabNav'
import { getEventsForConnection } from '../../../apis/connections'
import { getEventBasedConnectionRequest } from '../../../apis/matches'

const Swipper = lazy(() => import("../../../components/discover/eventConnectionBased"))
const NoConnections = lazy(() => import("../../../components/Cards/emptyCards/noConnections"))
const Footer = lazy(() => import('components/Footer'))
const FooterResponsive = lazy(() => import('components/Footer/responsive'))

function Index() {

    const [events, setEvents] = useState()
    const [showMatches, setShowMatches] = useState(false)
    const [eventConnections, setEventConnections] = useState()


    const fetchEventsForConnection = async () => {
        const res = await getEventsForConnection()
        setEvents(res?.data)
    }

    useEffect(() => {
        fetchEventsForConnection()
    }, [])

    const handleShowMatches = async (id) => {
        const res = await getEventBasedConnectionRequest(id)
        setEventConnections(res?.data?.users)
        setShowMatches(true)
    }


    return (
        <div className="flex flex-col h-full bg-[#f9f9f9]">
            {/* Navbar */}
            <div className="text-white bg-white h-18">
                <div className="hidden lg:block">
                    <NavBar />
                </div>
                <div className="block lg:hidden">
                    <NavBarResponsive />
                </div>
            </div>
            <ResponsiveBottomNav />

            {showMatches ?
                <Suspense fallback={<div>loading...</div>}>
                    <Swipper matches={eventConnections} handleCloseBtn={() => setShowMatches(false)} />
                </Suspense>
                :

                <>
                    {/* Tabs */}
                    <div className="flex flex-col items-center w-full bg-[#F9F9F9] mt-5">
                        <div className='w-10/12 h-full mt-3'>
                            <Tab count={events?.length} />
                        </div>
                    </div>

                    <div className="flex flex-col items-center w-full f-full bg-[#f9f9f9] mt-5 mb-20">
                        <div className='w-10/12 mt-3'>
                            {events?.length > 0 ?
                                <div className='flex flex-wrap gap-6'>
                                    {events?.map((item) => (
                                        <div className='cursor-pointer' onClick={() => handleShowMatches(item?.eventId)}>
                                            <EventsCard
                                                data={item}
                                            />
                                        </div>
                                    ))}
                                </div>
                                :
                                <div className='flex justify-center'>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <NoConnections decryption={"You haven't attended an event yet. Explore this section after attending your first event."} />
                                    </Suspense>
                                </div>
                            }
                        </div>
                    </div>
                </>
            }
            <Suspense fallback={<div>Loading...</div>}>
                <div className="hidden w-full bg-black lg:block">
                    <Footer />
                </div>
                <div className="mb-10 block bg-black lg:hidden">
                    <FooterResponsive />
                </div>
            </Suspense>
        </div>
    )
}

export default Index