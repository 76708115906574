import React, { useState, memo } from 'react';
import close from 'assets/discover/close.svg';
import LazyImage from 'components/image/lazyLoading';

function Index({ data, isPreview = false }) {

    const [prevImgUrl, setPrevImgUrl] = useState(null)

    return (
        <div className='flex flex-col items-start justify-start w-full h-full mx-auto'>
            <div className='h-fit md:border-2 md:border-div_border min-h-[510px] min-w-[350px]'>
                <p className='font-medium text-[20px] text-gray-600 m-3'>Gallery</p>
                <div className='grid grid-cols-3 m-3 cursor-pointer'>
                    {data?.images?.length > 0 &&
                        <>
                            {data?.images.map((image, index) => (
                                <div key={index} onClick={() => setPrevImgUrl(image.url)} className={`${isPreview ? 'mb-2' : 'mb-3'}`}>
                                    <LazyImage src={image.url} alt='gallery' className={`${isPreview ? 'w-[100px] h-[120px]' : 'w-[120px] h-[150px]'}`} />
                                </div>
                            ))}
                        </>
                    }
                </div>
            </div>

            {prevImgUrl !== null && (
                <div className='fixed top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50'>
                    <div className='relative'>

                        <button className="absolute z-50 -mt-8 text-sm text-white transform -translate-x-1/2 left-1/2"
                            onClick={() => setPrevImgUrl(null)}>
                            <img src={close} alt='close' className='w-[24px] h-[24px]' />
                        </button>

                        <LazyImage src={prevImgUrl} alt='selectedimage' className='w-fit h-[550px]' />
                    </div>
                </div>

            )}
        </div>
    );
}

export default memo(Index);
