import SideBar from "../../../components/sidebars/settings"
import instagramIcon from '../../../assets/profileEdit/connectedAccounts/instagramIcon.png'
import instaCircleIcon from '../../../assets/profileEdit/connectedAccounts/instaCircle.png'
import NavBar from "../../../components/Navbars/mainHeader"
import ProfileEditNav from '../../../components/Navbars/profileEdit'

function Index() {

    const items = Array.from({ length: 4 });

    return (
        <div>
            <div className="flex flex-col h-full">
                {/* Navbar */}
                <div className="bg-white text-white  h-18">
                    <NavBar />
                </div>

                <div className="flex flex-1 overflow-hidden bg-white">
                    <div className="bg-[#edf0f7] w-3/12">
                        <SideBar />
                    </div>

                    <div className="flex flex-col w-10/12">
                        <ProfileEditNav />
                        <div className="flex-1 overflow-auto p-4 lg:w-[45%] md:w-[60%] sm:w-[100%] text-black m-10 ms-14">
                            <h1 className="font-semibold text-xl mb-7">Connected Accounts</h1>
                            <div className="w-full border border-gray-200">

                                <div className="flex w-10/12 justify-between items-center m-5 ms-10">
                                    <div className="flex justify-center items-center">
                                        <img src={instagramIcon} alt="" />
                                        <h1 className="font-semibold text-xl ms-2">Connect Instagram</h1>
                                    </div>
                                    <img src={instaCircleIcon} alt="" className="h-fit" />
                                </div>
                                <div className="w-11/12 flex justify-center mt-4 text-black">
                                    <p className="text-lg text-gray-500">simply dummy text of the printing and <br /> typesetting industry. Lorem Ipsum has <br /> been the industry's standard dummy text <br /> ever since </p>
                                </div>

                                <div className="flex gap-3 overflow-hidden w-11/12 justify-center mt-5 ms-14">
                                    {
                                        items.map(() => (

                                            <div className="border border-gray-500 min-w-[100px] min-h-[100px] rounded-2xl flex justify-center items-center">
                                                <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="0.361328" y="0.957031" width="30.5041" height="30.5041" rx="15.252" fill="#F83758" />
                                                    <path d="M7.47656 16.2109H23.7454" stroke="white" stroke-width="3.3392" stroke-linecap="round" />
                                                    <path d="M15.6133 24.3398V8.07101" stroke="white" stroke-width="3.3392" stroke-linecap="round" />
                                                </svg>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
