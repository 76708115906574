import SideBar from "../../../components/sidebars/settings"
import { useEffect, useState } from "react";
import AdvancedImageCropping from "../../../components/image/ImageCropping/AdvancedCropping";
import { addImage, deleteImage, getImages } from "../../../apis/onBoarding";
import { toast } from "react-toastify";
import NavBar from "../../../components/Navbars/mainHeader"
import NavBarResponsive from "../../../components/Navbars/mainHeader/responsive"
import ProfileEditNav from '../../../components/Navbars/profileEdit'
import LazyImage from "components/image/lazyLoading";
import { useDispatch } from "react-redux";
import { resetUserProfile } from "../../../redux/slice/userProfile";

function Index() {

    const dispatch = useDispatch()
    const [images, setImages] = useState(
        Array.from({ length: 6 }, (_, index) => ({ imageId: `image-${index}`, url: '' }))
    );

    const [isCropping, setIsCropping] = useState(false);
    const [fileChangeIndex, setFileChangeIndex] = useState(null);
    const [currentImageForCropping, setCurrentImageForCropping] = useState(null);

    useEffect(() => {
        handleStateUpdate()
    }, [])

    const handleFileChange = (event, id) => {
        if (event.target.files && event.target.files[0]) {
            const fileReader = new FileReader();
            fileReader.onload = function (e) {
                setCurrentImageForCropping(e.target.result);
                setIsCropping(true);
            };
            fileReader.readAsDataURL(event.target.files[0]);
        }
    };

    const onCropComplete = async (croppedFile) => {
        try {
            const toastId = toast.loading("Uploading Image Please wait")
            if (fileChangeIndex) {
                await addImage({ index: fileChangeIndex, aspect_ratio: 12 / 16, file: croppedFile })
                setFileChangeIndex(null)
            } else {
                const index = images.findIndex(img => img.url === '');
                await addImage({ index: index + 1, aspect_ratio: 12 / 16, file: croppedFile })
            }
            await handleStateUpdate()
            setIsCropping(false);
            toast.dismiss(toastId)
        } catch (error) {
            toast.error('Something went wrong while uploading Image')
        }
    };

    const handleStateUpdate = async () => {
        try {
            const res = await getImages();
            const defaultImages = Array.from({ length: 6 }, (_, index) => ({
                imageId: `image-${index}`,
                url: ''
            }));
            const updatedImages = defaultImages.map((img, Index) => {
                if (Index < res.data.length) {
                    return {
                        imageId: `image-${res.data[Index].index}/${res.data[Index].imageId}`,
                        url: res.data[Index].url
                    };
                }
                return img;
            });
            setImages(updatedImages);

        } catch (error) {
            toast.error('Something went wrong')
        }
    };



    const handleRemoveImage = async (id) => {
        try {
            const toastId = toast.loading("Deleting Image please wait")
            const extractedIndex = id.split('/')[0].split('-')[1];
            const index = parseInt(extractedIndex, 10);
            await deleteImage({ index })
            await handleStateUpdate()
            toast.dismiss(toastId)
        } catch (error) {
            toast.error('Something went wrong while deleting Image')
        }
    };

    useEffect(() => {
        return () => {
            dispatch(resetUserProfile());
        }
    }, [])



    return (
        <div>
            {isCropping && (
                <div className='absolute top-0 left-0 z-50 flex flex-col items-center justify-center w-full h-full overflow-x-hidden overflow-y-scroll '>
                    <div className="w-full h-full max-w-lg p-4 bg-white rounded-lg">
                        <AdvancedImageCropping
                            image={currentImageForCropping}
                            setPopup={setIsCropping}
                            onCropComplete={onCropComplete}
                            circularCrop={false}
                            ratio={12 / 16}
                        />
                    </div>
                </div>
            )}
            <div className="flex flex-col h-full min-h-screen bg-white">
                {/* Navbar */}
                <div className="text-white bg-white h-18">
                    <div className="hidden lg:block">
                        <NavBar />
                    </div>
                    <div className="block lg:hidden">
                        <NavBarResponsive />
                    </div>
                </div>

                <div className="flex flex-1 overflow-hidden bg-white">
                    <div className="bg-[#edf0f7] w-3/12 hidden lg:block">
                        <SideBar />
                    </div>

                    <div className="flex flex-col items-center justify-center md:items-start md:justify-start w-full md:max-w-[90%]">
                        <div className="w-[98%] ms-2 ">
                            <ProfileEditNav />
                        </div>

                        <div className="flex flex-col items-center justify-center md:items-start md:justify-start overflow-auto p-4 text-black md:m-10 md:ms-14 lg:w-[50%] w-[100%]">
                            <h1 className="text-2xl font-semibold">Photos & Gallery</h1>
                            <p className="my-5 text-base text-gray-500">Make changes to your account</p>
                            <div className="flex flex-wrap items-center justify-center gap-4 md:items-start md:justify-start">
                                {images.map((imgPreview, index) => (
                                    <div key={imgPreview.imageId} className="relative flex flex-col items-center justify-center border border-gray-200 w-[110px] h-[140px] md:w-[162px] md:h-[214px]">
                                        {imgPreview.url ? (
                                            <>
                                                <LazyImage src={imgPreview.url} alt={`preview ${index + 1}`} className="w-[110px] h-[140px] md:w-[162px] md:h-[214px] outline-none border-none" />
                                                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-20"></div>
                                                <div className="absolute bottom-0 flex items-center space-x-2 transform -translate-x-1/2 -translate-y-1/2 left-1/2">
                                                    {index + 1 === 1 && (
                                                        <button onClick={() => document.getElementById(`input-${imgPreview.imageId}`).click()} >
                                                            <svg width="30" height="30" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M16 4H6.66667C5.95942 4 5.28115 4.28095 4.78105 4.78105C4.28095 5.28115 4 5.95942 4 6.66667V25.3333C4 26.0406 4.28095 26.7189 4.78105 27.219C5.28115 27.719 5.95942 28 6.66667 28H25.3333C26.0406 28 26.7189 27.719 27.219 27.219C27.719 26.7189 28 26.0406 28 25.3333V16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path d="M24.4993 3.5003C25.0298 2.96987 25.7492 2.67188 26.4993 2.67188C27.2495 2.67188 27.9689 2.96987 28.4993 3.5003C29.0298 4.03073 29.3278 4.75016 29.3278 5.5003C29.3278 6.25045 29.0298 6.96987 28.4993 7.5003L15.9993 20.0003L10.666 21.3336L11.9993 16.0003L24.4993 3.5003Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </button>
                                                    )}
                                                    {index + 1 !== 1 && (
                                                        <button onClick={() => handleRemoveImage(imgPreview.imageId)} >
                                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M24.0007 25.3333C24.0007 26.3942 23.5792 27.4116 22.8291 28.1618C22.0789 28.9119 21.0615 29.3333 20.0007 29.3333H10.6673C9.60645 29.3333 8.58904 28.9119 7.83889 28.1618C7.08875 27.4116 6.66732 26.3942 6.66732 25.3333V9.33333H5.33398V5.33333H11.334L12.6673 4H18.0007L19.334 5.33333H25.334V9.33333H24.0007V25.3333ZM8.00065 9.33333V25.3333C8.00065 26.0406 8.2816 26.7189 8.7817 27.219C9.2818 27.719 9.96007 28 10.6673 28H20.0007C20.7079 28 21.3862 27.719 21.8863 27.219C22.3864 26.7189 22.6673 26.0406 22.6673 25.3333V9.33333H8.00065ZM24.0007 8V6.66667H18.6673L17.334 5.33333H13.334L12.0007 6.66667H6.66732V8H24.0007ZM10.6673 12H12.0007V25.3333H10.6673V12ZM18.6673 12H20.0007V25.3333H18.6673V12Z" fill="#FCFCFC" />
                                                            </svg>
                                                        </button>
                                                    )}
                                                </div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => {
                                                        handleFileChange(e, imgPreview.imageId)
                                                        setFileChangeIndex(index + 1)
                                                    }}
                                                    id={`input-${imgPreview.imageId}`}
                                                    className="hidden"
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => handleFileChange(e, imgPreview.imageId)}
                                                    id={imgPreview.imageId}
                                                    className="hidden mb-2"
                                                />
                                                <label htmlFor={imgPreview.imageId} className="text-sm text-center text-gray-500 cursor-pointer">Choose an image</label>
                                                <button onClick={() => document.getElementById(imgPreview.imageId).click()} className="absolute bottom-[-2px] right-[-6px] w-6 h-6 bg-white rounded-full" style={{ width: '25px', height: '25px' }}>
                                                    <svg width="35" height="35" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect width="50" height="50" rx="26.8501" fill="#F83758" />
                                                        <path d="M14 26.6055H38" stroke="white" stroke-width="3.58002" stroke-linecap="round" />
                                                        <path d="M26.127 39V15" stroke="white" stroke-width="3.58002" stroke-linecap="round" />
                                                    </svg>

                                                </button>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div >
    )
}

export default Index
