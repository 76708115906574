import React, { memo, useState } from 'react'
import { capitalize } from 'shared/textFormats'

// Assets
import men from 'assets/discover/men.svg'
import female from 'assets/discover/GenderFemale.svg'
import cm from 'assets/discover/cm.svg'
import kg from 'assets/discover/kg.svg'
import jobIcon from 'assets/discover/lawyer2.svg'
import { requestContactInfo, viewContactInfo } from 'apis/contact'

import phone from 'assets/discover/icons/phone.png'

function Index({ data }) {

    const content = [
        { img: false, text: capitalize(data?.personal?.marital_status) },
        { img: data?.personal?.sex === 'male' ? men : female, text: capitalize(data?.personal?.sex) },
        { img: jobIcon, text: capitalize(data?.personal?.profession) },
        // { img: cm, text: `${data?.personal?.height}cm` },
        { img: cm, text: data?.personal?.height && `${data?.personal?.height}cm` },
        { img: kg, text: data?.personal?.weight && `${data?.personal?.weight}kg` },
    ]

    const [contactInfo, setContactInfo] = useState(
        {
            enabled: data?.preferred_contacts?.personal_contact?.enabled,
            status: data?.preferred_contacts?.personal_contact?.status,
            requestId: data?.preferred_contacts?.personal_contact?.requestId,
            request_type: data?.preferred_contacts?.personal_contact?.request_type,
            contactNumber: false,
            relation: false,
        }
    );


    const handleRequestContactInfo = async (type) => {
        const res = await requestContactInfo(data?.id, type);
        if (res?.success) {
            setContactInfo((prevContactInfo) => ({
                ...prevContactInfo,
                status: res.data?.status
            }));

        }
    };

    const handleViewContactInfo = async (id) => {
        const res = await viewContactInfo(id);
        setContactInfo((prevContactInfo) => ({
            ...prevContactInfo,
            contactNumber: prevContactInfo.requestId === res?.data?.request_id ? res?.data?.contact : prevContactInfo.contactNumber,
        }));
    };

    const classState = [
        { title: 'request', style: 'bg-gray-200 border text-subText' },
        { title: 'accepted', style: 'text-white bg-blue-500' },
        { title: 'requested', style: 'text-white bg-brandRed' },
        { title: 'rejected', style: 'text-white bg-black' },
        { title: 'expired', style: 'text-white bg-subText' },
    ]

    const getStyleByStatus = (status) => {
        const state = classState.find((state) => state.title === status);
        return state ? state.style : '';
    };

    const handleOnclickContactInfo = (status) => {
        if (status === 'request') {
            handleRequestContactInfo(contactInfo?.request_type)
        } else if (status === 'accepted') {
            if (contactInfo?.contactNumber === false) handleViewContactInfo(contactInfo?.requestId)
        }
    }

    return (
        <div className='w-full h-fit md:h-[510px] md:border-2 bg-white md:border-div_border flex flex-col justify-start items-start shadow-sm  '>

            <div className='w-[339px] flex flex-col justify-start items-start  gap-5 py-3 ml-5'>
                <div className='flex flex-col gap-3'>
                    <p className='font-medium text-lg md:text-[20px] text-text_gray'>My basics</p>
                    <div className='flex flex-wrap gap-2'>
                        {content.map((item, index) => (
                            <>{item?.text &&
                                <div key={index} className='w-fit h-[46px]  flex justify-center items-center border-2 border-border_color rounded-full gap-1 p-2'>
                                    {item?.img &&
                                        <img src={item?.img} alt='coupleSquad' />
                                    }
                                    <p className='font-normal text-black text-[16px]'>{item?.text}</p>
                                </div>
                            }</>
                        ))}
                    </div>
                </div>

                {contactInfo?.enabled &&
                    <div className="flex items-center mb-4">
                        <div className="w-full">
                            <h2 className="font-medium text-lg md:text-[20px] text-text_gray mb-2">
                                Contact Mode
                            </h2>
                            <div className="flex items-center justify-between w-full">
                                <button className="flex items-center gap-2 px-4 py-2 text-gray-600 border border-gray-300 rounded-full">
                                    <img src={phone} alt="phone" />
                                    Personal
                                </button>

                                <button className={`flex ms-20 items-center px-6 py-2 font-semibold transition rounded-lg ${getStyleByStatus(contactInfo?.status)}`}
                                    onClick={() => handleOnclickContactInfo(contactInfo?.status)}
                                >
                                    {contactInfo?.status === 'accepted' && contactInfo?.contactNumber === false ? 'View'
                                        : contactInfo?.status === 'accepted' && contactInfo?.contactNumber !== false ? contactInfo?.contactNumber
                                            : capitalize(contactInfo?.status)}
                                </button>
                            </div>
                        </div>
                    </div>
                }



                {data?.languages_known?.others.length > 0 &&
                    <div className='w-[174px] h-[89px] flex flex-col gap-3'>
                        <p className='font-medium text-lg md:text-[20px] text-text_gray'>Languages known</p>
                        <div className='flex gap-2'>
                            {data?.languages_known?.others.map((language) => (
                                <div className='w-fit h-[46px]  flex justify-center items-center border-2 border-border_color rounded-full p-3'>
                                    <p className='font-normal text-black text-[16px]'>{capitalize(language)}</p>
                                </div>
                            ))}

                        </div>
                    </div>
                }

                {data?.personal?.looking_for &&
                    <div className='w-fit h-[89px] flex flex-col  gap-3'>
                        <p className='font-medium text-lg md:text-[20px] text-text_gray'>Marital Status</p>
                        <div className='w-fit h-[46px]  flex justify-center items-center  border-2 border-border_color rounded-full gap-2 p-3 '>
                            <p className='font-normal text-black text-[16px]'>{capitalize(data?.personal?.marital_status)}</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default memo(Index)