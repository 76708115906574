import React, { memo, useEffect, useState } from 'react'
import { capitalize } from 'shared/textFormats'

// Assets
import parent from 'assets/discover/icons/parent.png'
import person from 'assets/discover/icons/inPerson.png'
import phone from 'assets/discover/icons/phone.png'
import wineIcon from 'assets/profileEdit/diet/wineIcon.svg'
import smokingIcon from 'assets/profileEdit/diet/smokingIcon.svg'
import { requestContactInfo, viewContactInfo } from 'apis/contact'

function Index({ data }) {
    const [contactInfo, setContactInfo] = useState(
        {
            enabled: data?.preferred_contacts?.family_contact?.enabled,
            status: data?.preferred_contacts?.family_contact?.status,
            requestId: data?.preferred_contacts?.family_contact?.requestId,
            request_type: data?.preferred_contacts?.family_contact?.request_type,
            contactNumber: false,
            relation: data?.family?.relationship,
        },
    );

    const content = [
        { title: "Drinking & Smoking", text: [{ img: wineIcon, text: capitalize(data?.misc?.drinking) }, { img: smokingIcon, text: capitalize(data?.misc?.smoking) }] },
        { title: "Family", text: [{ img: person, text: capitalize(data?.family?.parent_name) }, { img: parent, text: capitalize(data?.family?.relationship) }] },
    ]


    const interestBgColors = ['bg-blue-700', 'bg-yellow-700', 'bg-orange-700', 'bg-green-700']
    const interestTextColors = ['text-blue-700', 'text-yellow-700', 'text-orange-700 ', 'text-green-700']


    const handleRequestContactInfo = async (type) => {
        const res = await requestContactInfo(data?.id, type);
        if (res?.success) {
            setContactInfo((prevContactInfo) => ({
                ...prevContactInfo,
                status: res.data?.status
            }));
        }
    };

    const handleViewContactInfo = async (id) => {
        const res = await viewContactInfo(id)
        setContactInfo((prevContactInfo) => ({
            ...prevContactInfo,
            contactNumber: prevContactInfo.requestId === res?.data?.request_id ? res?.data?.contact : prevContactInfo.contactNumber,
        }));
    }

    const classState = [
        { title: 'request', style: 'bg-gray-200 border text-subText' },
        { title: 'accepted', style: 'text-white bg-blue-500' },
        { title: 'requested', style: 'text-white bg-brandRed' },
        { title: 'rejected', style: 'text-white bg-black' },
        { title: 'expired', style: 'text-white bg-subText' },
    ]

    const getStyleByStatus = (status) => {
        const state = classState.find((state) => state.title === status);
        return state ? state.style : '';
    };

    const handleOnclickContactInfo = (status) => {
        if (status === 'request') {
            handleRequestContactInfo(contactInfo?.request_type)
        } else if (status === 'accepted') {
            if (contactInfo?.contactNumber === false) handleViewContactInfo(contactInfo?.requestId)
        }
    }

    return (

        <div className='w-full h-fit md:h-[510px] md:border-2 bg-white md:border-div_border flex flex-col justify-start items-start shadow-sm  '>

            <div className='w-[339px] flex flex-col justify-start items-start  gap-5 py-3 ml-5'>
                <div className='w-[339px]'>


                    <div className='flex flex-wrap gap-2'>
                        {content.map((item, index) => (
                            <>
                                {item?.text[0]?.text &&
                                    <div key={index} className='flex flex-col w-full gap-3 h-fit'>
                                        <p className='font-medium text-[20px] text-gray-700'>{item?.title}</p>
                                        <div className='flex gap-2'>
                                            {item?.text.map((info, index) => (
                                                <>{info?.text &&
                                                    <div key={index} className='flex items-center justify-center p-2 border-2 rounded-full w-fit h-fit border-border_color'>
                                                        {info?.img &&
                                                            <img src={info?.img} alt='coupleSquad' />
                                                        }
                                                        <p className='font-normal text-black text-[16px] ms-1'>{capitalize(info?.text)}</p>
                                                    </div>
                                                }</>
                                            ))}
                                        </div>
                                    </div>
                                }</>
                        ))}
                    </div>


                    {/* Request Contact Info */}
                    {contactInfo?.enabled &&
                        <div className="flex items-center my-4">
                            <div className="w-full">
                                <h2 className="font-medium text-lg md:text-[20px] text-text_gray mb-2">
                                    Contact Mode
                                </h2>
                                <div className="flex items-center justify-between w-full">
                                    <button className="flex items-center gap-2 px-4 py-2 text-gray-600 border border-gray-300 rounded-full">
                                        <img src={phone} alt="phone" />
                                        Personal
                                    </button>

                                    <button className={`flex ms-20 items-center px-6 py-2 font-semibold transition rounded-lg ${getStyleByStatus(contactInfo?.status)}`}
                                        onClick={() => handleOnclickContactInfo(contactInfo?.status)}
                                    >
                                        {contactInfo?.status === 'accepted' && contactInfo?.contactNumber === false ? 'View' : capitalize(contactInfo?.status)}
                                    </button>
                                </div>
                            </div>
                        </div>
                    }

                    {data?.misc?.interests.length > 0 &&
                        <div className='flex flex-col gap-2 my-3'>
                            <p className='font-medium text-[20px] text-gray-700'>Interested In</p>
                            <div className='flex flex-wrap gap-3'>
                                {data?.misc?.interests.map((item, index) => (
                                    <div className={`w-fit h-[44px] ${interestBgColors[index]} bg-opacity-20  flex justify-center items-center  rounded-full gap-1 p-3`}>
                                        <p className={`font-normal ${interestTextColors[index]}  text-[16px]`}>#{item}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }

                </div>

            </div>
        </div>
    )
}

export default memo(Index)