import React, { memo } from 'react'
import avatar from "../../../assets/avatar.png"
import LazyImage from 'components/image/lazyLoading'
import { isHTML } from 'utils/helper'


function Index({ images, name, lastMessage, handleOnclick }) {
    return (
        <div className='w-full h-[70px] my-5 cursor-pointer overflow-hidden' onClick={handleOnclick}>
            <div className="flex">
                <LazyImage src={images ? images : avatar} className='w-[75px] h-[70px] rounded-xl' />

                <div className='flex flex-col justify-center ms-5'>
                    <h1 className='text-base font-bold text-black'>{name}</h1>
                    <h1 className='text-gray-500 text-sm max-w-[225px] max-h-[35px] overflow-hidden'>
                        {isHTML(lastMessage) ? (
                            <div dangerouslySetInnerHTML={{ __html: lastMessage }} />
                        ) : (
                            <p>{lastMessage}</p>
                        )}
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default memo(Index)