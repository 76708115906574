// Assets
import singlesWhiteIcon from '../assets/onBoarding/whiteIcons/singles_white_icon.svg';
import singlesBlackIcon from '../assets/onBoarding/blackIcons/singles_black_icon.svg';
import couplesWhiteIcon from '../assets/onBoarding/whiteIcons/couples_white_icon.svg';
import couplesBlackIcon from '../assets/onBoarding/blackIcons/couples_black_icon.svg';

// Dummy Data
export const EDUCATIONAL_QUALIFICATION = ["B.E/B Tech", "BCA"];

export const INDUSTRY = ["Software", "Marketing"];

export const PROFESSION = ["Engineer", "Doctor"];

export const RELATIONSHIP_STATUS = ['single', 'divorced', 'widowed',];

export const PREFERRED_RELATIONSHIP_STATUS = [
    { value: 'single', label: 'Unmarried' },
    { value: 'divorced', label: 'Divorced' },
    { value: 'widowed', label: 'Widowed' },
    // {value: 'married', label: 'married'},
]

export const paymentAmountOptions = [500, 1000, 2000, 5000];

export const HIGHEST_EDUCATION_QUALIFICATION = ["Select", "High School", "Bachelor's", "Master's", "Ph.D.", "Other"];

export const OCCUPATIONS = [
    "Software Developer/IT Professional",
    "Doctor/Medical Professional",
    "Engineer",
    "Business Manager/Executive",
    "Banker/Finance Professional",
    "Teacher/Educator",
    "Entrepreneur",
    "Farmer/Agricultural Worker",
    "Research Scientist",
    "Lawyer/Legal Professional",
    "Nurse/Healthcare Worker",
    "Retail Salesperson",
    "Mechanic/Technician",
    "Artist/Performer",
    "Chef/Culinary Professional",
    "Journalist/Media Professional",
    "Graphic Designer/Web Designer",
    "Human Resource Professional",
    "Digital Marketing",
    "Others"
];

export const HOW_OFTEN_PRAY = ['Regularly', 'Sometimes', 'Never'];

export const LIFESTYLE_CHOICES = [
    {
        title: 'What are your dietery preferences',
        values: ['Vegeterian', 'Non vegeterian', 'Vegan', 'Eggetarian'],
        key: 'diet',
    },
    {
        title: 'How often do you drink?',
        values: ['Not for me', 'Sobber', 'Socially', 'Occasionaly'],
        key: 'drinking',
    },
    {
        title: 'How often do you smoke?',
        values: ['Social Smoker', 'Non-smoker', 'Trying to quit'],
        key: 'smoking'
    },
    {
        title: 'Do you workout',
        values: ['Everyday', 'Often', 'Sometimes', 'Never'],
        key: 'workout'
    }
];
export const PARTNER_LIFESTYLE_CHOICES = [
    {
        title: 'Dietery preferences ?',
        values: ['Vegeterian', 'Non vegeterian', 'Vegan', 'Eggetarian'],
        key: 'diet',
    },
    {
        title: 'Drinking ?',
        values: ['Not for me', 'Sobber', 'Socially', 'Occasionaly'],
        key: 'drinking',
    },
    {
        title: 'Smoking ?',
        values: ['Social Smoker', 'Non-smoker', 'Trying to quit'],
        key: 'smoking'
    },
    {
        title: 'Workout ?',
        values: ['Everyday', 'Often', 'Sometimes', 'Never'],
        key: 'workout'
    }
];

export const INTERESTS_AND_HOBBIES = ['Cycling', 'Trekking', 'Swimming', 'Reading', 'Walking', 'Escape Cafe'];

export const PERSONALITY_TRAITS = ['Outgoing', 'Introvert', 'Adventurous', 'Artistic', 'Ambivert', 'Extrovert'];

export const FAMILY_BACKGROUND = ['Select', 'Traditional', 'Modern', 'Liberal', 'Mixed'];

export const MARRIAGE_TIMELINE = ['Select', 'Within a year', '1-2 years', 'Open timeline'];

export const PREFFERED_LOCATION = ['Select', 'Same city', 'Anywhere in india', 'Open to NRI (Non-Resident-Indian)'];

export const VERIFICATION_CALL_LINK = 'https://calendly.com/couplesquad/verification-call'

// Basic Info
export const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' }
]

export const relationshipStatusOptions = [
    { value: 'single', label: 'Single' },
    { value: 'couple', label: 'Couple' }
]

export const physicalStatusOptions = [
    { value: 'none', label: 'None' },
    { value: 'disabled', label: 'Disabled Person' },
]

export const singlesMartialStatusOPtions = [
    { label: 'Unmarried', value: "unmarried" },
    { label: 'Divorced', value: "divorced" },
    { label: 'Widowed', value: "widowed" }
]

export const couplesMartialStatusOPtions = [
    { label: 'In a relationship', value: "in_a_relationship" },
    { label: 'Engaged', value: "engaged" },
    { label: 'Married', value: "married" }
]

export const lookingForOptions = [
    { value: 'marriage', label: 'Marriage' },
    { value: 'dating', label: 'Dating' },
    { value: 'friendship', label: 'Friendship' },
    { value: 'im_not_sure_yet', label: 'Im not sure yet' },
]
export const lookingForOptionsCouples = [
    { value: 'meetup', label: 'Meetup' },
    { value: 'events', label: 'Events' },
    { value: 'friendship', label: 'Friendship' },
    { value: 'im_not_sure_yet', label: 'Im not sure yet' },
]

export const timeLineOptions = [
    { value: 'less_than_6_months', label: 'Less than 6 months' },
    { value: '1year', label: '1 Years' },
    { value: '1_2years', label: '1-2 Years' },
]

// Diet Options
export const dietDrinkingOptions = [
    { value: 'non_drinker', label: 'Non Drinker' },
    { value: 'social_drinker', label: 'Social Drinker' },
]

export const dietSmokingOptions = [
    { value: 'non_smoker', label: 'Non Smoker' },
    { value: 'occasional_smoker', label: 'Occasional Smoker' },
]

export const dietEatingOptions = [
    { value: 'Omnivore', label: 'Omnivore' },
    { value: 'vegetarian/vegan', label: 'Vegetarian/Vegan' },
    { value: "i'm_flexible", label: "I'm Flexible" },
]

export const workoutOptions = [
    { value: 'regularly', label: 'Regularly' },
    { value: 'weekly', label: 'Weekly' },
    { value: "sometimes", label: "Sometimes" },
    { value: "not_do", label: "Not do" },
]

export const educationOptions = [
    { value: 'under_graduate', label: 'Under Graduate' },
    { value: 'post_graduate', label: 'Post Graduate' },
    { value: "12th", label: "12th" },
]