import React, { useState, useEffect, Suspense, lazy, useRef } from 'react'
import ImageCarousel from '../../components/Home/ImageCarousel'
import EventCard from '../../components/Cards/event/EventCardWithAndWithOutSaveBtn'

import NavBar from '../../components/Navbars/mainHeader'
import NavBarResponsive from '../../components/Navbars/mainHeader/responsive'
import ResponsiveBottomNav from '../../components/Navbars/responsiveBottomNav'
import refrelImg from '../../assets/home/images/refrel.png'
import { checkPlanExpiry, expiryCoin, getEventByTimeline, getEventTags, getEventsByTags, getEventsForHomeBanner, getReferLink, getSavedEvent } from '../../apis/home'
import { capitalize } from '../../shared/textFormats'
import { getAllCategories, saveAndUnSaveEvent } from '../../apis/events'
import PlanExpired from "../../components/Cards/emptyCards/planExpired"
import { toast } from 'react-toastify'
import LazyImage from 'components/image/lazyLoading'
import { encodeToBase64 } from 'utils/encodeDecode'
import { EVENTS } from 'shared/router'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Footer = lazy(() => import('components/Footer'))
const FooterResponsive = lazy(() => import('components/Footer/responsive'))
const EmptyEvents = lazy(() => import('../../components/Cards/emptyCards/emptyEvents'))
const ToVerifyAccount = lazy(() => import('components/popUps/toVerifyAccount'))

function Index() {
    const location = useLocation();
    const navigate = useNavigate()
    const userInfo = useSelector((state) => state.userProfile);


    const [planExpire, setPlanExpire] = useState(false)
    const [showVerificationPopUp, setShowVerificationPopUp] = useState(false)
    const [nameOf, setNameOf] = useState({
        tag: '',
        timeLIne: ''
    })
    const [bannerData, SetBannerData] = useState()

    const [eventTags, setEventTags] = useState()
    const [selectedEventTag, setSelectedEventTag] = useState([])
    const [tagBasedEvents, setTagBasedEvents] = useState([])

    const [category, setCategory] = useState()
    const [pagination, setPagination] = useState({
        tagBasedEvent: 1,
        timeLineBasedEvent: 1
    })

    const events = [
        { label: 'Upcoming', value: 'upcoming' },
        { label: 'Ongoing', value: 'ongoing' },
        { label: 'Past', value: 'past' },
        { label: 'Saved', value: 'saved' },
    ]
    const [timeLineBasedEvents, setTimeLineBasedEvents] = useState()
    const [selectedTimeLine, setSelectedTimeLine] = useState(events[0].value)



    const fetchEventTags = async () => {
        const res = await getEventTags()
        setEventTags(res?.data)
        setNameOf(prev => ({ ...prev, tag: capitalize(res?.data[0]?.name) }))
        // updateSelectedTags(res?.data[1]?.id)
        handleFetchEventByTag(1, { tags: [] })
    }
    const fetchAllCategory = async () => {
        const res = await getAllCategories()
        setCategory(res)
    }

    const handleFetchEventByTag = async (page = 1, data) => {
        const res = await getEventsByTags(page, data)
        if (res?.data) {
            setTagBasedEvents(prev => ([...prev, ...res?.data]))
        }
        setPagination(prev => ({ ...prev, tagBasedEvent: res?.pagination.next_page }))
    }

    const handleFetchEventByTimeline = async (page = 1, selectedTimeLine) => {
        if (page === 1) {
            setTimeLineBasedEvents([])
        }
        const res = selectedTimeLine === 'saved' ? await getSavedEvent(page) : await getEventByTimeline(page, selectedTimeLine)
        setTimeLineBasedEvents(prevEvents => [...prevEvents, ...res?.data]);
        setPagination(prev => ({ ...prev, timeLineBasedEvent: res?.pagination.next_page }))
    }



    const handlePlanExpire = async () => {
        const res = await expiryCoin()
        if (res?.data?.expired) {
            setPlanExpire(res?.data?.expired)
        }
    }

    const fetchBannerEvents = async () => {
        const res = await getEventsForHomeBanner()
        SetBannerData(res?.data)
    }

    const handleReferee = async () => {
        const res = await getReferLink()
        if (res?.data) {
            if (!navigator.clipboard) {
                toast.error("Clipboard API not supported in this browser.");
            } else {
                toast.success('Invitation link copied to clipboard')
                await navigator.clipboard.writeText(res?.data);
            }
        }
    }

    useEffect(() => {
        fetchEventTags()
        // handleFetchEventByTag(1)
        fetchAllCategory()
        checkPlanExpiry()
        handlePlanExpire()
        fetchBannerEvents()
        handleFetchEventByTimeline(1, selectedTimeLine)
        handleShowVerificationPopUp()
    }, [])

    const handleSaveAndUnSaveEvent = async (id) => {
        await saveAndUnSaveEvent({ eventId: id })
    }

    const navigateToFilter = (id) => {
        const filterParams = new URLSearchParams({
            categoryId: encodeToBase64(id)
        }).toString();

        navigate(`${EVENTS.EVENT_FILTER}?${filterParams}`);
    }

    const updateSelectedTags = (id) => {
        setSelectedEventTag((prevSelectedTags) => {
            if (prevSelectedTags.includes(id)) {
                const res = prevSelectedTags.filter((tag) => tag !== id);
                handleFetchEventByTag(1, { tags: [...res] })
                return res
            } else {
                handleFetchEventByTag(1, { tags: [...prevSelectedTags, id] })
                return [...prevSelectedTags, id];
            }
        });
    };

    const handleShowVerificationPopUp = () => {
        const prevPath = location.state?.from;
        if (prevPath === '/login') {
            if (userInfo?.verification?.status === 'pending') {
                setShowVerificationPopUp(true)
            }
        }
    }


    return (
        <div className="flex flex-col h-full bg-white">

            {planExpire &&
                <div className="absolute inset-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-75">
                    <Suspense children={<PlanExpired closeBtn={() => setPlanExpire(false)} />} fallback={<div>loading...</div>} />
                </div>
            }
            {showVerificationPopUp &&
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                    <div className="relative">
                        <Suspense children={<ToVerifyAccount closeBtn={() => setShowVerificationPopUp(false)} />} fallback={<div>loading...</div>} />
                    </div>
                </div>
            }

            {/* Navbar */}
            <div className="text-white bg-white h-18">
                <div className="hidden lg:block">
                    <NavBar />
                </div>
                <div className="block lg:hidden">
                    <NavBarResponsive />
                </div>
            </div>
            <ResponsiveBottomNav />

            <div className="z-10 flex flex-col items-center w-full mt-5 bg-white">
                <div className='w-10/12 mt-3 -z-20'>
                    {bannerData &&
                        <ImageCarousel assets={bannerData} />
                    }
                </div>
            </div>

            {/* Tag Based Events */}
            <div className="flex flex-col items-center w-full bg-white">
                <div className='w-10/12 h-full mb-16'>
                    <div className='flex flex-wrap gap-2 mt-10 mb-7'>
                        {eventTags?.map((item) => (
                            <div key={item.id} className={`w-fit p-1 px-3 cursor-pointer ${selectedEventTag.includes(item.id) ? 'text-red-500 border-red-500 font-semibold' : 'text-gray-500 border-gray-500'} border-2 rounded-full`}
                                onClick={() => {
                                    updateSelectedTags(item?.id)
                                    setTagBasedEvents([])
                                    setNameOf(prev => ({ ...prev, tag: capitalize(item?.name) }))
                                }}
                            >
                                {capitalize(item.name)}
                            </div>
                        ))}
                    </div>

                    <div className="flex flex-wrap gap-4 mt-5 mb-10 ">
                        {tagBasedEvents?.length > 0 ?
                            <>
                                {tagBasedEvents?.map((item) => (
                                    <div key={item.id} className='cursor-pointer min-h-[315px] max-h-[315px]'>
                                        <EventCard
                                            data={item}
                                            saveEvent={true}
                                            SaveEventHandler={() => handleSaveAndUnSaveEvent(item.id)}
                                            onClickNavigate={`/event/details/${item.slug}`}
                                        />
                                    </div>
                                ))}
                            </>
                            :
                            <Suspense children={<EmptyEvents mainText={`No Events in ${nameOf.tag}`} subText={`You Have No Events in ${nameOf.tag} Tag`} />} fallback={<div>loading...</div>} />
                        }
                    </div>
                    {pagination.tagBasedEvent !== null && (
                        <p className='text-center text-black cursor-pointer' onClick={() => handleFetchEventByTag(pagination.tagBasedEvent, { tags: selectedEventTag })}>See more</p>
                    )}

                </div>
            </div>


            {/* Time Line Based Events */}
            <div className="flex flex-col items-center w-full bg-[#F9F9F9] my-5">
                <div className='w-10/12 h-full mt-3'>
                    <div className='flex flex-wrap gap-2 mb-10 mt-28'>
                        {events?.map((item, index) => (

                            <div key={index} className={`w-fit p-1 md:px-3 text-base md:text-2xl cursor-pointer ${selectedTimeLine === item.value ? 'text-red-500 border-red-500 font-semibold border-b-2' : 'text-black'}`}
                                onClick={() => {
                                    setSelectedTimeLine(item.value)
                                    handleFetchEventByTimeline(1, item.value)
                                    setNameOf(prev => ({ ...prev, timeLIne: item?.label }))
                                }}
                            >
                                {item.label}
                            </div>
                        ))}
                    </div>

                    <div className="flex flex-wrap gap-4 mt-5 mb-20 ">
                        {timeLineBasedEvents?.length > 0 ?
                            <>
                                {timeLineBasedEvents?.map((item) => (
                                    <div key={item.id} className='min-h-[315px] max-h-[315px]'>
                                        <EventCard
                                            data={item}
                                            saveEvent={false}
                                            onClickNavigate={`/event/details/${item?.slug}`}
                                        />
                                    </div>
                                ))}
                            </>
                            :
                            <Suspense children={<EmptyEvents mainText={`No ${nameOf.timeLIne} Events`} subText={`You Have No ${nameOf.timeLIne} Events`} />} fallback={<div>loading...</div>} />
                        }
                    </div>
                    {pagination.timeLineBasedEvent !== null && (
                        <p className='text-center text-black cursor-pointer' onClick={() => handleFetchEventByTimeline(pagination.timeLineBasedEvent, selectedTimeLine)}>See more</p>
                    )}
                </div>
            </div>

            {/* category Based Events */}
            <div className="flex flex-col items-center w-full mt-16 bg-white">
                <div className='w-10/12 h-full mb-14'>
                    {/* Heading */}
                    <div className='flex items-center justify-between w-full'>
                        <div className='flex-grow mb-5 text-xl font-medium text-center text-black sm:text-3xl md:text-4xl lg:text-5xl'>
                            <h1>The people platform <br />Where interests become friendships</h1>
                        </div>
                    </div>
                    {/* Category Card */}
                    <div className="grid grid-cols-1 gap-4 p-4 md:grid-cols-2 lg:grid-cols-4">
                        {category?.map((item) => (
                            <div className='flex flex-col'>
                                <LazyImage key={item?.id} src={item?.image} alt={`${item?.name}-category`} className={'w-[300px] h-[330px] cursor-pointer'}
                                    onClick={() => navigateToFilter(item?.id)}
                                />
                                <div className="text-black mt-2 text-center text-lg max-w-[300px]">{capitalize(item?.name)}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Invitation Section */}
            <div className="flex flex-col items-center w-full bg-white">
                <div className='w-[84%] ms-[34px] h-full mb-16 relative'> {/* Add relative here */}
                    {/* Responsive Absolute positioned Invite Button */}
                    <button className="z-50 absolute top-[48%] left-[4%] md:top-[48%] md:left-[4%] lg:top-[185px] lg:left-[55px] px-1 py-1 sm:px-2 sm:py-1 md:px-5 md:py-2 lg:px-5 lg:py-2 bg-brandRed text-white text-xs md:text-md lg:text-lg" onClick={handleReferee}>
                        Invite
                    </button>

                    <LazyImage src={refrelImg} className="w-full h-full" />
                </div>
            </div>


            <Suspense fallback={<div>Loading...</div>}>
                <div className="hidden w-full bg-black lg:block">
                    <Footer />
                </div>
                <div className="block mb-8 bg-black lg:hidden">
                    <FooterResponsive />
                </div>
            </Suspense>

        </div >
    )
}

export default Index