import React, { lazy, Suspense, useEffect, useState } from 'react'

// Components
import Navbar from 'components/Navbars/siteIntro'
import Banner from 'components/siteIntro/blog/banner'
import { fetchAllBlogs } from 'apis/siteIntro/blog'

const BlogCards = lazy(() => import("components/siteIntro/blog/blogCard"))
const Footer = lazy(() => import("components/Footer"))
const LandingPageResponsiveFooter = lazy(() => import("components/Footer/responsive"))

function Index() {

    const [blogs, setBlogs] = useState([])
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getAllBlogs = async (page) => {
        const res = await fetchAllBlogs(page)
        if (res?.success) {
            setBlogs([...blogs, ...res?.data])
            const pagination = res?.pagination
            setPageNation({ currentPage: pagination?.current_page, nextPage: pagination?.next_page, prevPage: pagination?.previous_page })
        }
    }



    useEffect(() => {
        getAllBlogs(1)
    }, [])

    return (
        <div className='bg-white'>
            <div className="fixed top-0 z-50 w-full">
                <Navbar />
            </div>

            <Banner />

            <div>
                <div className="grid w-full grid-cols-1 gap-4 p-4 mx-auto md:grid-cols-2 lg:grid-cols-3 lg:w-[80%]">
                    {blogs?.map((blog, index) => (
                        <Suspense key={index} fallback={<div>Loading...</div>}>
                            <BlogCards blog={blog} />
                        </Suspense>
                    ))}
                </div>

                {pageNation.nextPage !== null &&
                    <p className='my-5 text-lg text-center text-blue-400 cursor-pointer hover:underline'
                        onClick={() => getAllBlogs(pageNation.nextPage)}
                    >see more</p>
                }
            </div>


            <Suspense fallback={<div>Loading...</div>}>
                <div className="hidden w-full mt-5 bg-black lg:block">
                    <Footer />
                </div>
                <div className="block mt-5 bg-black lg:hidden">
                    <LandingPageResponsiveFooter />
                </div>
            </Suspense>
        </div >
    )
}

export default Index