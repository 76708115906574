import React, { useRef, memo, useState, useCallback, useEffect } from 'react'
import { smoothScroll } from '../../../utils/scroller/scrollingEffects';
import onlineUserIcon from "../../../assets/chat/icon/onlineUser.svg"
import scrollUpIcon from "../../../assets/chat/icon/scrollUp.svg"
import scrollDownIcon from "../../../assets/chat/icon/scrollDown.svg"
import { useSocketListenerWithConnectedSocket } from '../../../hooks/socketListern';
import { getOnlineUsers } from '../../../socket/SocketManager';
import { toast } from "react-toastify"
import avatar from "../../../assets/avatar.png"
import LazyImage from 'components/image/lazyLoading';

function Index({ direction }) {

    const [onLineUsers, setOnlineUsers] = useState([])
    const scrollContainerRef = useRef(null);

    const scrollUp = () => {
        if (scrollContainerRef.current) {
            smoothScroll(scrollContainerRef.current, -200, 500);
        }
    };

    const scrollDown = () => {
        if (scrollContainerRef.current) {
            smoothScroll(scrollContainerRef.current, 200, 500);
        }
    };


    const handleFetchAllOnlineUsers = () => {
        getOnlineUsers()
    }


    // Socket Lister's
    // Socket Error Lister
    const handleErrorMessage = useCallback((data) => {
        toast.error(data.message)
    }, [])
    useSocketListenerWithConnectedSocket("error", handleErrorMessage)


    const handleGetAllOnlineUsersData = useCallback((data) => {
        if (!data || !data.online) {
            return;
        }
        setOnlineUsers(prevUsers => {
            const newUsers = data.online.filter(newUser => {
                return !prevUsers.some(existingUser => existingUser.id === newUser.id);
            });
            return [...prevUsers, ...newUsers];
        });
    }, []);
    useSocketListenerWithConnectedSocket("clientOnlineUsers", handleGetAllOnlineUsersData);


    const handleGetNewOnlineUsers = useCallback((data) => {
        if (!data || !data.user) {
            return;
        }
        setOnlineUsers(prevUsers => {
            const isUserAlreadyPresent = prevUsers.some(existingUser => existingUser.id === data.user.id);
            if (isUserAlreadyPresent) {
                return prevUsers;
            }
            return [...prevUsers, data.user];
        });
    }, []);
    useSocketListenerWithConnectedSocket("clientOnlineStatus", handleGetNewOnlineUsers);


    const handleGetOfflineUsers = useCallback((data) => {
        if (!data || !data.user) {
            return;
        }
        setOnlineUsers(currentUsers => {
            const filteredUsers = currentUsers.filter(user => {
                if (!user || !user.user) {
                    return true;
                }
                return user.user.id !== data.user.id;
            });
            return filteredUsers;
        });
    }, []);
    useSocketListenerWithConnectedSocket("clientOfflineStatus", handleGetOfflineUsers);


    useEffect(() => {
        handleFetchAllOnlineUsers()
    }, [])

    return (
        <div>
            {onLineUsers.length > 0 &&
                <div className="w-full mx-auto mt-10 lg:w-10/12">
                    <div className="flex">
                        <LazyImage src={onlineUserIcon} />
                        <h1 className="text-lg font-bold text-black">Online</h1>
                    </div>
                    <LazyImage src={scrollUpIcon} className="hidden my-3 cursor-pointer ms-5 lg:block" onClick={scrollUp} />

                    <div ref={scrollContainerRef} className={`overflow-auto max-h-[calc(100vh-150px)] hide-scrollbar ${direction === 'row' ? 'flex flex-row' : 'flex flex-col'}`}>
                        <>
                            {onLineUsers
                                .filter(item => item.id)
                                .map((item, index) => (
                                    <div className="ms-3 lg:ms-5 lg:my-5" key={index}>
                                        <LazyImage src={item.photo ? item.photo : avatar} alt={item.name || "User"} className="h-[60px] w-[60px] rounded-xl" />
                                        <p className="text-sm text-black m-2 max-w-[60px]" title={item.name}>{item.name}</p>
                                    </div>
                                ))}
                        </>
                    </div>

                    <LazyImage src={scrollDownIcon} className="hidden my-3 cursor-pointer ms-5 lg:block" onClick={scrollDown} />
                </div>
            }
        </div>
    )
}

export default memo(Index)