import React, { lazy, Suspense, useEffect, useState } from 'react'
import { walletHistory } from '../../../apis/wallet'
import History from "../../../components/Wallet/history"

import SideBar from "../../../components/sidebars/walletSideBar"
import NavBar from "../../../components/Navbars/mainHeader"
import NavBarResponsive from "components/Navbars/mainHeader/responsive"

const Footer = lazy(() => import("components/Footer"))
const LandingPageResponsiveFooter = lazy(() => import("components/Footer/responsive"))

function Index() {

    const [walletHistoryData, setWalletHistoryData] = useState()
    const [pagination, setPagination] = useState({
        next_page: null,
        limit: 20,
    })

    const getWalletHistory = async () => {
        let res;
        if (pagination.next_page) {
            res = await walletHistory(pagination.next_page, pagination.limit);
        } else {
            res = await walletHistory(1, 20);
        }
        setPagination(res?.pagination);
        setWalletHistoryData(prev => {

            if (prev && Array.isArray(prev)) {
                return [...prev, ...res?.data];
            }
            return res?.data || [];
        });
    }


    useEffect(() => {
        getWalletHistory()
    }, [])

    return (
        <div className="flex flex-col h-full bg-[#f9f9f9]">
            {/* Navbar */}
            <div className="text-white bg-white h-18">
                <div className="hidden lg:block">
                    <NavBar />
                </div>
                <div className="block lg:hidden">
                    <NavBarResponsive />
                </div>
            </div>

            <div className="flex">
                <div className="hidden w-2/12 lg:block">
                    <SideBar />
                </div>
                <div className='w-[98%] md:w-[80%] mx-auto'>
                    <div className="py-12">
                        <History data={walletHistoryData} />
                    </div>
                    {pagination.next_page &&
                        <p className='text-center text-blue-500 cursor-pointer mb-7' onClick={() => getWalletHistory()}>see more</p>
                    }
                </div>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
                <div className="hidden w-full bg-black lg:block">
                    <Footer />
                </div>
                <div className="block bg-black lg:hidden">
                    <LandingPageResponsiveFooter />
                </div>
            </Suspense>
        </div>
    )
}

export default Index