

import cross from 'assets/discover/charm_cross.svg'
import messageSend from 'assets/discover/icon-park-outline_message-sent.svg'
import star from 'assets/discover/ic_round-star.svg'

export const Actions = ({ handleSkip, handleConnect, handleMutualConnection }) => {
    return (
        <>
            {/* Skip */}
            {handleSkip &&
                <div className='w-[50px] h-[50px] border border-brandRed rounded-xl flex justify-center items-center cursor-pointer' onClick={handleSkip}>
                    <img src={cross} alt='coupleSquad' className='w-[32px] h-[32px]' />
                </div>
            }

            {/* Connect */}
            {handleConnect &&
                <div className='w-[60px] h-[60px] bg-brandRed flex justify-center items-center rounded-xl cursor-pointer' onClick={handleConnect}>
                    <img src={messageSend} alt='coupleSquad' className='w-[32px] h-[32px]' />
                </div>
            }

            {/* Mutual Connection */}
            {handleMutualConnection &&
                <div className='w-[50px] h-[50px] flex justify-center items-center border border-yellow-700 rounded-xl cursor-pointer' onClick={handleMutualConnection}>
                    <img src={star} alt='coupleSquad' className='w-[32px] h-[32px]' />
                </div>
            }
        </>
    );
};