import React, { Suspense, lazy, useCallback, useEffect, useState } from 'react'
import SideBar from "../../../components/sidebars/settings"
import NavBar from "../../../components/Navbars/mainHeader"
import NavBarResponsive from "../../../components/Navbars/mainHeader/responsive"
import { useSelector } from 'react-redux';
import { fetchVerificationStatus, submitVerificationData } from '../../../apis/settings';
import { getUserWalletDetails } from '../../../apis/wallet';
import avatar from "../../../assets/avatar.png"

// Assets
import refrelSection from "../../../assets/settings/img/refrelSection.png"
import refrelCodeShareIcon from "../../../assets/settings/icons/refrelCodeShareIcon.png"
import refrelCodeCopyIcon from "../../../assets/settings/icons/refrelCodeCopyIcon.png"
import { getReferLink } from 'apis/home';
import { toast } from 'react-toastify';


const VerificationOverview = lazy(() => import('../../../components/verification/overView'));
const IdProof = lazy(() => import('../../../components/verification/idProof'));
const UploadPhoto = lazy(() => import('../../../components/verification/uploadPhoto'));
const CurrentStatus = lazy(() => import('../../../components/verification/currentStatus'));


function Index() {
    const userInfo = useSelector((state) => state.userProfile);
    const [verificationStatus, setVerificationStatus] = useState({
        status: null,
        message: null
    })
    const [isVisible, setIsVisible] = useState({
        settings: true,
        verifyOverViewPage: false,
        verifyDocUploadPage: false,
        verifyPicUploadPage: false,
        currentStatus: false
    })

    const [data, setData] = useState({
        id_type: null,
        id_front_side: null,
        id_back_side: null,
        nationality: null,
        user_photo: null,
    })
    const [walletDetails, setWalletDetails] = useState()

    const handleVerificationData = (data) => {
        setData(prev => ({
            ...prev,
            ...data
        }));
        if (data.user_photo) {
            handleSubmitVerificationData()
        }
    }


    const handleChangeVisibility = useCallback((key) => {
        setIsVisible(prev => ({
            ...Object.keys(prev).reduce((acc, currentKey) => {
                acc[currentKey] = false;
                return acc;
            }, {}),
            [key]: true
        }));
    }, []);

    const getVerificationStatus = async () => {
        const res = await fetchVerificationStatus()
        if (res?.data?.status === "pending") {
            handleChangeVisibility("verifyOverViewPage")
        } else {
            setVerificationStatus(res?.data)
            handleChangeVisibility("currentStatus")
        }
    }

    const getWalletDetails = async () => {
        const res = await getUserWalletDetails()
        setWalletDetails(res?.data)
    }

    const handleSubmitVerificationData = async () => {

        const formData = new FormData();
        formData.append('id_front_side', data.id_front_side)
        formData.append('id_back_side', data.id_back_side)
        formData.append('user_photo', data.user_photo)
        formData.append('id_type', data.id_type)
        formData.append('nationality', data.nationality)
        const toastId = toast.loading("updating please wait")
        const res = await submitVerificationData(formData)
        if (res.success) {
            handleChangeVisibility("currentStatus")
        }
        setVerificationStatus({ status: res?.data?.status, message: res?.data?.message })
        toast.dismiss(toastId)
    }

    useEffect(() => {
        getWalletDetails()
    }, [])

    const handleReferee = async (isLink, code = false) => {
        if (isLink) {
            const res = await getReferLink()
            if (res?.data) {
                if (!navigator.clipboard) {
                    toast.error("Clipboard API not supported in this browser.");
                } else {
                    await navigator.clipboard.writeText(res?.data);
                    toast.success('Invitation link copied to clipboard')
                }
            }
        } else if (code) {
            if (!navigator.clipboard) {
                toast.error("Clipboard API not supported in this browser.");
            } else {
                await navigator.clipboard.writeText(code);
                toast.success('Code copied to clipboard')
            }
        }
    }

    return (
        <div>
            <div className="flex flex-col h-full">
                {/* Navbar */}
                <div className="text-white bg-white h-18">
                    <div className="hidden lg:block">
                        <NavBar />
                    </div>
                    <div className="block lg:hidden">
                        <NavBarResponsive />
                    </div>
                </div>

                {/* Content Area */}
                <div className="flex flex-1 overflow-hidden bg-[#f9f9f9]">
                    {/* Sidebar */}
                    <div className="hidden w-2/12 bg-white lg:block border-e-2 border-e-gray-300">
                        <SideBar />
                    </div>
                    <div className={`w-[99%] lg:w-9/12 mx-auto ${isVisible.settings ? 'h-screen' : ''}`}>
                        {isVisible.settings && (
                            <div className='flex flex-col items-center justify-center md:justify-start md:items-start'>
                                <div className="lg:mx-0 mt-10 bg-[#df1a3c] rounded-md w-[98%] lg:w-full h-[112px] flex justify-between text-white text-center">
                                    {/* profile pic and name */}
                                    <div className="flex items-center justify-center ms-3">
                                        <img src={userInfo.photo ? userInfo?.photo : avatar} alt="" className='rounded-full h-[40px] w-[40px] lg:h-[60px] lg:w-[60px]' />
                                        <div className='flex flex-col items-start ms-2'>
                                            <p className='font-bold text-base lg:text-[20px]'>{userInfo?.name}</p>
                                            <p className='text-sm'>{userInfo?.phone}</p>
                                        </div>
                                    </div>

                                    {/* Verify User */}
                                    <div className="flex items-center justify-center cursor-pointer ms-3" onClick={() => getVerificationStatus()}>
                                        <p className='p-2 px-4 text-sm bg-blue-500 me-4'>{userInfo?.verification?.status === 'verified' ? "verified" : "Verify"}</p>
                                    </div>
                                </div>

                                {/* Referral */}
                                <div className="h-24 bg-black text-white w-[99%] md:w-[60%] lg:w-[42%] mt-4 lg:mt-8 flex items-center justify-center rounded-md">
                                    <img src={refrelSection} alt="Insights icon" className="my-4 ms-2 lg:ms-0 mr-4 h-[40px] w-[40px] md:h-[50px] md:w-[50px]" />
                                    <div className='px-4'>
                                        <p className='text-sm lg:text-[16px]'>Invite Friends and Earn <span className='text-[#5d5def]'> 300 coins</span></p>
                                        <div className="flex items-center text-[14px] mt-3">
                                            <p className=''>Share your code</p>
                                            <p className='mx-2'>{walletDetails?.referral_code}</p>
                                            <img src={refrelCodeCopyIcon} alt="refrelCodeCopyIcon" className='cursor-pointer' onClick={() => handleReferee(false, walletDetails?.referral_code)} />
                                        </div>
                                    </div>
                                    <img src={refrelCodeShareIcon} alt="refrelCodeShareIcon" className="my-4 cursor-pointer ms-4 me-2 lg:me-0 h-[40px] w-[40px] md:h-[50px] md:w-[50px]" onClick={() => handleReferee(true)} />
                                </div>
                            </div>
                        )}
                        {isVisible.verifyOverViewPage &&
                            <Suspense fallback={<div>Loading...</div>}>
                                <VerificationOverview handleOnClick={() => handleChangeVisibility("verifyDocUploadPage")} />
                            </Suspense>
                        }
                        {isVisible.verifyDocUploadPage &&
                            <Suspense fallback={<div>Loading...</div>}>
                                <IdProof handleVerificationData={handleVerificationData} handleOnClick={() => handleChangeVisibility("verifyPicUploadPage")} />
                            </Suspense>
                        }
                        {isVisible.verifyPicUploadPage &&
                            <Suspense fallback={<div>Loading...</div>}>
                                <UploadPhoto handleVerificationData={handleVerificationData} />
                            </Suspense>
                        }
                        {isVisible.currentStatus &&
                            <Suspense fallback={<div>Loading...</div>}>
                                <CurrentStatus
                                    handleOnClick={() => handleChangeVisibility("settings")}
                                    status={verificationStatus.status}
                                    text={verificationStatus.message}
                                />
                            </Suspense>
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Index