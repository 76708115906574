import React, { useState, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import SideBar from "../../../components/sidebars/settings"
import NavBar from "../../../components/Navbars/mainHeader"
import NavBarResponsive from "../../../components/Navbars/mainHeader/responsive"

import ImgPreview from "../../../components/discover/imgPreview"
import Details from "components/discover/details"
import DetailsResponsive from "components/discover/details/responsive"
import { getUserProfilePreview } from '../../../apis/settings';


function Index() {

    const [userData, setUserData] = useState()

    const fetchUserProfilePreview = async () => {
        const res = await getUserProfilePreview()
        if (res?.data) {
            setUserData(res?.data)
        }
    }

    useEffect(() => {
        fetchUserProfilePreview()
    }, [])


    return (
        <div className="flex flex-col h-full min-h-screen bg-[#efefef]">
            {/* Navbar */}
            <div className="text-white bg-white h-18">
                <div className="hidden lg:block">
                    <NavBar />
                </div>
                <div className="block lg:hidden">
                    <NavBarResponsive />
                </div>
            </div>

            <div className="flex overflow-hidden bg-[#efefef]">
                <div className="hidden w-2/12 lg:block border-e-2 border-e-gray-300">
                    <SideBar />
                </div>

                <div className='flex flex-col gap-x-10 items-start justify-center flex-grow w-full mt-[3%] lg:flex-row'>

                    {/* ImgPreview Component */}
                    <div className="h-[510px] w-full md:w-[360px]">
                        <ImgPreview data={userData} />
                    </div>

                    <div className="hidden md:block h-[580px] w-[60%] overflow-y-auto">
                        <Details data={userData} />
                    </div>
                    <div className="block overflow-y-auto md:hidden">
                        <DetailsResponsive data={userData} />
                    </div>
                </div>


            </div>
        </div>

    )
}

export default Index