
import { useNavigate } from "react-router-dom";
import bg_img from "../../../assets/onBoarding/bgImages/pageOneBgImage.svg"
import { useEffect, useState } from "react";
import { nameValidation } from "../../../utils/validations/onBoarding";
import { toast } from "react-toastify";
import * as Yup from 'yup'

function Index() {

    const navigate = useNavigate()
    const [name, setName] = useState(null)

    useEffect(() => {
        const serializedObject = localStorage.getItem('userData');
        const userData = JSON.parse(serializedObject);
        if (userData?.onBoardingOne) {
            navigate('/on-boarding-2')
        } else if (userData?.data?.name) {
            setName(userData.data.name)
        }

    }, [])

    const handleLetsGo = async () => {
        try {
            await nameValidation.validate({ name }, { abortEarly: false });
            const serializedObject = localStorage.getItem('userData');
            const currentUserData = JSON.parse(serializedObject) || {};
            const updatedUserData = {
                ...currentUserData,
                onBoardingOne: true,
                data: {
                    ...currentUserData.data,
                    name: name,
                },
            };
            localStorage.setItem('userData', JSON.stringify(updatedUserData));
            navigate('/on-boarding-2');
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    };


    return (
        <div className="flex flex-col min-h-screen bg-white lg:flex-row">
            <div className="relative flex-1 hidden overflow-hidden bg-center bg-cover lg:block lg:h-auto h-1/2 lg:w-full">
                <img src={bg_img} alt="" className="object-cover w-full h-screen" />

                <div className="absolute inset-0 flex flex-col items-center justify-center text-white bg-black bg-opacity-50">
                    <h1 className="p-4 font-semibold text-7xl">The Real Story <br /> Begins With <br /> Couple Squad.</h1>
                    <p className="p-4">Finding something valuable isn't an easy task. We are aware of <br /> that, that's why Couple Squad is here.</p>
                </div>
            </div>


            <div className="relative flex flex-col items-center justify-center flex-1 w-full h-screen my-10 bg-white lg:h-auto lg:w-6/12 lg:my-0 text-brandRed ">
                <div className="w-[90%] mb-5 md:w-7/12 lg:mb-0">
                    <h1 className="text-3xl font-semibold md:text-5xl">Let’s build your <br /> profile!</h1>
                    <p className="my-5 text-xl font-semibold text-black md:text-2xl">What’s your name?</p>
                    <input type="text" value={name} placeholder="Enter your name" className="w-full px-4 py-2 mt-2 text-black bg-white border md:w-10/12"
                        onChange={(e) => setName(e.target.value)}
                    />
                    <p className="my-5 text-sm text-gray-500">This is how it will appear on your profile</p>
                    <button className="w-full p-3 mt-10 text-sm text-white border md:w-10/12 bg-brandRed" onClick={handleLetsGo}>
                        Let's Go
                    </button>
                </div>

                <div className="absolute inset-x-0 bottom-0 lg:bottom-10">
                    <div className="w-full bg-gray-200">
                        <div className="py-1 text-xs leading-none text-center text-white bg-brandRed" style={{ width: "14%" }}></div>
                    </div>
                </div>
            </div>


        </div>

    );
}

export default Index;