import React, { Suspense, lazy, useCallback, useState } from 'react'
import SideBar from "../../../components/sidebars/walletSideBar"
import NavBar from "../../../components/Navbars/mainHeader"
import NavBarResponsive from "components/Navbars/mainHeader/responsive"
import { getWalletRechargeDetails, paymentStatus } from '../../../apis/wallet'
import { toast } from 'react-toastify'
import { WALLET } from '../../../shared/router'
import Recharge from 'components/Wallet/recharge'
import { useNavigate } from 'react-router-dom'

const PlanUpgrade = lazy(() => import("../../../components/Cards/emptyCards/PlanUpgrade"))
const Footer = lazy(() => import("components/Footer"))
const LandingPageResponsiveFooter = lazy(() => import("components/Footer/responsive"))

const RazorPay = lazy(() => import("components/payment/commanRazorPay"));
const PaymentSummary = lazy(() => import("components/payment/paymentSummary"));
const PaymentSuccessPopUp = lazy(() => import("components/popUps/success/paymentSuccess"));

function Index() {
    const navigate = useNavigate()
    const [planUpgrade, setPlanUpgrade] = useState(null)

    const [razoorpayPaymentStatus, setRazoorpayPaymentStatus] = useState(false)
    const [paymentStructure, setPaymentStructure] = useState()
    const [paymentData, setPaymentData] = useState({
        orderId: null,
        total: null,
        paymentId: null,
        status: null,
        gst: null,
        coupon: null,
        discounted_amount: 0
    })
    const [visible, setVisible] = useState({
        coin: true,
        paymentSummary: false,
        razorpay: false,
        paymentSuccess: false,
    })

    const handleCoinsRecharge = async (amount) => {
        const toastId = toast.loading("please wait a second")
        if (!amount) {
            toast.error("Please select the amount")
        }
        const res = await getWalletRechargeDetails({ amount: amount })
        if (res?.response?.data?.errno === 402) {
            setPlanUpgrade(true)
        } else if (res?.success) {
            const data = { ...res.payment_structure, id: res?.data?.id }
            setPaymentStructure(data)
            handleChangeVisibility('paymentSummary')
        }
        toast.dismiss(toastId)
    }

    // NEW
    const handleChangeVisibility = useCallback((key) => {
        setVisible(prev => ({
            ...Object.keys(prev).reduce((acc, currentKey) => {
                acc[currentKey] = false;
                return acc;
            }, {}),
            [key]: true
        }));
    }, []);

    const handlePay = (value) => {
        setPaymentData(prev => ({ ...prev, ...value }))
        handleChangeVisibility("razorpay")
    }

    const handleDoneBtn = () => {
        navigate(WALLET.WALLET)
    }

    const updateThePayment = async (data) => {
        const { total, ...other } = data
        const res = await paymentStatus(other)
        if (res?.success) {
            handleChangeVisibility('paymentSuccess')
        }
    }

    const razorpaySuccess = async (value) => {
        if (value?.status === 'failed') {
            handleChangeVisibility('paymentSuccess')
            setRazoorpayPaymentStatus(true)
        } else {
            const data = {
                ...paymentData,
                paymentId: value.paymentId,
                status: value.status
            }
            updateThePayment(data)
        }
    }

    return (
        <div className="flex flex-col h-full bg-white">
            {planUpgrade &&
                <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                    <Suspense children={<PlanUpgrade closeBtn={() => setPlanUpgrade(false)} />} fallback={<div>loading...</div>} />
                </div>
            }

            {/* Navbar */}
            <div className="text-white bg-white h-18">
                <div className="hidden lg:block">
                    <NavBar />
                </div>
                <div className="block lg:hidden">
                    <NavBarResponsive />
                </div>
            </div>

            <div className="flex w-full h-full min-h-screen">
                <div className="w-2/12 bg-[#f9f9f9] border-r border-gray-300 hidden lg:block">
                    <SideBar />
                </div>
                <div className="w-full mx-auto border lg:w-10/12">
                    {visible.coin &&
                        <Recharge handleCoinsRecharge={handleCoinsRecharge} />
                    }

                    {visible.paymentSummary &&
                        <PaymentSummary data={paymentStructure} handlePay={(value) => handlePay(value)} />
                    }

                    {visible.razorpay && (
                        <Suspense fallback={<div>Loading...</div>}>
                            <RazorPay amount={paymentData.total} orderId={paymentData.orderId} handleOnclick={(value) => razorpaySuccess(value)} />
                        </Suspense>
                    )}

                    {visible.paymentSuccess && (
                        <div className="flex items-center justify-center w-full">
                            <Suspense fallback={<div>Loading...</div>}>
                                <PaymentSuccessPopUp handleDoneBtn={handleDoneBtn} isFailed={razoorpayPaymentStatus} />
                            </Suspense>
                        </div>
                    )}
                </div>
            </div>

            <Suspense fallback={<div>Loading...</div>}>
                <div className="hidden w-full bg-black lg:block">
                    <Footer />
                </div>
                <div className="block bg-black lg:hidden">
                    <LandingPageResponsiveFooter />
                </div>
            </Suspense>
        </div>
    )
}

export default Index