import React, { memo } from 'react'
import men from '../../../assets/discover/men.svg'
import female from '../../../assets/discover/GenderFemale.svg'
import cm from '../../../assets/discover/cm.svg'
import kg from '../../../assets/discover/kg.svg'
import jobIcon from '../../../assets/discover/lawyer2.svg'

// Assets
import religion from 'assets/discover/icons/religion-black.svg'
// import marriage from '../../../assets/discover/marriage.svg'
// import friendship from '../../../assets/discover/friendship.svg'
// import dating from '../../../assets/discover/dating.svg'
// import notSure from '../../../assets/discover/notSure.svg'

import { capitalize } from '../../../shared/textFormats'

function Index({ data }) {

    // const getIcon = (lookingFor) => {
    //     switch (lookingFor) {
    //         case 'marriage':
    //             return marriage;
    //         case 'dating':
    //             return dating;
    //         case 'friendship':
    //             return friendship;
    //         default:
    //             return notSure;
    //     }
    // };
    // const lookingForIcon = getIcon(data?.personal?.looking_for);

    return (
        <div className='w-full md:w-[390px] h-fit md:h-[510px] md:border-2 bg-white md:border-div_border flex flex-col justify-start items-start shadow-sm px-2'>

            <div className='w-full md:w-[339px] flex flex-col justify-start items-start  gap-5 py-3 ml-2'>
                <div className='flex flex-col gap-3'>
                    <p className='font-medium text-lg md:text-[20px] text-text_gray'>My basics</p>
                    <div className='flex flex-row gap-3'>
                        {data?.personal?.marital_status &&
                            <div className='w-fit h-[46px]  flex justify-center items-center border-2 border-border_color rounded-full gap-2 p-3'>
                                <p className='font-normal text-black text-sm md:text-[16px]'>{capitalize(data?.personal?.marital_status)}</p>
                            </div>
                        }
                        {data?.personal?.sex &&
                            <div className='w-fit h-[45px] flex justify-center items-center border-2 border-border_color rounded-full gap-2 p-3'>
                                <img src={data?.personal?.sex === 'male' ? men : female} alt='coupleSquad' />
                                <p className='font-normal text-black text-sm md:text-[16px]'>{capitalize(data?.personal?.sex)}</p>
                            </div>
                        }
                        {data?.personal?.height &&
                            <div className='w-fit h-[46px]  flex justify-center items-center border-2 border-border_color rounded-full p-3'>
                                <img src={cm} alt='coupleSquad' />
                                <p className='font-normal text-black text-sm md:text-[16px]'>{data?.personal?.height}cm</p>
                            </div>
                        }

                    </div>
                    <div className='flex flex-row gap-4'>
                        {data?.personal?.weight &&
                            <div className='w-fit h-[46px] flex justify-center items-center border-2 border-border_color rounded-full gap-2 p-3'>
                                <img src={kg} alt='coupleSquad' />
                                <p className='font-normal text-black text-sm md:text-[16px]'>{data?.personal?.weight}kg</p>
                            </div>
                        }
                        {data?.personal?.religion_id?.name &&
                            <div className='w-fit h-[46px] flex justify-center items-center border-2 border-border_color rounded-full gap-2  p-3'>
                                <img src={religion} alt='coupleSquad' />
                                <p className='font-normal text-black text-sm md:text-[16px]'>{capitalize(data?.personal?.religion_id?.name)}</p>
                            </div>
                        }

                    </div>
                </div>

                {data?.personal?.profession &&
                    <div className='w-[116px] h-[89px] flex flex-col gap-3'>
                        <p className='font-medium text-lg md:text-[20px] text-text_gray'>Profession</p>
                        <div className='w-fit h-[46px]  flex justify-center items-center border-2 border-border_color rounded-full gap-2 p-3 px-6'>
                            <img src={jobIcon} alt='coupleSquad' />
                            <p className='font-normal text-black text-[16px]'>{capitalize(data?.personal?.profession)}</p>
                        </div>
                    </div>
                }
                {data?.languages_known?.others?.length > 0 &&
                    <div className='w-[174px] h-[89px] flex flex-col gap-3'>
                        <p className='font-medium text-lg md:text-[20px] text-text_gray'>Languages known</p>
                        <div className='flex gap-2'>
                            {data?.languages_known?.others.map((language) => (
                                <div className='w-fit h-[46px]  flex justify-center items-center border-2 border-border_color rounded-full p-3'>
                                    <p className='font-normal text-black text-[16px]'>{capitalize(language)}</p>
                                </div>
                            ))}

                        </div>
                    </div>
                }
                {data?.personal?.looking_for &&
                    <div className='w-fit h-[89px] flex flex-col  gap-3'>
                        <p className='font-medium text-lg md:text-[20px] text-text_gray'>Looking for</p>
                        <div className='w-fit h-[46px]  flex justify-center items-center  border-2 border-border_color rounded-full gap-2 p-3 '>
                            <p className='font-normal text-black text-[16px]'>{capitalize(data?.personal?.looking_for)}</p>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default memo(Index)