import React, { Suspense, lazy, useState } from 'react'
import image15 from '../../assets/homepage/image 15.jpg'
import { useParams } from 'react-router-dom'
import { getMatchForGuest } from '../../apis/matches'
import { toast } from 'react-toastify'


const FirstPage = lazy(() => import('../../components/cupid/firstPage'))
const SecondPage = lazy(() => import('../../components/cupid/secondPage'))
const ThirdPage = lazy(() => import('../../components/cupid/thirdPage'))
const FourthPage = lazy(() => import('../../components/cupid/fourthPage'))

function Index() {

    const { id } = useParams();


    const [currentPage, setCurrentPage] = useState(1)
    const [matchData, setMatchData] = useState()
    const [userData, setUserData] = useState({
        name: null,
        dob: null,
    })

    const PageToDisplay = currentPage === 1 ? FirstPage
        : currentPage === 2 ? SecondPage
            : currentPage === 3 ? ThirdPage
                : FourthPage

    const handleNextPage = async (value = null) => {
        try {
            if (value) {
                setUserData(({ dob: value.dob, name: value.name }))
            }
            if (currentPage === 2) {
                const res = await fetchTheMatchData(value)
                if (res?.success) {
                    setCurrentPage(currentPage + 1)
                    setMatchData(res?.data)
                } else {
                    toast.error('something went wrong')
                }
            } else {
                setCurrentPage(currentPage + 1)
            }
        } catch (error) {
            toast.error('something went wrong')
        }
    }

    const fetchTheMatchData = async (value) => {
        const res = await getMatchForGuest({ dob: value.dob, name: value.name, encryptedId: id })
        return res
    }

    return (
        <div className="h-screen flex flex-col text-white bg-black">
            {/* Header */}
            <div className="flex flex-col items-center">
                <img src={image15} alt="" className='my-4 w-[100px] h-auto' />
                <div className="h-[70px] w-full bg-[#21262e] flex items-center">
                    <div className="text-base ms-6">Welcome as a Guest User</div>
                </div>
            </div>
            {/* Content Component */}
            <Suspense fallback={<div>loading...</div>}>
                <PageToDisplay nextPage={(value) => handleNextPage(value)} data={matchData} />
            </Suspense>
        </div>

    )
}

export default Index