import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

const MobileView = lazy(() => import('components/popUps/mobileView'));

const UnAuthRoute = ({ children }) => {
    const [authStatus, setAuthStatus] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    const authenticate = useCallback(async () => {
        const token = localStorage.getItem('session');
        if (token) {
            setAuthStatus(true);
        } else {
            setAuthStatus(false);
        }
    }, []);

    useEffect(() => {
        authenticate();
        const handleResize = () => {
            // setIsMobile(window.innerWidth <= 768)
            setIsMobile(false);
        };
        // Initial check
        handleResize();
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Clean up event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [authenticate]);

    // Initial check status
    if (authStatus === null) {
        return <div>Checking authentication...</div>;
    }

    if (authStatus) {
        return <Navigate to='/home' replace />;
    }

    if (isMobile) {
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <MobileView />
            </Suspense>
        );
    }

    return children;
};

export default UnAuthRoute;
