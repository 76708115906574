import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { status } from '../../apis/auth';
import { useSelector, useDispatch } from 'react-redux';
import { setUserProfileDetails } from '../../redux/slice/userProfile';
import ErrorBoundary from 'components/Cards/ErrorCards/errorBoundry';

const MobileView = lazy(() => import('components/popUps/mobileView'));

const ProtectedRoute = ({ children }) => {
    const dispatch = useDispatch();
    const [authStatus, setAuthStatus] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const userInfo = useSelector((state) => state.userProfile);

    const authenticate = useCallback(async () => {
        const result = await status();
        if (result) {
            setAuthStatus(true);
            if (!userInfo.name) {
                const updateData = {
                    ...result,
                    personal: {
                        ...result.personal,
                        religion_id: result?.personal.religion_id?._id,
                        caste_id: result?.personal.caste_id?._id,
                    },
                };
                dispatch(setUserProfileDetails(updateData));
            }
        } else {
            setAuthStatus(false);
        }
    }, [dispatch, userInfo.name]);

    useEffect(() => {
        authenticate();

        const handleResize = () => {
            // setIsMobile(window.innerWidth <= 768);
            setIsMobile(false);
        };
        // Initial check
        handleResize();
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Clean up event listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [authenticate]);

    // Initial check status
    if (authStatus === null) {
        return <div>Checking authentication...</div>;
    }

    if (!authStatus) {
        localStorage.removeItem('session');
        return <Navigate to='/welcome' replace />;
    }

    if (isMobile) {
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <MobileView />
            </Suspense>
        );
    }

    return children;
};

const Index = ({ children }) => (
    <ErrorBoundary>
        <ProtectedRoute>
            {children}
        </ProtectedRoute>
    </ErrorBoundary>
);

export default Index;
