import React, { memo } from 'react'
import { capitalize } from 'shared/textFormats'

// Assets
import timeLine from 'assets/discover/icons/timeLine.png'
import religionBlack from 'assets/discover/icons/religion-black.svg'
import GlobeIcon from 'assets/profileEdit/groomsLocation/GlobeIcon.svg'
import MapIconIcon from 'assets/profileEdit/groomsLocation/MapIcon.svg'

function Index({ data }) {

    const content = [
        // { title: "Marital Status", text: [{ img: false, text: capitalize(data?.personal?.marital_status) }] },
        { title: "Looking for", text: [{ img: false, text: capitalize(data?.personal?.looking_for) }] },
        { title: "Timeline", text: [{ img: timeLine, text: capitalize(data?.personal?.timeline) }] },
        { title: "Religion & caste", text: [{ img: religionBlack, text: capitalize(data?.personal?.religion_id?.name) }, { img: false, text: capitalize(data?.personal?.caste_id?.name) }] },
        { title: "Place", text: [{ img: GlobeIcon, text: capitalize(data?.address?.country) }, { img: MapIconIcon, text: capitalize(data?.address?.state) }] },
    ]

    return (

        <div className='w-full h-fit md:h-[510px] md:border-2 bg-white md:border-div_border flex flex-col justify-start items-start shadow-sm  '>

            <div className='w-[339px] flex flex-col justify-start items-start  gap-5 py-3 ml-5'>
                <div className='w-[339px]'>
                    <div className='flex flex-col gap-3'>

                        <div className='flex flex-wrap gap-3'>
                            {content.map((item, index) => (
                                <>{item?.text[0]?.text &&
                                    <div key={index} className='flex flex-col w-full gap-3 h-fit'>
                                        <p className='font-medium text-[20px] text-text_gray'>{item?.title}</p>
                                        <div className='flex gap-2'>
                                            {item?.text.map((info, index) => (
                                                <>{info?.text &&
                                                    <div key={index} className='flex items-center justify-center p-3 px-4 border-2 rounded-full w-fit h-fit border-border_color'>
                                                        {info?.img &&
                                                            <img src={info?.img} alt='coupleSquad' />
                                                        }
                                                        <p className='font-normal text-black text-[16px] ms-1'>{capitalize(info?.text)}</p>
                                                    </div>
                                                }</>
                                            ))}
                                        </div>
                                    </div>
                                }</>
                            ))}
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default memo(Index)


// {data?.personal?.looking_for &&
//     <div className='w-fit h-[89px] flex flex-col  gap-3'>
//         <p className='font-medium text-lg md:text-[20px] text-text_gray'>Marital Status</p>
//         <div className='w-fit h-[46px]  flex justify-center items-center  border-2 border-border_color rounded-full gap-2 p-3 '>
//             <p className='font-normal text-black text-[16px]'>{capitalize(data?.personal?.marital_status)}</p>
//         </div>
//     </div>
// }