import React, { memo, useState } from 'react';
import { formatDate } from '../../../../shared/dates';
import { Link } from 'react-router-dom';
import LazyImage from 'components/image/lazyLoading';
import { capitalize } from 'shared/textFormats';

const Index = ({ data, saveEvent, SaveEventHandler, onClickNavigate }) => {

    const [saveEventStatus, setSaveEventStatus] = useState(data?.saved)

    return (
        <div className="event-card max-w-[300px] relative shadow-xl border ">
            <div className="absolute top-0 left-0 z-10 p-2 w-[62px] h-[62px] bg-black bg-opacity-50 text-white flex flex-col justify-center items-center">
                {formatDate(data?.start_date)}
            </div>
            <Link to={onClickNavigate}>
                <LazyImage src={data.image} alt="Event" className="event-image " />
            </Link>
            <div className="text-black event-details ms-3">
                <h2 className="event-title text-[20px] font-medium my-3 line-clamp-2 h-[57px]" title={data?.title}>
                    {data?.title}
                </h2>

                {/* Bookings */}
                <Link to={onClickNavigate} className="flex items-center h-[20px]">
                    <div className="relative flex items-center ms-3 ">
                        {data?.display_attendees && data?.attendees?.map((item, index) => (
                            <div
                                key={index}
                                className={`flex-shrink-0 w-9 h-9 -ms-3 relative`}
                                style={{ zIndex: 30 - index }}
                            >
                                <LazyImage src={item.photo} alt={`Avatar ${index + 1}`} className="w-[33px] h-[33px] rounded-full" />
                            </div>
                        ))}

                    </div>
                    {data?.display_attendees && data?.remaining_attendees_count > 0 &&
                        <span className="text-red-300 booked-count ms-2">+{data?.remaining_attendees_count} Booked</span>
                    }
                </Link>

                {/* Location */}
                <Link to={onClickNavigate} className='flex gap-1 mt-2 mb-4'>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_2999_2990)">
                            <path d="M8.26446 15.273L4.22011 11.2287C3.42023 10.4288 2.8755 9.40966 2.65482 8.30018C2.43413 7.1907 2.5474 6.0407 2.98031 4.99559C3.41321 3.95049 4.14629 3.05722 5.08686 2.42875C6.02744 1.80029 7.13325 1.46484 8.26446 1.46484C9.39568 1.46484 10.5015 1.80029 11.4421 2.42875C12.3826 3.05722 13.1157 3.95049 13.5486 4.99559C13.9815 6.0407 14.0948 7.1907 13.8741 8.30018C13.6534 9.40966 13.1087 10.4288 12.3088 11.2287L8.26446 15.273ZM11.4102 10.3301C12.0323 9.70792 12.4559 8.91527 12.6275 8.05235C12.7992 7.18943 12.711 6.29501 12.3743 5.48217C12.0376 4.66933 11.4674 3.9746 10.7359 3.4858C10.0043 2.99701 9.14428 2.73612 8.26446 2.73612C7.38465 2.73612 6.52459 2.99701 5.79304 3.4858C5.06149 3.9746 4.49131 4.66933 4.1546 5.48217C3.81789 6.29501 3.72977 7.18943 3.90138 8.05235C4.07299 8.91527 4.49662 9.70792 5.11872 10.3301L8.26446 13.4758L11.4102 10.3301ZM8.26446 8.45534C7.92737 8.45534 7.60408 8.32143 7.36572 8.08307C7.12736 7.84471 6.99345 7.52142 6.99345 7.18433C6.99345 6.84724 7.12736 6.52395 7.36572 6.28559C7.60408 6.04723 7.92737 5.91332 8.26446 5.91332C8.60156 5.91332 8.92484 6.04723 9.1632 6.28559C9.40156 6.52395 9.53547 6.84724 9.53547 7.18433C9.53547 7.52142 9.40156 7.84471 9.1632 8.08307C8.92484 8.32143 8.60156 8.45534 8.26446 8.45534Z" fill="#5D6577" />
                        </g>
                        <defs>
                            <clipPath id="clip0_2999_2990">
                                <rect width="15.2521" height="15.2521" fill="white" transform="translate(0.638672 0.191406)" />
                            </clipPath>
                        </defs>
                    </svg>

                    <div className="text-sm text-gray-500 event-location">{data?.location?.city}</div>
                </Link>
            </div>
            {saveEvent &&
                <div className="absolute top-0 right-0 z-10 mt-2 me-2">
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {
                        SaveEventHandler()
                        setSaveEventStatus(!saveEventStatus)
                    }}>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5588 29.6644L13.6692 33.907C13.1192 34.2284 12.4415 34.0009 12.1382 33.3931V33.3931C12.0505 33.2049 12.0031 32.9961 12 32.7836V13.9844C12 10.399 14.1796 8.96484 17.316 8.96484H24.684C27.7247 8.96484 30 10.3034 30 13.7453V32.7836C30 33.1228 29.8801 33.448 29.6668 33.6879C29.4535 33.9277 29.1641 34.0624 28.8624 34.0624C28.6699 34.059 28.4808 34.0058 28.3095 33.907L21.3774 29.6644C21.122 29.5092 20.8142 29.5092 20.5588 29.6644Z" stroke={saveEventStatus ? '#F83758' : 'white'} fill={saveEventStatus ? '#F83758' : ''} stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </div>
            }
        </div>
    );
};

export default memo(Index)