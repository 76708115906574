import React from 'react'
import bannerBg from "assets/siteIntro/landingPage/images/bannerBg.png"
import bannerImg from "assets/siteIntro/landingPage/images/banner.png"
import LazyImage from 'components/image/lazyLoading';
import { getTheApp } from 'utils/getTheApp';

function Index() {
    return (

        <div className="flex flex-col items-center justify-between w-full h-screen overflow-hidden text-white bg-center bg-cover "
            style={{ backgroundImage: `url(${bannerBg})` }}
        >
            <div className="flex"></div>
            {/* Content Section */}
            <div className="flex flex-col items-center justify-center w-full px-4 mt-16 text-center lg:px-0">
                <h1 className="font-inter text-[25px] lg:text-[40px] mt-3">
                    India's First Event-Based Social <br /> Community for Singles & Couples
                </h1>
                <p className="text-sm md:text-base mb-5 mt-2 max-w-[95%] md:max-w-[63%] mx-auto">
                    Join Couple Squad Community for Singles and Couples, and find a better
                    version of yourself.
                </p>

                {/* Email Section */}
                <div className="flex justify-center mt-5 mb-10">
                    <div onClick={getTheApp}
                        className="bg-black h-12 w-[180px] flex justify-center items-center cursor-pointer">
                        Get the App!
                    </div>
                </div>

                {/* Banner Image */}
                <div className="flex items-end justify-center">
                    <LazyImage
                        src={bannerImg}
                        width='full'
                        height='auto'
                        className="mx-auto"
                    />
                </div>
            </div>
        </div>




    );
}

export default React.memo(Index)