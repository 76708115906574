import LazyImage from 'components/image/lazyLoading';
import React, { memo } from 'react';

const Index = ({ bgColor, imgSrc, title, connection }) => {
    return (
        <div className={`w-[150px] h-[150px] lg:w-[250px] lg:h-[250px] rounded-[56px] flex flex-col items-center justify-center ${bgColor} p-4`}>
            <div className="flex flex-col text-center">
                <LazyImage src={imgSrc} alt="icon" className="w-6 h-6 lg:w-10 lg:h-10 mb-2" />
                <h3 className="text-white text-base lg:text-xl font-semibold">{title}</h3>
            </div>
            {connection && (
                <p className="text-white text-sm mt-2">{connection}</p>
            )}
        </div>
    );
}

export default memo(Index)