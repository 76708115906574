import React from 'react'
import noConnections from '../../../../assets/cards/noConnections.png'

function Index({ decryption }) {
    return (
        <div className='w-[336px] h-[398px] bg-white flex flex-col justify-center items-center m-4'>
            <div>
                <img src={noConnections} alt='limits' className='w-[180px] h-auto mb-2' />
            </div>
            <div className='flex flex-col justify-center items-center gap-2'>
                <p className='text-black font-medium text-[18px]'>No Suggestions</p>
                <p className='text-[14px] font-normal text-text_gray text-center w-[200px] h-[96px]'>{decryption}</p>
            </div>
        </div>
    )
}

export default React.memo(Index)