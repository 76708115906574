import React from 'react'
import location from '../../../assets/discover/mynaui_location.svg'
import { capitalize } from '../../../shared/textFormats'
import GalleryDiscover from "../GalleryDiscover"


function Index({ data, isPreview = true }) {

    const interestBgColors = ['bg-blue-700', 'bg-yellow-700', 'bg-orange-700', 'bg-green-700']
    const interestTextColors = ['text-blue-700', 'text-yellow-700', 'text-orange-700 ', 'text-green-700']

    return (
        <div className='w-fit md:w-[390px] h-fit md:h-[510px] bg-white md:border-2 md:border-div_border flex flex-col justify-start items-start shadow-sm '>
            <div className='w-fit md:w-[339px] flex flex-col justify-start items-start  cursor-pointer gap-5 py-4 p-2'>
                <>
                    {data?.misc?.interests?.length > 0 &&
                        <div className='flex flex-col gap-2'>
                            <p className='font-medium text-lg md:text-[20px] text-text_gray'>Interested in</p>
                            <div className='flex flex-wrap gap-3'>
                                {data?.misc?.interests.map((item, index) => (
                                    <div className={`w-fit h-[44px] ${interestBgColors[index]} bg-opacity-20  flex justify-center items-center  rounded-full gap-1 p-3`}>
                                        <p className={`font-normal ${interestTextColors[index]}  text-[16px]`}>#{item}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }

                </>
                {data?.address &&
                    <div className='w-fit md:w-[147px] h-[60px] flex flex-col justify-start items-start gap-2'>
                        <p className='font-medium text-lg md:text-[20px] text-text_gray'>Location</p>
                        <div className='w-[147px] h-[24px]  flex justify-start items-start gap-2'>
                            <img src={location} alt='coupleSquad' />
                            <p className='font-normal text-black text-[16px]'>
                                {data?.address?.state &&
                                    <>
                                        {capitalize(data?.address?.state)}
                                        ,
                                    </>
                                }
                                {capitalize(data?.address?.country)}</p>
                        </div>
                    </div>
                }
                {isPreview &&
                    <GalleryDiscover data={data} isPreview={isPreview} />
                }
            </div>
        </div>
    )
}

export default React.memo(Index)