import React, { Suspense, lazy, useEffect } from 'react'
import Navbar from "components/Navbars/siteIntro"
import Banner from 'components/siteIntro/hostWithUs/banner'
import { Helmet } from 'react-helmet'

const WhoWeAre = lazy(() => import("components/siteIntro/hostWithUs/whoWeAre"))
const WhyHostWithCS = lazy(() => import("components/siteIntro/hostWithUs/whyHostWithCS"))
const HowDoesItWork = lazy(() => import("components/siteIntro/hostWithUs/howDoesItWork"))
const HostWithUs = lazy(() => import("components/siteIntro/hostWithUs/hostWithUs"))
const FAQ = lazy(() => import("components/siteIntro/hostWithUs/FAQ"))
const Footer = lazy(() => import('components/Footer'))
const LandingPageResponsiveFooter = lazy(() => import('components/Footer/responsive'))

function Index() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className='bg-white'>

            <Helmet>
                <meta name='title' content="Host with Us | Couple Squad Events | Make Your Event a Hit" />
                <meta name='description' content='Host with Us at Couple Squad Events! Get expert support and reach a wide audience to make your event a success. Create memorable experiences with us.' />
                <link rel="canonical" href="https://www.couplesquad.com/host-with-us" />
            </Helmet>

            {/* Navbar */}
            <div className="fixed top-0 z-50 w-full">
                <Navbar />
            </div>

            {/* Main Banners */}
            <div className="mt-16">
                <Banner />
            </div>

            {/* Who We Are */}
            <div className="">
                <Suspense children={<WhoWeAre />} fallback={<div>loading...</div>} />
            </div>

            {/* Why Host With CS */}
            <div className="mt-10">
                <Suspense children={<WhyHostWithCS />} fallback={<div>loading...</div>} />
            </div>

            {/* How does it work */}
            <div className="mt-10">
                <Suspense children={<HowDoesItWork />} fallback={<div>loading...</div>} />
            </div>

            {/* Host With Us */}
            <div className="mt-10">
                <Suspense children={<HostWithUs />} fallback={<div>loading...</div>} />
            </div>

            {/*FAQ */}
            <div className="mt-10">
                <Suspense children={<FAQ />} fallback={<div>loading...</div>} />
            </div>

            {/* Footer */}
            <div className="bg-[#f6f6f6] flex flex-col items-center justify-center w-full pt-32 ">
                <Suspense fallback={<div>Loading...</div>}>
                    <div className="hidden w-full bg-black lg:block">
                        <Footer />
                    </div>
                    <div className="block bg-black lg:hidden">
                        <LandingPageResponsiveFooter />
                    </div>
                </Suspense>
            </div>
        </div>

    )
}

export default Index