import React, { useState, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';

import { useParams } from 'react-router-dom';

import { getFriendProfilePreview } from 'apis/matches';
import { decodeFromBase64 } from 'utils/encodeDecode';
import NavBar from 'components/Navbars/mainHeader'
import NavBarResponsive from 'components/Navbars/mainHeader/responsive'
import ResponsiveBottomNav from 'components/Navbars/responsiveBottomNav'

import ImgPreview from "../../../components/discover/imgPreview"
import GalleryDiscover from "components/discover/profilePrev/gallery"
import BasicsInfo from "components/discover/profilePrev/basicInfo"
import SectionThree from "components/discover/profilePrev/sectionThree"
import SectionFour from "components/discover/profilePrev/sectionFour"
import SectionFive from "components/discover/profilePrev/sectionFive"

// Assets
import arrow from 'assets/discover/icons/arrow.png'

function Index() {
    const { id } = useParams();
    const [userData, setUserData] = useState()
    const [pageIndex, setPageIndex] = useState(1)

    const fetchFriendsProfilePreview = async () => {
        const res = await getFriendProfilePreview(decodeFromBase64(id))
        if (res?.data) {
            setUserData(res?.data)
        }
    }

    useEffect(() => {
        fetchFriendsProfilePreview()
    }, [])


    return (
        <div className="flex flex-col h-full min-h-screen bg-white">
            {/* Navbar */}
            <div className="text-white bg-white h-18">
                <div className="hidden lg:block">
                    <NavBar />
                </div>
                <div className="block lg:hidden">
                    <NavBarResponsive />
                </div>
            </div>
            <ResponsiveBottomNav />

            <div className='flex items-center justify-center flex-grow w-full bg-white'>
                <img src={arrow} alt="coupleSquad" className='hidden cursor-pointer me-3 lg:block' onClick={() => setPageIndex(1)} />
                <div className='flex flex-wrap items-center justify-center gap-5'>
                    {pageIndex === 1 &&
                        <>
                            <div className="h-fit md:h-[510px] w-full md:w-[360px] lg:me-2">
                                <ImgPreview data={userData} profilePreview={false} page="friendsProfilePrev" />
                            </div>
                            <div className="w-full md:w-[400px] lg:me-2">
                                <BasicsInfo data={userData} />
                            </div>
                            <div className="w-full md:w-[400px] lg:me-2">
                                <SectionThree data={userData} isPreview={true} />
                            </div>
                            <div className="black lg:hidden">
                                <div className="w-full md:w-[400px] lg:me-2">
                                    <SectionFour data={userData} isPreview={true} />
                                </div>
                                <div className="w-full md:w-[400px] lg:me-2">
                                    <SectionFive data={userData} isPreview={true} />
                                </div>
                                {userData?.images?.length > 0 &&
                                    <div className="w-full md:max-w-[400px] min-h-[510px] lg:me-2">
                                        <GalleryDiscover data={userData} />
                                    </div>
                                }
                            </div>
                        </>
                    }
                    {pageIndex === 2 &&
                        <>
                            <div className="w-[400px] me-2">
                                <SectionFour data={userData} isPreview={true} />
                            </div>
                            <div className="w-[400px] me-2">
                                <SectionFive data={userData} isPreview={true} />
                            </div>
                            {userData?.images?.length > 0 &&
                                <div className="max-w-[400px] h-fit md:min-h-[510px] me-2">
                                    <GalleryDiscover data={userData} />
                                </div>
                            }
                        </>}
                </div>
                <img src={arrow} alt="coupleSquad" className='hidden rotate-180 cursor-pointer ms-3 lg:block' onClick={() => setPageIndex(2)} />
            </div>
            <div className="justify-center hidden mb-4 lg:flex">
                <div className={`h-4 w-4 rounded-full mx-1 ${pageIndex === 1 ? 'bg-gray-600' : 'bg-gray-300'}`}></div>
                <div className={`h-4 w-4 rounded-full mx-1 ${pageIndex === 2 ? 'bg-gray-600' : 'bg-gray-300'}`}></div>
            </div>
        </div>

    )
}

export default Index