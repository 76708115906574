import React, { lazy, Suspense, useEffect } from 'react';
import Banner from "components/siteIntro/landing/banner";
import Navbar from "components/Navbars/siteIntro"
import { Helmet } from 'react-helmet';

const FeatureCard = lazy(() => import("components/siteIntro/landing/feature"))
const ServicesCard = lazy(() => import("components/siteIntro/landing/services"))
const DesktopAndMobileApp = lazy(() => import("components/siteIntro/landing/desktopAndMobileApp"))
const ConnectionsDisappear = lazy(() => import("components/siteIntro/landing/connectionsDisappear"))
const VerificationImgSlider = lazy(() => import("components/animation/landingPage/verification"))
const TeamMembers = lazy(() => import("components/siteIntro/landing/teamMembers"))
const PlanCard = lazy(() => import("components/siteIntro/landing/planCard"))
const Testimonial = lazy(() => import("components/siteIntro/landing/testimonial"))
const Footer = lazy(() => import("components/Footer"))
const LandingPageResponsiveFooter = lazy(() => import("components/Footer/responsive"))


function Index() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Helmet>
                <meta name='title' content="Couple Squad: India's First Event-Based Social Community" />

                <meta name="description" content="
                   Join Couple Squad, India's first event-based social community. Find curated events, connect with like-minded people, and build meaningful relationships.
                "/>
                <link rel="canonical" href="https://www.couplesquad.com" />

            </Helmet>
            <div className='bg-white'>

                {/* Navbar */}
                <div className="fixed top-0 z-50 w-full">
                    <Navbar />
                </div>

                {/* Main Banners */}
                <div className="h-full min-h-screen mt-16">
                    <Banner />
                </div>
                {/* FeatureCard */}
                <>
                    <Suspense children={<FeatureCard />} fallback={<div>loading...</div>} />
                </>
                {/* Services */}
                <>
                    <Suspense children={<ServicesCard />} fallback={<div>loading...</div>} />
                </>
                {/* Desktop and Mobile App */}
                <>
                    <Suspense children={<DesktopAndMobileApp />} fallback={<div>loading...</div>} />
                </>
                {/* Connections Disappear */}
                <>
                    <Suspense children={<ConnectionsDisappear />} fallback={<div>loading...</div>} />
                </>
                {/* Verification  */}
                <>
                    <Suspense children={<VerificationImgSlider />} fallback={<div>loading...</div>} />
                </>
                {/* Team Members */}
                <div className="items-center hidden bg-white sm:flex">
                    <div className="w-full ">
                        <div className="flex flex-col items-center text-black">
                            <Suspense fallback={<div>loading...</div>}>
                                <TeamMembers />
                            </Suspense>
                        </div>
                    </div>
                </div>

                {/* Plan Card */}
                <>
                    <Suspense children={<PlanCard />} fallback={<div>loading...</div>} />
                </>
                {/* Testimonial */}
                <>
                    <Suspense children={<Testimonial />} fallback={<div>loading...</div>} />
                </>
                {/* Ready to work with us */}
                <div className="flex flex-col items-center justify-center w-full bg-white">
                    <Suspense fallback={<div>Loading...</div>}>
                        <div className="hidden w-full bg-black lg:block">
                            <Footer />
                        </div>
                        <div className="block bg-black lg:hidden">
                            <LandingPageResponsiveFooter />
                        </div>
                    </Suspense>
                </div>

            </div>
        </div>
    );
}

export default Index;