import React, { memo } from 'react';
import { Switch } from 'antd';

function Index({ mainTextAndColor, subTextAndColor, isChecked, onToggle }) {
    const switchStyle = {
        backgroundColor: isChecked ? '#f56565' : '#d9d9d9',
        borderColor: isChecked ? '#f56565' : '#d9d9d9'
    };

    return (
        <div className="flex items-center justify-between px-4">
            <div className='me-2'>
                <p className={`font-normal text-base text-${mainTextAndColor[1]}`}>{mainTextAndColor[0]}</p>
                {subTextAndColor[0] && (
                    <p className={` mt-1 font-normal text-sm text-${subTextAndColor[1]}`}>{subTextAndColor[0]}</p>
                )}
            </div>
            <Switch
                checked={isChecked}
                onChange={onToggle}
                style={switchStyle}
            />
        </div>
    );
}

export default memo(Index);
