import React, { Suspense, lazy, useEffect } from 'react'

import Navbar from "components/Navbars/siteIntro"
import Banner from 'components/siteIntro/eventHome/banner'
import { getFeaturedEvents, getUpcomingEvents } from 'apis/siteIntro/eventHome'
import { Helmet } from 'react-helmet'

const TagBasedEvents = lazy(() => import('components/siteIntro/eventHome/tagBasedEvents'))
const HostWithUs = lazy(() => import('components/siteIntro/eventHome/hostWithUs'))
const EventCategory = lazy(() => import('components/siteIntro/eventHome/eventCategory'))
const EventsList = lazy(() => import('components/siteIntro/eventHome/EventsList'))
const SocialMedia = lazy(() => import('components/siteIntro/eventHome/socialMedia'))
const Footer = lazy(() => import('components/Footer'))
const LandingPageResponsiveFooter = lazy(() => import('components/Footer/responsive'))

function Index() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='bg-white'>

            <Helmet>
                <meta name='title' content="Connect through Couple Squad Events | App & Web Versions" />
                <meta name='description' content="Join Couple Squad's events community! Explore 20+ interest-based categories, meet people in person or virtually, and connect through unique Couple Squad events." />
                <link rel="canonical" href="https://www.couplesquad.com/event" />
            </Helmet>

            {/* Navbar */}
            <div className="fixed top-0 z-50 w-full">
                <Navbar />
            </div>

            {/* Main Banners */}
            <div className="overflow-hidden">
                <Banner />
            </div>
            {/* Interest Based Events */}
            <div className="mt-10 bg-white">
                <Suspense children={<TagBasedEvents />} fallback={<div>loading...</div>} />
            </div>

            {/* HostWithUs */}
            <div className="bg-[#f5f5f5]">
                <Suspense children={<HostWithUs />} fallback={<div>loading...</div>} />
            </div>

            {/* EventCategory */}
            <div className="mt-10">
                <Suspense children={<EventCategory />} fallback={<div>loading...</div>} />
            </div>

            {/* UpComing Events */}
            <div className="mt-16">
                <Suspense children={<EventsList mainText={'Upcoming Events'} api={getUpcomingEvents} />} fallback={<div>loading...</div>} />
            </div>

            {/* Activities  */}
            <div className="mt-16">
                <Suspense children={<EventsList mainText={'Activities'} api={getFeaturedEvents} />} fallback={<div>loading...</div>} />
            </div>

            {/* SocialMedia  */}
            <div className="mt-16">
                <Suspense children={<SocialMedia />} fallback={<div>loading...</div>} />
            </div>

            {/* Footer */}
            <div className="bg-[#f6f6f6] flex flex-col items-center justify-center w-full pt-32 ">
                <Suspense fallback={<div>Loading...</div>}>
                    <div className="hidden w-full bg-black lg:block">
                        <Footer />
                    </div>
                    <div className="block bg-black lg:hidden">
                        <LandingPageResponsiveFooter />
                    </div>
                </Suspense>
            </div>
        </div>
    )
}

export default Index