import React, { useState, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';

import { useParams } from 'react-router-dom';

import { getConnectionRequestedProfilePreview } from 'apis/matches';
import { decodeFromBase64 } from 'utils/encodeDecode';
import NavBar from 'components/Navbars/mainHeader'
import NavBarResponsive from 'components/Navbars/mainHeader/responsive'
import ResponsiveBottomNav from 'components/Navbars/responsiveBottomNav'

import ImgPreview from "components/discover/imgPreview"
import BasicsInfo from "components/discover/basics"
import InterestAndLocation from "components/discover/interestAndLocation"

// Assets

function Index() {
    const { id } = useParams();
    const [userData, setUserData] = useState()

    const fetchFriendsProfilePreview = async () => {
        const res = await getConnectionRequestedProfilePreview(decodeFromBase64(id))
        if (res?.data) {
            setUserData(res?.data)
        }
    }

    useEffect(() => {
        fetchFriendsProfilePreview()
    }, [])


    return (
        <div className="flex flex-col h-full min-h-screen bg-white">
            {/* Navbar */}
            <div className="text-white bg-white h-18">
                <div className="hidden lg:block">
                    <NavBar />
                </div>
                <div className="block lg:hidden">
                    <NavBarResponsive />
                </div>
            </div>
            <ResponsiveBottomNav />

            <div className='flex items-center justify-center flex-grow w-full bg-white mb-14'>
                <div className='flex flex-wrap items-center justify-center gap-5'>
                    <div className="h-[510px] w-full md:w-[380px] lg:me-2">
                        <ImgPreview data={userData} profilePreview={true} />
                    </div>
                    <div className="w-full md:w-[400px] lg:me-2">
                        <BasicsInfo data={userData} />
                    </div>
                    <div className="w-full md:w-[400px] lg:me-2">
                        <InterestAndLocation data={userData} isPreview={true} />
                    </div>

                </div>
            </div>
        </div>

    )
}

export default Index