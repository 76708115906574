import { useState } from "react"
import SideBar from "../../../components/sidebars/settings"
import languages from '../../../assets/json/languages.json'

import { Select, Tag } from 'antd';
import { toast } from "react-toastify";
import { updateUserProfile } from "../../../apis/editProfile";
import { setUserProfileDetails } from '../../../redux/slice/userProfile';
import NavBar from "../../../components/Navbars/mainHeader"
import NavBarResponsive from "../../../components/Navbars/mainHeader/responsive"
import { useDispatch } from "react-redux";
import { useDataForProfileEdit } from "../../../hooks/userProfileEdit";
import ProfileEditNav from '../../../components/Navbars/profileEdit'
const { Option } = Select;

function Index() {

    const dispatch = useDispatch()
    const userInfo = useDataForProfileEdit()
    const [selectedLanguages, setSelectedLanguages] = useState(userInfo.languages_known.others || []);
    const [motherTongue, setMotherTongue] = useState(userInfo.languages_known.mother_tongue || null)

    const handleChangeLanguages = (selected) => {
        if (selected.length <= 5) {
            setSelectedLanguages(selected);
        } else {
            toast.error('You can select up to 5 languages.');
        }
    };

    const customTagRender = (props) => {
        const { label, value, closable, onClose } = props;
        let color = '';
        const index = selectedLanguages.indexOf(value);
        if (index === 0) {
            color = 'green';
        } else if (index === 1) {
            color = 'yellow';
        } else if (index === 2) {
            color = 'red';
        } else if (index === 3) {
            color = 'blue';
        } else if (index === 4) {
            color = 'pink';
        }

        return (
            <Tag
                color={color}
                closable={closable}
                onClose={onClose}
                style={{ borderRadius: '9999px', fontSize: '16px', padding: '2px', margin: '2px', paddingLeft: '4px', paddingRight: '4px' }}
            >
                {label}
            </Tag>
        );
    };

    const handleSave = async () => {
        try {
            if (!motherTongue) {
                toast.error("Mother Tongue can not be empty")
            } else {
                const toastId = toast.loading('Updating profile...');

                const updateData = {
                    ...userInfo,
                    languages_known: {
                        mother_tongue: motherTongue,
                        others: selectedLanguages,
                    },

                };

                const res = await updateUserProfile(updateData);
                dispatch(setUserProfileDetails(res?.data));
                toast.dismiss(toastId);
            }
        } catch (error) {
            toast.dismiss();
            toast.error('Something went wrong');
        }
    };


    return (
        <div>
            <div className="flex flex-col h-full min-h-screen bg-white">
                {/* Navbar */}
                <div className="text-white bg-white h-18">
                    <div className="hidden lg:block">
                        <NavBar />
                    </div>
                    <div className="block lg:hidden">
                        <NavBarResponsive />
                    </div>
                </div>

                <div className="flex flex-1 overflow-hidden bg-white">
                    <div className="bg-[#edf0f7] w-3/12 hidden lg:block">
                        <SideBar />
                    </div>

                    <div className="flex flex-col w-full lg:w-10/12">
                        <div className="w-[98%] ms-2 ">
                            <ProfileEditNav />
                        </div>
                        <div className="flex-1 overflow-auto p-4 lg:w-[28%] md:w-[50%] w-full text-black m-10 ms-0 md:ms-14">
                            {/* Mother tongue */}
                            <h1 className="mb-3 text-xl font-semibold">Mothertongue</h1>
                            <div className="block w-full p-2 border border-gray-300 rounded-sm">
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Select language"
                                    className="custom-select"
                                    value={motherTongue}
                                    onChange={(value) => setMotherTongue(value)}
                                >
                                    {languages.map((value) =>
                                        <Option key={value.name} value={value.name}>{value.name}</Option>
                                    )}

                                </Select>
                            </div>
                            {/* Languages Known */}
                            <h1 className="mt-8 mb-3 text-xl font-semibold">Languages Known</h1>
                            <div className="block w-full p-2 border border-gray-300 rounded-sm">
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Select languages"
                                    className="custom-select"
                                    value={selectedLanguages}
                                    onChange={handleChangeLanguages}
                                    tagRender={customTagRender}
                                >
                                    {languages.map((value) =>
                                        <Option key={value.name} value={value.name}>{value.name}</Option>
                                    )}
                                </Select>
                            </div>


                            <div className="flex items-start justify-start w-full pt-4 mt-16">
                                <button className="block w-full p-3 text-white border rounded-sm ps-4 bg-brandRed" onClick={handleSave}>
                                    Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
