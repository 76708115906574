import React from 'react'
import LimitReachedErrorCard from '../Cards/emptyCards/LimitReached'
import NodataAccess from '../Cards/emptyCards/NodataAccess'
import Location from '../Cards/emptyCards/LocationTurnoff'
import NoMessage from '../Cards/emptyCards/NoMessage'
import InsufficientCode from '../Cards/emptyCards/InsufficientCode'
import Network from '../Cards/emptyCards/NetworkData'
import PlanUpgrade from '../Cards/emptyCards/PlanUpgrade'
import NoMatches from '../Cards/emptyCards/noMatches'
import NoConnections from '../Cards/emptyCards/noConnections'
import EmptyImg from '../Cards/emptyCards/emptyImg'
import PlanExpired from '../Cards/emptyCards/planExpired'
import EmptyEvents from '../Cards/emptyCards/emptyEvents'

function Index() {
  return (
    <div className='flex flex-wrap justify-center items-center'>
      <LimitReachedErrorCard />
      <NodataAccess />
      <Location />
      <NoMessage />
      <InsufficientCode />
      <Network />
      <PlanUpgrade />
      <NoConnections />
      <PlanExpired />
      <EmptyEvents />

      <NoMatches />
      <EmptyImg />


    </div>
  )
}

export default React.memo(Index)