import React from 'react';

// Assets
import men from 'assets/discover/men.svg'
import female from 'assets/discover/GenderFemale.svg'
import cm from 'assets/discover/cm.svg'
import kg from 'assets/discover/kg.svg'
import jobIcon from 'assets/discover/lawyer2.svg'
import religion from 'assets/discover/icons/religion-black.svg'
import { capitalize } from 'shared/textFormats'
import location from 'assets/discover/mynaui_location.svg'
import GalleryDiscover from "../GalleryDiscover"


function Index({ data, isPreview = true }) {
    const basicInfo = [
        { label: capitalize(data?.personal?.marital_status) || null, icon: null },
        { label: capitalize(data?.personal?.sex) || null, icon: data?.personal?.sex === 'male' ? men : female },
        { label: `${data?.personal?.height}cm` || null, icon: cm },
        { label: `${data?.personal?.weight}kg` || null, icon: kg },
        { label: capitalize(data?.personal?.religion_id?.name) || null, icon: religion },
    ];

    const interestBgColors = ['bg-blue-700', 'bg-yellow-700', 'bg-orange-700', 'bg-green-700']
    const interestTextColors = ['text-blue-700', 'text-yellow-700', 'text-orange-700 ', 'text-green-700']

    return (
        <div className="flex flex-wrap w-full h-full p-4 bg-white">
            {/* Left Section */}
            <div className="w-full h-full pr-4 overflow-y-auto md:w-1/2 scrollbar-thin scrollbar-thumb-red-300 scrollbar-track-red-100">

                <p className='font-medium text-lg md:text-[20px] text-text_gray'>My basics</p>
                <div className="flex flex-wrap gap-3 mt-2">
                    {basicInfo?.map((info, index) => (
                        <>
                            {info?.label &&
                                <div key={index} className='w-fit h-[46px] flex justify-center items-center border-2 border-border_color rounded-full gap-2  p-3'>
                                    {info?.icon && <img src={info?.icon} alt='coupleSquad' />}
                                    <p className='font-normal text-black text-sm md:text-[16px]'>{info?.label}</p>
                                </div>
                            }
                        </>
                    ))}
                </div>

                {data?.personal?.profession &&
                    <div className='w-fit h-[89px] flex flex-col gap-3 mt-6'>
                        <p className='font-medium text-lg md:text-[20px] text-text_gray'>Profession</p>
                        <div className='w-fit h-[46px]  flex justify-center items-center border-2 border-border_color rounded-full gap-2 p-3 px-6'>
                            <img src={jobIcon} alt='coupleSquad' />
                            <p className='font-normal text-black text-[16px]'>{capitalize(data?.personal?.profession)}</p>
                        </div>
                    </div>
                }

                {data?.languages_known?.others.length > 0 &&
                    <div className='w-fit h-[89px] flex flex-wrap flex-col gap-3 mt-6'>
                        <p className='font-medium text-lg md:text-[20px] text-text_gray'>Languages known</p>
                        <div className='flex gap-2'>
                            {data?.languages_known?.others.map((language) => (
                                <div className='w-fit h-[46px]  flex justify-center items-center border-2 border-border_color rounded-full p-3'>
                                    <p className='font-normal text-black text-[16px]'>{capitalize(language)}</p>
                                </div>
                            ))}

                        </div>
                    </div>
                }

                {data?.personal?.looking_for &&
                    <div className='w-fit h-[89px] flex flex-col  gap-3 mt-6'>
                        <p className='font-medium text-lg md:text-[20px] text-text_gray'>Looking for</p>
                        <div className='w-fit h-[46px]  flex justify-center items-center  border-2 border-border_color rounded-full gap-2 p-3 '>
                            <p className='font-normal text-black text-[16px]'>{capitalize(data?.personal?.looking_for)}</p>
                        </div>
                    </div>
                }

            </div>

            {/* Right Section */}
            <div className="w-full pl-4 md:w-1/2">
                {data?.misc?.interests?.length > 0 &&
                    <div className='flex flex-col gap-2'>
                        <p className='font-medium text-lg md:text-[20px] text-text_gray'>Interested in</p>
                        <div className='flex flex-wrap gap-3'>
                            {data?.misc?.interests.map((item, index) => (
                                <div className={`w-fit h-[44px] ${interestBgColors[index]} bg-opacity-20  flex justify-center items-center  rounded-full gap-1 p-3`}>
                                    <p className={`font-normal ${interestTextColors[index]}  text-[16px]`}>#{item}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                }

                {data?.address &&
                    <div className='w-fit md:w-[147px] h-[60px] flex flex-col justify-start items-start gap-2 my-8'>
                        <p className='font-medium text-lg md:text-[20px] text-text_gray'>Location</p>
                        <div className='w-[147px] h-[24px]  flex justify-start items-start gap-2'>
                            <img src={location} alt='coupleSquad' />
                            <p className='font-normal text-black text-[16px]'>
                                {data?.address?.state &&
                                    <>
                                        {capitalize(data?.address?.state)}
                                        ,
                                    </>
                                }
                                {capitalize(data?.address?.country)}</p>
                        </div>
                    </div>
                }

                {isPreview &&
                    <div className='h-fit'>
                        <GalleryDiscover data={data} isPreview={isPreview} />
                    </div>
                }
            </div>
        </div>
    );
};

export default Index;
