import { Suspense, lazy, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import NavBar from 'components/Navbars/mainHeader'
import NavBarResponsive from 'components/Navbars/mainHeader/responsive'
import ResponsiveBottomNav from 'components/Navbars/responsiveBottomNav'
import FriendsCard from '../../../components/Cards/Connection/userConnectionCard'
import Tab from '../../../components/Navbars/ConnectionTabNav'
import { getAllFriendsList } from '../../../apis/connections'
import { CONNECTIONS } from 'shared/router'
import { encodeToBase64 } from 'utils/encodeDecode'
import avatar from "../../../assets/profileEdit/basic/avaatar.png"

const NoConnections = lazy(() => import("../../../components/Cards/emptyCards/noConnections"))
const Footer = lazy(() => import('components/Footer'))
const FooterResponsive = lazy(() => import('components/Footer/responsive'))

function Index() {
    const navigate = useNavigate()
    const [friendsLIstData, setFriendsLIstData] = useState([])
    const [nextPage, setNextPage] = useState(1)

    const handleGetAllFriendsList = async () => {
        if (nextPage) {
            const res = await getAllFriendsList(nextPage, 8)
            setNextPage(res?.pagination?.next_page)
            setFriendsLIstData(prev => ([...prev, ...(res?.data || [])]))
        }
    }

    useEffect(() => {
        handleGetAllFriendsList()
    }, [])

    const handleFriendsPreview = (id) => {
        navigate(CONNECTIONS.FRIEND_PREVIEW.replace(':id', encodeToBase64(id)));
    }

    return (
        <div className="flex flex-col h-full bg-[#f9f9f9]">
            {/* Navbar */}
            <div className="text-white bg-white h-18">
                <div className="hidden lg:block">
                    <NavBar />
                </div>
                <div className="block lg:hidden">
                    <NavBarResponsive />
                </div>
            </div>
            <ResponsiveBottomNav />

            {/* Tabs */}
            <div className="flex flex-col items-center w-full bg-[#F9F9F9] mt-5">
                <div className='w-10/12 h-full mt-3'>
                    <Tab count={friendsLIstData?.length} />
                </div>
            </div>

            <div className="flex flex-col items-center justify-center  w-full bg-[#f9f9f9] mt-5 mb-20">
                <div className='md:w-10/12 w-[70%] mt-3'>
                    {friendsLIstData?.length > 0 ? (
                        <div className='flex flex-wrap justify-start gap-6'>
                            {friendsLIstData?.map((item, index) => (
                                <div className='w-[300px] min-h-[400px]'>
                                    <FriendsCard
                                        imgUrl={item?.photo ? item.photo : avatar}
                                        name={item?.name}
                                        profession={item?.profession || null}
                                        meetHandler={""}
                                        chatHandler={""}
                                        viewUserProfile={() => handleFriendsPreview(item?.matchId)}
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='flex justify-center'>
                            <Suspense fallback={<div>Loading...</div>}>
                                <NoConnections decryption={"Your Mutual connections will be displayed here. Unfortunately, you don't have any mutual connections at the moment."} />
                            </Suspense>
                        </div>
                    )}
                </div>
                {nextPage && (
                    <p className='text-lg text-blue-500 cursor-pointer' onClick={handleGetAllFriendsList}>see more...</p>
                )}
            </div>

            <Suspense fallback={<div>Loading...</div>}>
                <div className="hidden w-full bg-black lg:block">
                    <Footer />
                </div>
                <div className="block mb-10 bg-black lg:hidden">
                    <FooterResponsive />
                </div>
            </Suspense>
        </div>
    )
}

export default Index