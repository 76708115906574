import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import { MAIN_NAV } from '../../../../shared/router';
import LazyImage from 'components/image/lazyLoading';


const Index = ({ imgUrl, name, profession, viewUserProfile }) => {


    return (
        <div className="max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md">
            <LazyImage className="w-[300px] h-56 object-cover cursor-pointer" src={imgUrl} alt="Profile" onClick={viewUserProfile} />
            <div className="flex flex-col items-center justify-center p-4">
                <h2 className="mt-3 text-2xl font-medium text-center text-black event-title">{name}</h2>
                {profession ?
                    <h2 className="text-lg text-center text-subText">{profession}</h2>
                    :
                    <div className="h-[26px]"></div>
                }
                <Link to={MAIN_NAV.CHAT} className="w-[140px] mt-4 bg-brandRed text-white py-2  rounded-md flex items-center justify-center cursor-pointer">
                    <svg width="36" height="34" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3260_3510)">
                            <path d="M3.89561 30.5188C3.73361 30.6551 3.53609 30.7423 3.32624 30.7702C3.11639 30.7981 2.90293 30.7656 2.71095 30.6764C2.51896 30.5872 2.35642 30.445 2.24242 30.2666C2.12842 30.0883 2.0677 29.8811 2.06738 29.6694V7.42473C2.06738 7.12975 2.18456 6.84685 2.39315 6.63827C2.60173 6.42968 2.88463 6.3125 3.17961 6.3125H27.6487C27.9437 6.3125 28.2266 6.42968 28.4352 6.63827C28.6437 6.84685 28.7609 7.12975 28.7609 7.42473V25.2204C28.7609 25.5154 28.6437 25.7983 28.4352 26.0069C28.2266 26.2155 27.9437 26.3327 27.6487 26.3327H9.08834C8.82634 26.3327 8.57277 26.4253 8.37234 26.594L3.89561 30.5188Z" stroke="white" stroke-width="1.77957" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.9648 14.0977H19.8627" stroke="white" stroke-width="1.77957" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.9648 18.5469H19.8627" stroke="white" stroke-width="1.77957" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_3260_3510">
                                <rect width="34" height="34" fill="white" transform="translate(0.288086 0.777344)" />
                            </clipPath>
                        </defs>
                    </svg>
                    Chat
                </Link>
            </div>
        </div>
    );
};

export default memo(Index)