import { useState, useCallback } from "react";
import SideBar from "../../../components/sidebars/settings"
import SelectableDropdown from "../../../components/profileEdit/DropDown"
import healthIconBody from '../../../assets/profileEdit/physical/healthIconBody.svg'
import weightIcon from '../../../assets/profileEdit/physical/weightIcon.jpg'
import { updateUserProfile } from "../../../apis/editProfile";
import { setUserProfileDetails } from '../../../redux/slice/userProfile';
import { toast } from "react-toastify";
import { physicalAttributesValidation } from "../../../utils/validations/editProfile";
import * as Yup from 'yup'
import { useDispatch } from "react-redux";
import { Select } from "antd";
import NavBar from "../../../components/Navbars/mainHeader"
import NavBarResponsive from "../../../components/Navbars/mainHeader/responsive"
import { useDataForProfileEdit } from "../../../hooks/userProfileEdit";
import { physicalStatusOptions } from "../../../shared/constants";
import ProfileEditNav from '../../../components/Navbars/profileEdit'
const { Option } = Select;

function Index() {

    const dispatch = useDispatch()
    const userInfo = useDataForProfileEdit()

    const height = Array.from({ length: 250 - 140 + 1 }, (_, index) => 140 + index);
    const weight = Array.from({ length: 150 - 40 + 1 }, (_, index) => 40 + index);
    const [userData, setUserData] = useState({
        height: userInfo?.personal?.height,
        weight: userInfo?.personal?.weight,
        physical_status: userInfo?.personal?.physical_status,
    })

    // Disabled
    const physicalStatusIcons = { sixMonths: healthIconBody }
    // Handle Change
    const handleChange = useCallback(async (inputKey, value) => {
        setUserData(prev => ({
            ...prev,
            [inputKey]: value
        }));
    }, []);

    const handleSave = async () => {
        try {
            const toastId = toast.loading("updating profile")
            await physicalAttributesValidation.validate(userData, { abortEarly: false });


            const updateData = {
                ...userInfo,
                personal: {
                    ...userInfo.personal,
                    height: userData.height,
                    weight: userData.weight,
                    physical_status: userData.physical_status,
                },

            }
            const res = await updateUserProfile(updateData)
            dispatch(setUserProfileDetails(res?.data))
            toast.dismiss(toastId)
        } catch (error) {
            toast.dismiss()
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }

    return (
        <div>
            <div className="flex flex-col h-full min-h-screen bg-white">
                {/* Navbar */}
                <div className="text-white bg-white h-18">
                    <div className="hidden lg:block">
                        <NavBar />
                    </div>
                    <div className="block lg:hidden">
                        <NavBarResponsive />
                    </div>
                </div>

                <div className="flex flex-1 overflow-hidden bg-white">
                    <div className="bg-[#edf0f7] w-3/12 hidden lg:block">
                        <SideBar />
                    </div>

                    <div className="flex flex-col w-full lg:w-10/12">
                        <div className="w-[98%] ms-2 ">
                            <ProfileEditNav />
                        </div>
                        <div className="flex-1 overflow-auto p-4 lg:w-[28%] md:w-[50%] w-full text-black m-10 ms-0 md:ms-14">
                            {/* Height */}
                            <div className="flex mt-8 mb-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.9794 20L8.69141 16.712L9.38041 16.023L11.4804 18.079V5.902L9.39941 7.977L8.71141 7.288L11.9994 4L15.2874 7.288L14.5804 8.002L12.4804 5.922V18.079L14.5804 16.023L15.2684 16.712L11.9794 20Z" fill="#070707" />
                                </svg>
                                Height
                            </div>
                            <div className="block w-full p-2 border border-gray-300 rounded-sm">
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Choose..."
                                    className="custom-select"
                                    onChange={(value) => setUserData(prev => ({ ...prev, height: value }))}
                                    value={userData.height}
                                >
                                    <option>Choose...</option>
                                    {height.map((number) => (
                                        <Option key={number} value={number}>
                                            {number} CM
                                        </Option>
                                    ))}
                                </Select>
                            </div>

                            {/* Weight */}
                            <div className="flex mt-8 mb-2">
                                <img src={weightIcon} alt="" />
                                Weight
                            </div>
                            <div className="block w-full p-2 border border-gray-300 rounded-sm">
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Choose..."
                                    className="custom-select"
                                    onChange={(value) => setUserData(prev => ({ ...prev, weight: value }))}
                                    value={userData.weight}
                                >
                                    <option >Choose...</option>
                                    {weight.map((number) => (
                                        <Option key={number} value={number}>
                                            {number} kg
                                        </Option>
                                    ))}
                                </Select>
                                <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 9L12 15L6 9" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>

                            {/* Disability */}
                            <div className="mt-8">
                                <SelectableDropdown
                                    title="Physical Status"
                                    icons={physicalStatusIcons}
                                    values={physicalStatusOptions}
                                    handleChangeFunction={handleChange}
                                    inputKey='physical_status'
                                    selectedValue={userData.physical_status}
                                />
                            </div>

                            <div className="flex items-start justify-start w-full pt-4 mt-10">
                                <button className="block w-full p-3 text-white border rounded-sm ps-4 bg-brandRed" onClick={handleSave}>
                                    Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index
