import { useState, lazy, Suspense } from 'react'

import NavBar from 'components/Navbars/mainHeader'
import NavBarResponsive from 'components/Navbars/mainHeader/responsive'
import ResponsiveBottomNav from 'components/Navbars/responsiveBottomNav'
import SuggestionCard from 'components/Cards/Connection/suggestionCard'
import Tab from '../../../components/Navbars/ConnectionTabNav'
import { useSelector } from 'react-redux'
import { getHandpickedMatches, getInterestBasedMatches, getLookingForBasedMatches } from '../../../apis/suggested'
import { toast } from 'react-toastify'

// assets
import handpicked from "assets/connection/icons/handPicked.svg"
import interests from "assets/connection/icons/intrests.svg"
import events from "assets/connection/icons/events.svg"
import meetUps from "assets/connection/icons/meetUps.svg"
import dating from "assets/connection/icons/dating.svg"
import marriage from "assets/connection/icons/marriage.svg"
import friendship from "assets/connection/icons/friendship.svg"

const Swipper = lazy(() => import("../../../components/discover/suggestionBased"))
const NodataAccess = lazy(() => import("../../../components/Cards/emptyCards/NodataAccess"))
const Footer = lazy(() => import('components/Footer'))
const FooterResponsive = lazy(() => import('components/Footer/responsive'))
function Index() {

    const userInfo = useSelector((state) => state.userProfile);
    const relationShipStatus = userInfo?.personal?.relationship_status

    const [showMatches, setShowMatches] = useState(false)
    const [currentOption, setCurrentOption] = useState()
    const [matchesData, setMatchesData] = useState()
    const [showErrorCard, setShowErrorCard] = useState(false)


    const data = [
        { img: handpicked, title: "handPicked", suggestion: 'Hand Picked', bgColor: 'bg-[#6161f8]' },
        { img: relationShipStatus === "single" ? marriage : meetUps, title: `${relationShipStatus === "single" ? "marriage" : "meetup"}`, suggestion: `${relationShipStatus === "single" ? "Marriage" : "Meetup"}`, bgColor: 'bg-[#ff525c]' },
        { img: relationShipStatus === "single" ? dating : events, title: `${relationShipStatus === "single" ? "dating" : "events"}`, suggestion: `${relationShipStatus === "single" ? "Dating" : "Events"}`, bgColor: 'bg-[#ff007a]' },
        { img: friendship, title: "friendship", suggestion: "Friendship", bgColor: 'bg-[#017bb6]' },
        { img: interests, title: "Interests", suggestion: 'Interests', bgColor: 'bg-[#f8b95b]' },
    ]

    const fetchInterestBasedMatches = async () => {
        const res = await getInterestBasedMatches()
        return res
    }

    const fetchLookingForBasedMatches = async (title) => {
        const res = await getLookingForBasedMatches(title)
        return res
    }


    const handleClickCard = async (option) => {
        let res
        if (option === "handPicked") {
            res = await getHandpickedMatches()
        } else if (option === "Interests") {
            res = await fetchInterestBasedMatches()
        } else {
            res = await fetchLookingForBasedMatches(option);
        }
        if (res?.success) {
            setCurrentOption(option)
            setMatchesData(res?.data)
            setShowMatches(true)
        } else {
            if (option !== "handPicked") {
                setShowErrorCard(true)
            }
        }
    }

    return (
        <div className="flex flex-col h-full bg-[#f9f9f9]">
            {showErrorCard &&
                <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
                    <Suspense fallback={<div>loading...</div>} children={<NodataAccess closeBtn={() => setShowErrorCard(false)} />} />
                </div>
            }

            {/* Navbar */}
            <div className="text-white bg-white h-18">
                <div className="hidden lg:block">
                    <NavBar />
                </div>
                <div className="block lg:hidden">
                    <NavBarResponsive />
                </div>
            </div>
            <ResponsiveBottomNav />

            {showMatches ?
                <Suspense fallback={<div>loading...</div>}>
                    <Swipper matches={matchesData} handleCloseBtn={() => setShowMatches(false)} currentOption={currentOption} />
                </Suspense>
                :
                <div className='flex flex-col items-center justify-center'>
                    {/* Tabs */}
                    <div className="flex flex-col items-center w-full bg-[#F9F9F9] mt-5">
                        <div className='w-10/12 h-full mt-3'>
                            <Tab />
                        </div>
                    </div>

                    <div className="flex flex-col items-center w-full min-h-screen bg-[#f9f9f9] mt-5 mb-20">
                        <div className='w-full mx-auto mt-3'>
                            <div className="flex justify-center">
                                <div className='flex flex-wrap items-center justify-center gap-6'>
                                    {data?.map((item, index) => (
                                        <div onClick={() => handleClickCard(item?.title)} className='cursor-pointer'>
                                            <SuggestionCard
                                                bgColor={item?.bgColor}
                                                imgSrc={item?.img}
                                                title={item?.suggestion}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Suspense fallback={<div>Loading...</div>}>
                <div className="hidden w-full bg-black lg:block">
                    <Footer />
                </div>
                <div className="block mb-10 bg-black lg:hidden">
                    <FooterResponsive />
                </div>
            </Suspense>
        </div>

    )
}

export default Index