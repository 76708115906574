// import React, { memo } from 'react';
// import { Select } from 'antd';
// import 'tailwindcss/tailwind.css';
// import './style.css';
// import { capitalize } from 'shared/textFormats';

// const { Option } = Select;

// function CustomSelect({ label, selectedValue, placeholderValue, options, handleOnChange, mode }) {
//     return (
//         <div className="w-full h-auto my-5 cursor-pointer">
//             <p className="mb-2 text-lg font-normal text-black capitalize">{label}</p>
//             <div className="w-full min-h-[60px] border-2 border-gray-300 rounded flex justify-between items-center p-4 cursor-pointer">
//                 <Select
//                     value={selectedValue}
//                     onChange={handleOnChange}
//                     placeholder={placeholderValue}
//                     mode={mode}
//                     showSearch={true}
//                     className="w-full custom-select"
//                     dropdownClassName="custom-dropdown"
//                 >
//                     {options.map(option => (
//                         <Option key={option.label} value={option.label}>
//                             {capitalize(option.name)}
//                         </Option>
//                     ))}
//                 </Select>
//             </div>
//         </div>
//     );
// }

// export default memo(CustomSelect);


import React, { memo } from 'react';
import { Select } from 'antd';
import 'tailwindcss/tailwind.css';
import './style.css';
import { capitalize } from 'shared/textFormats';

const { Option } = Select;

function CustomSelect({ label, selectedValue, placeholderValue, options, handleOnChange, mode }) {
    const isSelectedValueEmpty =
        selectedValue === null ||
        selectedValue === undefined ||
        (Array.isArray(selectedValue) && selectedValue.length === 0);

    return (
        <div className="w-full h-auto my-5 cursor-pointer">
            <p className="mb-2 text-lg font-normal text-black capitalize">{label}</p>
            <div className="w-full min-h-[60px] border-2 border-gray-300 rounded flex justify-between items-center p-4 cursor-pointer">
                <Select
                    value={isSelectedValueEmpty ? undefined : selectedValue}
                    onChange={handleOnChange}
                    placeholder={placeholderValue}
                    mode={mode}
                    showSearch={true}
                    className="w-full custom-select"
                    dropdownClassName="custom-dropdown"
                >
                    {options.map(option => (
                        <Option key={option.label} value={option.label}>
                            {capitalize(option.name)}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    );
}

export default memo(CustomSelect);
