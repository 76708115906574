// import React from 'react';

import { informationWeCollect, mainPoints } from "shared/constant/license/PrivacyPolicy"

// const PrivacyPolicy = () => {
//     return (
//         <>
//             <div className="flex flex-col items-start justify-center p-10 text-black bg-white">
//                 <h1 className='w-full text-3xl font-bold text-center'>Privacy Policy</h1>
//                 <p>
//                     Couple Squad PVT LTD recognises the importance of
//                     maintaining your privacy. We value your privacy and appreciate
//                     your trust in us. This Privacy Policy sets out how Couple Squad
//                     PVT LTD uses and protects any information that you give
//                     Couple Squad PVT LTD when you use this
//                     www.couplesquad.com or the Couple Squad PVT LTD mobile
//                     application or any other digital medium and other offline sources
//                     of our Company. Couple Squad PVT LTD is committed to
//                     ensure that your privacy is protected. Should we ask you to
//                     provide certain information by which you can be identified when
//                     using this website, then you can be assured that it will only be
//                     used in accordance with this Privacy Policy as it describes how
//                     we treat user information we collect from you, the policies and
//                     procedures on the collection, use, disclosure and protection of
//                     your information when you use our Couple Squad PVT LTD
//                     Platform.
//                 </p>
//                 <p>
//                     This Privacy Policy applies to current and former visitors to
//                     our Couple Squad PVT LTD Platform and to our online
//                     customers. By visiting and/or using our website, you agree to this
//                     Privacy Policy. Couple Squad PVT LTD may change this policy
//                     from time to time by updating this page. You should check this
//                     page from time to time to ensure that you are happy with any
//                     changes.
//                 </p>
//                 <p>
//                     www.couplesquad.com is a property of Couple Squad PVT
//                     LTD, Private Limited, established under laws of India, having its
//                     registered office at Private Limited, Kerala - 686601.
//                 </p>
//                 <p>
//                     DEFINITIONS:<br />
//                     The terms "Couple Squad", "Company", "we", "us" and "our"
//                     refer to the Couple Squad PVT LTD.
//                     The term "Couple Squad PVT LTD Platform" refers to
//                     www.couplesquad.com or the Couple Squad PVT LTD mobile
//                     application or any other digital medium and other offline sources.
//                     The terms "you" and "your" refer to the user of the
//                     www.couplesquad.com.
//                     The term "Group" means, with respect to any person, any
//                     entity that is controlled by such person, or any entity that controls
//                     such person, or any entity that is under common control with
//                     such person, whether directly or indirectly, or, in the case of a
//                     natural person, any Relative (as such term is defined in the
//                     Companies Act, 1956 and Companies Act, 2013 to the extent
//                     applicable) of such person.
//                 </p>
//                 <p>
//                     The term "Services" refers to any services offered by Couple
//                     Squad PVT LTD whether on the Swiggy Platform or otherwise.
//                     The term "Policy" refers to this Privacy Policy.
//                     Please read this Policy before using the Couple Squad PVT
//                     LTD Platform or submitting any personal information to Couple
//                     Squad PVT LTD. This Policy is a part of and incorporated within,
//                     and is to be read along with, the Terms of Use
//                     www.couplesquad.com.
//                 </p>
//                 <p>
//                     INFORMATION WE COLLECT FROM YOU:
//                     <br />
//                     Contact information:
//                     We might collect your Name, Email, Phone, Address, IP
//                     Address.
//                     Payment and billing information:<br />
//                     We might collect your billing name, billing address and payment
//                     method when you buy a product/service. We NEVER collect your
//                     credit card number or credit card expiry date or other details
//                     pertaining to your credit card on our website. Credit card
//                     information will be obtained and processed by our online
//                     payment partner Razorpay.<br />
//                     Information you post:<br />
//                     We collect information you post in a public space on our Couple
//                     Squad PVT LTD Platform or on a third-party social media site
//                     belonging to Couple Squad PVT LTD.
//                     Usage and Preference Information:<br />
//                     We collect information as to how you interact with our Services,
//                     preferences expressed and settings chosen. Couple Squad PVT
//                     LTD Platform includes the Couple Squad PVT LTD advertising
//                     services ("Ad Services"), which may collect user activity and
//                     browsing history within the Couple Squad PVT LTD Platform
//                     and across third-party sites and online services, including those
//                     sites and services that include our ad pixels ("Pixels"), widgets,
//                     plug-ins, buttons, or related services or through the use of
//                     cookies. Our Ad Services collect browsing information including,
//                     without limitation, your Internet protocol (IP) address and
//                     location, your login information, browser type and version, date
//                     and time stamp, user agent, Couple Squad PVT LTD cookie ID
//                     (if applicable), time zone setting, browser plug-in types and
//                     versions, operating system and platform, and other information
//                     about user activities on the Couple Squad PVT LTD Platform, as
//                     well as on third party sites and services that have embedded our
//                     Pixels, widgets, plug-ins, buttons, or related services.
//                 </p>
//                 <p>
//                     Transaction Information:,<br />
//                     We collect transaction details related to your use of our Services,
//                     and information about your activity on the Services, including the
//                     full Uniform Resource Locators (URL), the type of Services you
//                     requested or provided, comments, domain names, search results
//                     selected, number of clicks, information and pages viewed and
//                     searched for, the order of those pages, length of your visit to our
//                     Services, the date and time you used the Services, amount
//                     charged, details regarding application of promotional code,
//                     methods used to browse away from the page and any phone
//                     number used to call our customer service number and other
//                     related transaction details.<br />
//                     Stored information and files:<br />
//                     Couple Squad PVT LTD mobile application (Couple Squad
//                     PVT LTD app) may also access metadata and other information
//                     associated with other files stored on your mobile device. This
//                     may include, for example, photographs, audio and video clips,
//                     personal contacts and address book information. If you permit
//                     the Couple Squad PVT LTD app to access the address book on
//                     your device, we may collect names and contact information from
//                     your address book to facilitate social interactions through our
//                     services and for other purposes described in this Policy or at the
//                     time of consent or collection. If you permit the Couple Squad
//                     PVT LTD app to access the calendar on your device, we collect
//                     calendar information such as event title and description, your
//                     response (Yes, No, Maybe), date and time, location and number
//                     of attendees. If you are a partner company, we will, additionally,
//                     record your calls with us made from the device used to provide
//                     Services, related call details, SMS details location and address
//                     details.<br />
//                     Demographic information:<br />
//                     We may collect demographic information about you, events you
//                     like, events you intend to participate in, tickets you buy, or any
//                     other information provided by you during the use of Couple
//                     Squad PVT LTD Platform. We might collect this as a part of a
//                     survey also.<br />
//                     Other information:<br />
//                     If you use Couple Squad PVT LTD Platform, we may collect
//                     information about your IP address and the browser you're using.
//                     We might look at what site you came from, duration of time spent
//                     on our website, pages accessed or what site you visit when you
//                     leave us. We might also collect the type of mobile device you are
//                     using, or the version of the operating system your computer or
//                     device is running.<br />
//                     WAYS OF COLLECTING INFORMATION:
//                     We collect information directly from you:
//                     We collect information directly from you when you register on
//                     Couple Squad PVT LTD Platform. We also collect information if
//                     you post a comment on Couple Squad PVT LTD Platform or ask
//                     us a question through phone or email or any other source of
//                     communication.
//                     <br />
//                     We collect information from you passively:
//                     We use tracking tools like Google Analytics, Google Cookies
//                     for collecting information about your usage of our website.
//                     <br />
//                     We get information about you from third parties:<br />
//                     We may receive information about you from third parties, such as
//                     other users, partners (including ad partners, analytics providers,
//                     search information providers), or our affiliated companies or if
//                     you use any of the other websites/apps we operate or the other
//                     Services we provide. Users of our Ad Services and other thirdparties may share information with us such as the cookie ID,
//                     device ID, or demographic or interest data, and information about
//                     content viewed or actions taken on a third-party website, online
//                     services or apps. For example, users of our Ad Services may
//                     also be able to share customer list information (e.g., email or
//                     phone number) with us to create customized audience segments
//                     for their ad campaigns.
//                     <br />
//                     When you sign in to Couple Squad PVT LTD Platform with your
//                     SNS account, or otherwise connect to your SNS account with the
//                     Services, you consent to our collection, storage, and use, in
//                     accordance with this Policy, of the information that you make
//                     available to us through the social media interface. This could
//                     include, without limitation, any information that you have made
//                     public through your social media account, information that the
//                     social media service shares with us, or information that is
//                     disclosed during the sign-in process. Please see your social
//                     media provider’s privacy policy and help center for more
//                     information about how they share information when you choose
//                     to connect your account.
//                     <br />
//                     If you are partner company, we may, additionally, receive
//                     feedback and rating from other users.
//                     <br />
//                     USE OF YOUR PERSONAL INFORMATION:<br />
//                     We use information to contact you:<br />
//                     We might use the information you provide to contact you for
//                     confirmation of a purchase on Couple Squad PVT LTD Platform
//                     or for other promotional purposes.
//                     <br />
//                     We use information to abide by the contracts:
//                     We might use the information you provide to carry out our
//                     obligations arising from any contracts entered into between you
//                     and us and to provide you with the relevant information and
//                     services.
//                     <br />
//                     We use information to respond to your requests or
//                     questions:<br />
//                     We might use your information to confirm your registration for
//                     rendering services we offer.
//                     <br />
//                     We use information to improve our products and services:
//                     <br />
//                     We might use your information to customize your experience with
//                     us. This could include displaying content based upon your
//                     preferences. We might also use your information to understand
//                     our users (what they do on our Services, what features they like,
//                     how they use them, etc.), improve the content and features of our
//                     Services (such as by personalizing content to your interests),
//                     process and complete your transactions, make special offers,
//                     provide customer support, process and respond to your queries
//                     and to generate and review reports and data about, and to
//                     conduct research on, our user base and Service usage patterns.
//                     If you are a partner company, we may use the information to
//                     track the progress of your service to our customers.
//                     <br />
//                 </p>
//                 <p>
//                     We use information to look at site trends and customer
//                     interests:<br />
//                     We may use your information to make our website and products
//                     better. We may combine information we get from you with
//                     information about you we get from third parties. We may use
//                     your information to provide you with information about services
//                     we consider similar to those that you are already using, or have
//                     enquired about, or may interest you. If you are a registered user,
//                     we will contact you by electronic means (e-mail or SMS or
//                     telephone) with information about these services.
//                 </p>
//                 <p>
//                     We use information for security purposes:<br />
//                     We may use information to protect our company, our customers,
//                     or our Couple Squad PVT LTD Platform.
//                     We use information for marketing purposes:
//                     We might send you information about special promotions or
//                     offers. We might also tell you about new features or products or
//                     enroll you in our newsletter. These might be our own offers or
//                     products, or third-party offers or products we think you might find
//                     interesting. We may use the information to measure or
//                     understand the effectiveness of advertising we serve to you and
//                     others, and to deliver relevant advertising to you.
//                 </p>
//                 <p>
//                     We use information to send you transactional
//                     communications:<br />
//                     We might send you emails or SMS about your account or
//                     purchase of our product.
//                     <br />
//                     We use information as otherwise permitted by law:<br />
//                     We may combine the information that we receive from third
//                     parties with the information you give to us and information we
//                     collect about you for the purposes set out above. Further, we
//                     may anonymize and/or de-identify information collected from you
//                     through the Services or via other means, including via the use of
//                     third-party web analytic tools. As a result, our use and disclosure
//                     of aggregated and/or de-identified information is not restricted by
//                     this Policy, and it may be used and disclosed to others without
//                     limitation.<br />
//                     We analyse the log files of our Couple Squad PVT LTD Platform
//                     that may contain Internet Protocol (IP) addresses, browser type
//                     and language, Internet service provider (ISP), referring, app
//                     crashes, page viewed and exit websites and applications,
//                     operating system, date/time stamp, and clickstream data. This
//                     helps us to administer the website, to learn about user behavior
//                     on the site, to improve our product and services, and to gather
//                     demographic information about our user base as a whole.
//                     <br />
//                 </p>
//                 <p>
//                     SHARING/DISCLOSURE/DISTRIBUTION OF YOUR
//                     INFORMATION WITH THIRD-PARTIES:<br />
//                     We will share information with third parties who perform
//                     services on our behalf:<br />
//                     We may share your information with our vendors, consultants,
//                     marketing partners, research firms and other service providers or
//                     business partners, such as payment processing companies, to
//                     support our business. For example, your information may be
//                     shared with outside vendors to send you emails and messages
//                     or push notifications to your devices in relation to our Services, to
//                     help us analyze and improve the use of our Services, to process
//                     and collect payments.
//                 </p>
//                 <p>
//                     We may also use vendors for other projects, such as conducting
//                     surveys or organizing sweepstakes for us. Some vendors may be
//                     located outside of India.
//                     We will share information with Advertisers and advertising
//                     networks:<br />
//                     We may work with third parties such as network advertisers to
//                     serve advertisements on the Couple Squad PVT LTD Platform
//                     and on third-party websites or other media (e.g., social
//                     networking platforms). These third parties may use cookies,
//                     JavaScript, web beacons (including clear GIFs), Flash LSOs and
//                     other tracking technologies to measure the effectiveness of their
//                     ads and to personalize advertising content to you.<br />
//                     While you cannot opt out of advertising on the Couple Squad
//                     PVT LTD Platform, you may opt out of much interest-based
//                     advertising on third party sites and through third party ad
//                     networks (including DoubleClick Ad Exchange, Facebook
//                     Audience Network and Google AdSense). Opting out means that
//                     you will no longer receive personalized ads by third parties ad
//                     networks from which you have opted out, which is based on your
//                     browsing information across multiple sites and online services. If
//                     you delete cookies or change devices, your ‘opt out’ may no
//                     longer be effective to fulfill the purpose for which you provide it.
//                     We will share information with our business partners:
//                     This includes a third party who provide us various services. Our
//                     partners use the information we give them as described in their
//                     privacy policies.<br />
//                     While you place a request to order through the Couple Squad
//                     PVT LTD Platform, your information is provided to us and to the
//                     vendors with whom you may choose to order. In order to facilitate
//                     your online order processing, we provide your information to that
//                     vendors in a similar manner as if you had made a
//                     goods/products/food order directly with the vendors. If you
//                     provide a mobile phone number, Couple Squad PVT LTD may
//                     send you text messages regarding the orders delivery status.
//                     We will share information with Other Users:
//                     If you are a delivery partner, we may share your name, phone
//                     number and/or profile picture (if applicable), tracking details with
//                     other users to provide them the Services.
//                 </p>
//                 <p>
//                     We may share information if we think we have to in order to
//                     comply with the law or to protect ourselves:<br />
//                     We may also share it if a government agency or investigatory
//                     body or other companies assisting us, requests when we are:
//                     Obligated under the applicable laws or in good faith to respond to
//                     court orders and processes; or
//                     Detecting and preventing against actual or potential occurrence
//                     of identity theft, fraud, abuse of Services and other illegal acts;
//                     Responding to claims that an advertisement, posting or other
//                     content violates the intellectual property rights of a third party;
//                     Under a duty to disclose or share your personal data in order to
//                     enforce our Terms of Use and other agreements, policies or to
//                     protect the rights, property, or safety of the Company, our
//                     customers, or others, or in the event of a claim or dispute relating
//                     to your use of our Services. This includes exchanging information
//                     with other companies and organisations for the purposes of fraud
//                     detection and credit risk reduction.<br />
//                     We may share information for Internal Use or may share it
//                     with any successor to all or part of our business:<br />
//                     For example, if part of our business is sold we may give our
//                     customer list as part of that transaction. We may also share your
//                     information with any present or future member of our "Group" (as
//                     defined above) or affiliates for our internal business purposes
//                     We may share your information for reasons not described in
//                     this policy:<br />
//                     We may share your information other than as described in this
//                     Policy if we notify you and you consent to the sharing. We will tell
//                     you before we do this.
//                     <br />
//                 </p>
//                 <p>
//                     YOUR CONSENT:<br />
//                     By using the Couple Squad PVT LTD Platform and the
//                     Services provided therein, you agree and consent to the
//                     collection, transfer, use, storage, disclosure and sharing of your
//                     information as described and collected by us in accordance with
//                     this Policy. If you do not agree with the Policy, please do not use
//                     or access the Couple Squad PVT LTD Platform.
//                 </p>
//                 <p>
//                     COOKIES:<br />
//                     Our Couple Squad PVT LTD Platform and third parties with
//                     whom we partner, may use cookies, pixel tags, web beacons,
//                     mobile device IDs, "flash cookies" and similar files or
//                     technologies to collect and store information with respect to your
//                     use of the Services and third-party websites.
//                     Cookies are small files that are stored on your browser or
//                     device by websites, apps, online media and advertisements. We
//                     use cookies and similar technologies for purposes such as:
//                     Authenticating users;
//                     Remembering user preferences and settings;
//                     Determining the popularity of content;
//                     Delivering and measuring the effectiveness of advertising
//                     campaigns;
//                     Analysing site traffic and trends, and generally understanding the
//                     online behaviours and interests of people who interact with our
//                     services.<br />
//                     A pixel tag (also called a web beacon or clear GIF) is a tiny
//                     graphic with a unique identifier, embedded invisibly on a
//                     webpage (or an online ad or email), and is used to count or track
//                     things like activity on a webpage or ad impressions or clicks, as
//                     well as to access cookies stored on users’ computers. We use
//                     pixel tags to measure the popularity of our various pages,
//                     features and services. We also may include web beacons in email messages or newsletters to determine whether the message
//                     has been opened and for other analytics.<br />
//                 </p>
//                 <p>
//                     To modify your cookie settings, please visit your browser’s
//                     settings. By using our Services with your browser settings to
//                     accept cookies, you are consenting to our use of cookies in the
//                     manner described in this section.
//                     <br />
//                     We may also allow third parties to provide audience
//                     measurement and analytics services for us, to serve
//                     advertisements on our behalf across the Internet, and to track
//                     and report on the performance of those advertisements. These
//                     entities may use cookies, web beacons, SDKs and other
//                     technologies to identify your device when you visit the Couple
//                     Squad PVT LTD Platform and use our Services, as well as when
//                     you visit other online sites and services.<br />
//                     Please see our Cookie Policy here for more information
//                     regarding the use of cookies and other technologies described in
//                     this section, including regarding your choices relating to such
//                     technologies.
//                     <br />
//                 </p>
//                 <p>
//                     DATA SECURITY PRECAUTIONS:<br />
//                     We have in place appropriate technical and security
//                     measures to secure the information collected by us. We use vault
//                     and tokenization services from third party service providers to
//                     protect the sensitive personal information provided by you. The
//                     third-party service providers with respect to our vault and
//                     tokenization services and our payment gateway and payment
//                     processing are compliant with the payment card industry
//                     standard (generally referred to as PCI compliant service
//                     providers). You are advised not to send your full credit/debit card
//                     details through unencrypted electronic platforms. Where we have
//                     given you (or where you have chosen) a username and
//                     password which enables you to access certain parts of the
//                     Couple Squad PVT LTD Platform, you are responsible for
//                     keeping these details confidential. We ask you not to share your
//                     password with anyone.
//                 </p>
//                 <p>
//                     Please be aware that the transmission of information via the
//                     internet is not completely secure. Although we will do our best to
//                     protect your personal data, we cannot guarantee the security of
//                     your data transmitted through the Couple Squad PVT LTD
//                     Platform. Once we have received your information, we will use
//                     strict physical, electronic, and procedural safeguards to try to
//                     prevent unauthorised access.
//                     <br />
//                 </p>
//                 <p>
//                     Email OPT-OUT:<br />
//                     You can opt out of receiving our marketing emails. To stop
//                     receiving our promotional emails, please email
//                     (info@couplesquad.com). It may take about ____ days to
//                     process your request. Even if you opt out of getting marketing
//                     messages, we will still be sending you transactional messages
//                     through email and SMS about your purchases.

//                 </p>
//                 <p>
//                     THIRD PARTY SITES:<br />
//                     The Couple Squad PVT LTD Platform may contain links to
//                     other websites. If you click on one of the links to third party
//                     websites, you may be taken to websites we do not control. Any
//                     personal information about you collected whilst visiting such
//                     websites is not governed by this Policy. Couple Squad PVT LTD
//                     shall not be responsible for and has no control over the practices
//                     and content of any website accessed using the links contained
//                     on the Couple Squad PVT LTD Platform. Read the privacy
//                     policy of other websites carefully as this policy does not apply to
//                     the privacy practices of those websites. We are not responsible
//                     for these third party sites.
//                     This Policy shall also not apply to any information you may
//                     disclose to any of our service providers/service personnel which
//                     we do not require you to disclose to us or any of our service
//                     providers under this Policy
//                 </p>
//                 <p>
//                     GRIEVANCE OFFICER:<br />
//                     In accordance with Information Technology Act 2000 and
//                     rules made and applicable there under, the name and contact
//                     details of the Grievance Officer are provided below:
//                     Name:<br />
//                     Address:<br />
//                     Phone Number:<br />
//                     Email Address:<br />
//                     JURISDICTION:<br />
//                     If you choose to visit the website, your visit and any dispute
//                     over privacy is subject to this Policy and the website's terms of
//                     use. In addition to the foregoing, any disputes arising under this
//                     Policy shall be governed by the laws of India.
//                     Further, please note that the Couple Squad PVT LTD
//                     Platform stores your data with the cloud platform of Amazon
//                     Web Services provided by (Name of the Service Provider),
//                     which may store this data on their servers located Outside of
//                     India. Amazon Web Services has security measures in place to
//                     protect the loss, misuse and alteration of the information, details
//                     of which are available at https://aws.amazon.com/. The privacy
//                     policy adopted by Amazon Web Services are detailed in
//                     https://aws.amazon.com/privacy/?nc1=f_pr.<br />
//                     In the event you have questions or concerns about the
//                     security measures adopted by Amazon Web Services, you can
//                     contact their data protection/ privacy team/ legal team or
//                     designated grievance officer at these organisations, whose
//                     contact details are available in its privacy policy, or can also write
//                     to our Grievance Officer at the address provided above.
//                     If you have any queries relating to the processing or usage of
//                     information provided by you in connection with this Policy or
//                     other privacy concerns, you can email us at
//                     hello@couplesquad.com.
//                 </p>
//             </div>
//         </>
//     )
// };

// export default PrivacyPolicy;


import React from 'react'
const PrivacyPolicy = () => {
    return (
        <>
            <div className="flex flex-col items-start justify-center p-10 text-black bg-white">
                <h1 className='w-full text-3xl font-bold text-center'>Privacy Policy</h1>

                <h1 className="mt-4"> <strong>Effective Date: </strong> 20th August 2024</h1>
                <p>
                    Couple Squad Private Limited (“Couple Squad,” “we,” “us,” or “our”) values your privacy and is committed to protecting your personal information. This Privacy Policy (“Policy”) explains how we collect, use, share, and protect your information when you use our website, mobile application (“App”), or any other services provided by Couple Squad (collectively, the “Service” or “Platform”). By accessing or using the Service, you agree to this Policy. If you do not agree with this Policy, please do not use the Service.
                </p>

                <p className="mt-4">
                    <strong>Datrimony®</strong> is a registered trademark of Couple Squad Private Limited. It represents our unique process that blends dating and matrimonial services to offer a seamless transition from dating to marriage. This trademarked service is exclusive to Couple Squad and cannot be used or replicated by any other entity without the express written consent of Couple Squad Private Limited.
                </p>

                <h1 className="mt-4 text-lg font-semibold">1. Information We Collect</h1>
                <h1 className="text-base font-semibold">1.1 Registration Information</h1>
                <p>
                    When you create an account with Couple Squad, we may collect certain personal information (“Registration Information”) from you, such as:
                </p>
                <ul className="font-semibold">
                    <li>• Name</li>
                    <li>• Username</li>
                    <li>• Email address</li>
                    <li>• Phone number</li>
                    <li>• Gender identity</li>
                    <li>• Date of birth</li>
                    <li>• Religion</li>
                    <li>• Nationality</li>
                    <li>• Location</li>
                    <li>• Photographs</li>
                </ul>

                <p className="mt-4">
                    You can update or change your Registration Information at any time by logging into your account. It is your responsibility to keep your account details up to date. Some Registration Information, such as your name, gender identity, and photographs, may be visible to other users on your profile page.
                </p>

                {informationWeCollect?.map((item, index) => (
                    <div className="mt-4" key={index}>
                        <h1 className="text-base font-semibold">{item?.title}</h1>
                        <p dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                ))}


                <h1 className="mt-4 text-lg font-semibold">2. How We Use Your Information</h1>
                <p>
                    Our primary goal is to provide you with a safe, enjoyable, and personalised experience on Couple Squad. Here’s how we use the information we collect:
                </p>

                <ul>
                    <li><strong>• To provide our services and features:</strong> We use your information to create your profile, show you relevant matches, and allow you to participate in Datrimony services and events.</li>
                    <li><strong>• To communicate with you:</strong> We may contact you with information about the Service, updates, and new features. We may also send you marketing messages if you’ve opted in.</li>
                    <li><strong>• To personalise your experience:</strong> We use your information to tailor the content, matches, and events you see based on your preferences and location.</li>
                    <li><strong>• To improve the Service:</strong> We analyse how users interact with the Service to improve our offerings, conduct research, and test new features.</li>
                    <li><strong>• To ensure safety and security:</strong> We use your information to verify your identity, prevent fraud, and monitor for breaches of our Terms of Service.</li>
                    <li><strong>• To process payments:</strong> We use your payment information to process transactions and ensure the security of your financial data.</li>
                    <li><strong>• To serve relevant ads:</strong> We may use your information to show you targeted ads based on your interests and interactions with the Service.</li>
                </ul>

                <h1 className="mt-4 text-lg font-semibold">3. Sharing Your Information</h1>
                <p>
                    We may share your information with third parties in the following circumstances:
                </p>
                <ul>
                    <li><strong>• Service Providers:</strong> We work with trusted third-party service providers to perform functions and provide services to us, such as payment processing, customer support, and data analysis. These providers only have access to the information necessary to perform their functions.</li>
                    <li><strong>• Event Organisers:</strong> If you register for an event, we may share your information with event organisers to facilitate your participation.</li>
                    <li><strong>• Legal and Safety Reasons:</strong> We may disclose your information if required by law or if we believe it is necessary to protect the rights, property, or safety of Couple Squad, our users, or others.</li>
                    <li><strong>• Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new owners.</li>
                    <li><strong>• Marketing Partners:</strong> We may share limited information with marketing partners to help us advertise our services and measure the effectiveness of our campaigns.</li>
                    <li><strong>• Aggregated Information:</strong> We may share aggregated data that does not identify you personally for research, analysis, and marketing purposes.</li>
                </ul>

                {mainPoints?.map((item, index) => (
                    <div className="mt-4" key={index}>
                        <h1 className="text-base font-semibold">{item?.title}</h1>
                        <p dangerouslySetInnerHTML={{ __html: item.description }} />
                    </div>
                ))}

            </div>
        </>
    )
}
export default PrivacyPolicy